<template>
  <div>
    <div class="grid xl:grid-cols-3 sm:grid-cols-2 gap-6 mt-8">
      <div @click="openHandler" class="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-4 py-4 cursor-pointer">
        <div class="flex-shrink-0">
          <img class="h-10 w-10 rounded-full" src="../../assets/flag/canadian-flag.svg" alt="" />
        </div>
        <div class="min-w-0 flex-1">
          <p class="truncate text-[17px] leading-[20px] font-Rubik font-normal">Canada</p>
        </div>
      </div>
      <div @click="openMono('ghana')"
        class="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-4 py-4 cursor-pointer">
        <div class="flex-shrink-0">
          <img class="h-10 w-10 rounded-full" src="../../assets/flag/ghana-flag.svg" alt="" />
        </div>
        <div class="min-w-0 flex-1">
          <p class="truncate text-[17px] leading-[20px] font-Rubik font-normal">Ghana</p>
        </div>
      </div>
      <div @click="openMono('kenya')" class="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-4 py-4 cursor-pointer">
        <div class="flex-shrink-0">
          <img class="h-10 w-10 rounded-full" src="../../assets/flag/kenyan-flag.svg" alt="" />
        </div>
        <div class="min-w-0 flex-1">
          <p class="truncate text-[17px] leading-[20px] font-Rubik font-normal">Kenya</p>
        </div>
      </div>
      <div @click="openMono('nigeria')" class="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-4 py-4 cursor-pointer">
        <div class="flex-shrink-0">
          <img class="h-10 w-10 rounded-full" src="../../assets/flag/nigeria-flag.svg" alt="" />
        </div>
        <div class="min-w-0 flex-1">
          <p class="truncate text-[17px] leading-[20px] font-Rubik font-normal">Nigeria</p>
        </div>
      </div>
      <div @click="openMono('south-africa')" class="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-4 py-4 cursor-pointer">
        <div class="flex-shrink-0">
          <img class="h-10 w-10 rounded-full" src="../../assets/flag/southafrica-flag.svg" alt="" />
        </div>
        <div class="min-w-0 flex-1">
          <p class="truncate text-[17px] leading-[20px] font-Rubik font-normal">South Africa</p>
        </div>
      </div>
      <div @click="openHandler" class="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-4 py-4 cursor-pointer">
        <div class="flex-shrink-0">
          <img class="h-10 w-10 rounded-full" src="../../assets/flag/british-flag.svg" alt="" />
        </div>
        <div class="min-w-0 flex-1">
          <p class="truncate text-[17px] leading-[20px] font-Rubik font-normal">United Kingdom</p>
        </div>
      </div>
      <div @click="openHandler" class="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-4 py-4 cursor-pointer">
        <div class="flex-shrink-0">
          <img class="h-10 w-10 rounded-full" src="../../assets/flag/usa-flag.svg" alt="" />
        </div>
        <div class="min-w-0 flex-1">
          <p class="truncate text-[17px] leading-[20px] font-Rubik font-normal">United States</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Connect from '@mono.co/connect.js'
export default {
  name: 'PlaidIntegration',
  async setup () {
    const route = useRoute()
    const store = useStore()
    const apiUrl = process.env.VUE_APP_API_URL
    const monoNGKey = process.env.VUE_APP_MONO_NG_KEY
    const monoKEKey = process.env.VUE_APP_MONO_KE_KEY
    const monoGHKey = process.env.VUE_APP_MONO_GH_KEY
    const monoSAKey = process.env.VUE_APP_MONO_SA_KEY
    watch(
      () => route,
      async (val) => {
        if (val) {
          if (window.location.href.includes('?oauth_state_id=')) {
            console.log('oauth')
            handler.open()
          }
        }
      },
      { immediate: true }
    )
    const postLinkSuccessEvent = async (type, metadata) => {
      await fetch(`${apiUrl}/api/v1/app/plaid/log-link-event`, {
        method: 'POST',
        body: JSON.stringify({
          type: type,
          session_id: metadata.link_session_id,
          request_id: metadata.request_id || null,
          error_type: null,
          error_code: null,
          status: metadata.status || null
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + ' ' + appToken
        }
      })
    }
    const postLinkEvent = async (type, metadata, error) => {
      await fetch(`${apiUrl}/api/v1/app/plaid/log-link-event`, {
        method: 'POST',
        body: JSON.stringify({
          type: type,
          session_id: metadata.link_session_id,
          request_id: metadata.request_id,
          error_type: error.error_type || null,
          error_code: error.error_code || null,
          status: metadata.status
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + ' ' + appToken
        }
      })
    }
    const appToken = localStorage.getItem('app-token')
    const createLinkToken = async () => {
      try {
        const res = await fetch(`${apiUrl}/api/v1/app/plaid/create-link-token`, {
          method: 'POST',
          headers: {
            Authorization: 'Bearer' + ' ' + appToken
          }
        })
        const data = await res.json()
        const linkToken = data.data.link_token
        localStorage.setItem('link_token', linkToken)
        return linkToken
      } catch (e) {
        console.log(e)
      }
    }
    const linkToken = localStorage.getItem('link_token')

    // eslint-disable-next-line no-undef
    const handler1 = Plaid.create({
      token: linkToken,
      onSuccess: async (publicToken, metadata) => {
        await postLinkSuccessEvent('Success', metadata, {})
        await fetch(`${apiUrl}/api/v1/app/plaid/get-access-token`, {
          method: 'POST',
          body: JSON.stringify({
            public_token: publicToken,
            institution_id: metadata.institution.institution_id
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + ' ' + appToken
          }
        }).catch(err => console.error(err))
        // await getBalance()
      },
      onEvent: (eventName, metadata) => {
        console.log('Event:', eventName)
        console.log('Metadata:', metadata)
        if (eventName === 'HANDOFF') {
          const success = {
            status: true,
            message: 'Your data has been added successfully. You can add multiple accounts to complete your financial profile.'
          }
          createLinkToken()
          store.commit('setBankModal', success)
        }
      },
      onExit: async (error, metadata) => {
        await postLinkEvent('Exit', metadata, error)
        console.log(error, metadata)
      }
    })

    const openHandler = () => {
      handler1.open()
    }

    // const getBalance = async function () {
    //   const response = await fetch('/api/data', {
    //     method: 'GET'
    //   })
    //   const data = await response.json()
    //   console.log(data)
    //   // Render response data
    //   // const pre = document.getElementById("response");
    //   // pre.textContent = JSON.stringify(data, null, 2);
    //   // pre.style.background = "#F6F6F6";
    // }

    // eslint-disable-next-line no-undef
    const handler = Plaid.create({
      token: linkToken,
      receivedRedirectUri: window.location.href,
      onSuccess: async (publicToken, metadata) => {
        await postLinkSuccessEvent('Success', metadata, null)
        await fetch(`${apiUrl}/api/v1/app/plaid/get-access-token`, {
          method: 'POST',
          body: JSON.stringify({
            public_token: publicToken,
            institution_id: metadata.institution.institution_id
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + ' ' + appToken
          }
        }).catch(err => console.error(err))
        // const response = await fetch('/api/data', {
        //   method: 'GET'
        // })
        // const data = await response.json()
        // console.log(data)
        window.location.href = '/financial-insights/integrations'
      },
      onEvent: (eventName, metadata) => {
        console.log('Event:', eventName)
        console.log('Metadata:', metadata)
        if (eventName === 'HANDOFF') {
          const success = {
            status: true,
            message: 'Your data has been added successfully. You can add multiple accounts to complete your financial profile.'
          }
          store.commit('setBankModal', success)
        }
      },
      onExit: async (error, metadata) => {
        await postLinkEvent('Exit', metadata, error)
        console.log(error, metadata)
      }
    })

    const openOkra = () => {
      // eslint-disable-next-line no-undef
      Okra.buildWithOptions({
        name: 'Kinfolk Onyx',
        env: 'production-sandbox',
        key: '02f13a60-5672-527a-ac97-095d2cd767c1',
        token: '62faabaac0d162a7fe06b311',
        source: 'link',
        color: '#000000',
        limit: '24',
        callback_url: '',
        redirect_url: '',
        currency: 'NGN',
        logo: 'https://kinfolk.vc/img/kinfolk.c65c8745.svg',
        onSuccess: function (data) {
          console.log('success', data)
        },
        onClose: function () {
          console.log('closed')
        }
      })
    }
    // const connect = new Connect({
    //   key: 'test_pk_7FmQMYxSxnZkQawcFE8R',
    //   onSuccess: ({ code }) => {
    //     console.log('code', code)
    //     alert(code)
    //   },
    //   onLoad: () => console.log('widget loaded successfully'),
    //   onClose: () => console.log('widget has been closed'),
    //   onEvent: (eventName, data) => {
    //     console.log(eventName)
    //     console.log(data)
    //   }
    // })

    const getMonoKey = (country) => {
      let monoKey = monoNGKey
      switch (country) {
        case 'nigeria':
          monoKey = monoNGKey
          return monoKey
        case 'kenya':
          monoKey = monoKEKey
          return monoKey
        case 'ghana':
          monoKey = monoGHKey
          return monoKey
        case 'south-africa':
          monoKey = monoSAKey
          return monoKey
        default:
          return monoKey
      }
    }

    const openMono = (country) => {
      const monoKey = getMonoKey(country)
      const connect = new Connect({
        key: monoKey,
        onSuccess: async ({ code }) => {
          console.log('code', code)
          await fetch(`${apiUrl}/api/v1/app/mono/link/${country}`, {
            method: 'POST',
            body: JSON.stringify({
              code: code
            }),
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer' + ' ' + appToken
            }
          }).catch(err => console.error(err))
        },
        onLoad: () => console.log('widget loaded successfully'),
        onClose: () => console.log('widget has been closed'),
        onEvent: (eventName, data) => {
          console.log(eventName)
          console.log(data)
        }
      })
      connect.setup()
      connect.open()
    }
    return {
      openHandler,
      openOkra,
      openMono
    }
    // handler.open()
  }
}
</script>

<style lang="scss" scoped></style>
