import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class AuthService {
  static getMagicLink (data) {
    return axios.post(`${url}/app/auth/partial-signup`, data)
  }

  static signUp (data) {
    return axios.post(`${url}/app/auth/signup`, data)
  }

  static signIn (data) {
    return axios.post(`${url}/app/auth/signin`, data)
  }

  static getPasswordLink (data) {
    return axios.post(`${url}/app/auth/forgot-password`, data)
  }

  static restPassword (data) {
    return axios.post(`${url}/app/auth/reset-password`, data)
  }
}

export default AuthService
