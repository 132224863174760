<template>
  <div class="h-screen min-h-screen overflow-y-hidden  md:overflow-y-auto lg:overflow-hidden overflow-x-hidden bg-black relative">
    <div class="max-w-[1200px] w-full mx-auto h-full overflow-y-auto">
      <div class="circle-sprite max-w-[736px] w-full max-h-[736px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-[1px] border-opacity-25 rounded-full top-[-530px] right-[-110px] lg:right-[100px] md:top-[-450px] opacity-1 z-10"></div>
      <div class="square-sprite lg:max-w-[470px] max-w-[350px] w-full lg:max-h-[470px] max-h-[350px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-[1px] border-opacity-25 transform rotate-[55deg] left-[300px] lg:bottom-[-350px] bottom-[-120px] opacity-1 z-10"></div>
      <div class="flex lg:flex-row flex-col items-center justify-center lg:h-full h-[max-content] px-[16px] lg:px-0">
        <div class="lg:w-[50%] w-full flex flex-col justify-center lg:items-start items-center lg:pl-[25px] pl-auto">
          <transition name="fade">
            <template v-if="toggleHowTo === false">
              <div class="flex flex-col lg:items-start items-center lg:max-w-[310px] max-w-[350px] mx-auto lg:ml-0 lg:my-auto md:my-[80px] mt-10">
                <div class="md:mb-[28px]">
                  <img src="../assets/KFLogo.svg" class="h-12 w-12 md:h-20 md:w-20 lg:h-[108px] lg:w-[123px]" />
                </div>
                <h1 class="text-sm text-white text-[16px] md:text-[28px] font-Playfair font-normal mt-3">Kinfolk Venture Capital</h1>
                <p class="text-greyText text-sm md:text-[16px] lg:text-left text-center md:leading-[18.96px] font-normal mt-3">The Fundraising Process for Africa’s Next-Gen Startups—Fully Digitized</p>
                <button @click="handleHowTo" class="flex items-center font-Rubik font-medium text-white text-[14px] leading-[16.59px] md:my-[64px] my-6 border-greyText border-b-[1px] pb-[16px]">
                  How Does It Work?
                  <div class="arrow-right ml-[6px] mb-[3px]">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.9987 15.1654C11.6806 15.1654 14.6654 12.1806 14.6654 8.4987C14.6654 4.8168 11.6806 1.83203 7.9987 1.83203C4.3168 1.83203 1.33203 4.8168 1.33203 8.4987C1.33203 12.1806 4.3168 15.1654 7.9987 15.1654Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M8 11.1654L10.6667 8.4987L8 5.83203" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M5.33203 8.5H10.6654" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </button>
              </div>
            </template>
            <template v-else>
              <div class="how-to max-h-[485px] overflow-hidden hover:overflow-y-auto lg:ml-0 lg:my-auto md:my-[80px] mt-10 z-[50]">
                <div class="head mb-[26px]">
                  <button @click="handleHowTo" class="flex items-center font-Rubik font-normal text-greyText hover:text-white transition-all text-[15px] leading-[17.78px]">
                    <div class="arrow-right mr-[12px] mb-[2px]">
                      <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.84853 2.84853C10.3172 2.3799 10.3172 1.6201 9.84853 1.15147C9.3799 0.682843 8.6201 0.682843 8.15147 1.15147L9.84853 2.84853ZM2 9L1.15147 8.15147C0.682843 8.6201 0.682843 9.3799 1.15147 9.84853L2 9ZM8.15147 16.8485C8.6201 17.3172 9.3799 17.3172 9.84853 16.8485C10.3172 16.3799 10.3172 15.6201 9.84853 15.1515L8.15147 16.8485ZM8.15147 1.15147L1.15147 8.15147L2.84853 9.84853L9.84853 2.84853L8.15147 1.15147ZM1.15147 9.84853L8.15147 16.8485L9.84853 15.1515L2.84853 8.15147L1.15147 9.84853Z" fill="white"/>
                      </svg>
                    </div>
                    Back
                  </button>
                </div>
                <div class="body font-Rubik">
                  <h1 class="text-white text-[20px] font-bold">How Does It Work?</h1>
                  <ul id="instruction-items" class="text-white my-[40px] max-w-[100%]">
                    <li class="w-[300px] mb-[32px] flex justify-between">
                      <div class="one max-w-[270px]">
                        <h3 class="font-medium text-[18px] leading-[21.33px] mb-[12px]">Tell Us About Your Business</h3>
                        <p class="font-normal leading-[24px] text-greyText">Complete our application to tell us about your mission, product, market, and company.</p>
                      </div>
                    </li>
                    <li class="mb-[32px] flex justify-between">
                      <div class="two max-w-[270px]">
                        <h3 class="font-medium text-[18px] leading-[21.33px] mb-[12px]">Upload Your Data</h3>
                        <p class="font-normal leading-[24px] text-greyText">Kinfolk will analyze your business relative to your industry, key metrics, benchmarks.</p>
                      </div>
                    </li>
                    <li class="mb-[32px] flex justify-between">
                      <div class="three max-w-[270px]">
                        <h3 class="font-medium text-[18px] leading-[21.33px] mb-[12px]">Get a Quick Response</h3>
                        <p class="font-normal leading-[24px] text-greyText">Kinfolk will provide an investment decision in a few days, and regardless of our decision we will share back analysis based on the data provided.</p>
                      </div>
                    </li>
                    <li class="mb-[32px] flex justify-between">
                      <div class="four max-w-[270px]">
                        <h3 class="font-medium text-[18px] leading-[21.33px] mb-[12px]">Return to What Matters</h3>
                        <p class="font-normal leading-[24px] text-greyText">Use your funds and our data insights to grow your business and keep solving important problems facing Africa.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </transition>
        </div>
        <div v-if="token" class="lg:w-[50%] w-full flex flex-col justify-center items-center min-w-0 h-full lg:py-0 py-[34px] px-0 z-50">
          <div class="signup-card max-w-[458px] w-full bg-white lg:px-[48px] px-[30px] py-6 md:py-[40px]  rounded-[6px]">
            <h1 class="text-center font-Playfair text-[28px] leading-[37.32px] md:text-[32px] md:leading-[42.66px]">Sign Up</h1>
            <p class="text-center text-greyText font-Rubik text-[15px] leading-[17.78px] font-light mt-3 mb-6">Welcome to Kinfolk Venture Capital</p>
            <div v-if="error" class="rounded-md bg-red-50 p-4 mb-3">
              <div class="flex">
                <div class="flex-shrink-0">
                  <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                  <p class="text-sm font-medium text-red-800">
                    {{ error }}
                  </p>
                </div>
                <div class="ml-auto pl-3 my-auto">
                  <div class="-mx-1.5 -my-1.5">
                    <button type="button" class="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600">
                      <span class="sr-only">Dismiss</span>
                      <XIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <form>
              <div class="relative w-full mb-3">
                <label
                  class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                  htmlFor="fullName"
                >
                  Full Name
                </label>
                <input
                  id="fullName"
                  name="fullName"
                  type="text"
                  :class="[
                    v$.full_name.$error ? 'border-red-400' : 'border-grey-300',
                  ]"
                  class="px-4 py-3 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-gray-200 w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                  placeholder=""
                  style="transition: 'all .15s ease'"
                  v-model="v$.full_name.$model"
                  @blur="v$.full_name.$touch()"
                />
                <div v-if="v$.full_name.$error">
                  <p
                    v-if="v$.full_name.required.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >Full name is required</p>
                  <p
                    v-if="v$.full_name.alpha.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >Full name should contain first and last name</p>
                </div>
              </div>
              <div class="relative w-full mb-3">
                <label
                  class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                  htmlFor="email"
                >
                  Email Address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="username"
                  :class="[
                    v$.email.$error ? 'border-red-400' : 'border-grey-300',
                  ]"
                  class="px-4 py-3 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-gray-200 w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                  placeholder=""
                  style="transition: 'all .15s ease'"
                  v-model="v$.email.$model"
                  @blur="v$.email.$touch()"
                />
                <div v-if="v$.email.$error">
                  <p
                    v-if="v$.email.required.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >Email is required</p>
                  <p
                    v-if="v$.email.email.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >Email is invalid</p>
                </div>
              </div>
              <div class="relative w-full mb-6">
                <label
                  class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                  htmlFor="password"
                >
                  Password
                </label>
                <div class="flex relative w-full my-auto">
                  <span class='absolute inset-y-0 right-0 flex items-center pr-1 mt-2'>
                    <div class=''>
                    <img :src="eye" alt="" height="100%" width="100%" class="w-[20px] h-[20px] mx-[10px] hover:cursor-pointer" @click="toggleShow">
                    </div>
                  </span>
                  <input
                    id="password"
                    name="password"
                    autocomplete="new-password"
                    :type="fieldVisibility"
                    :class="[
                      v$.password.$error ? 'border-red-400' : 'border-grey-300',
                    ]"
                    class="px-4 py-3 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-gray-200 w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                    placeholder=""
                    style="transition: 'all .15s ease'"
                    v-model="v$.password.$model"
                    @blur="v$.password.$touch()"
                  />
                </div>
                <div v-if="v$.password.$error">
                  <p
                    v-if="v$.password.required.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >Password is required</p>
                  <p
                    v-if="v$.password.minLength.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >Password should be alteast 8 characters</p>
                </div>
              </div>
              <button :disabled="v$.$invalid" type="button" class="submit w-full py-4 text-white font-medium text-[16px] text-center leading-[19px] bg-black mb-[40px] disabled:opacity-50" @click="signUp">Sign Up</button>
              <p class="text-center font-Rubik text-[15px] font-light">Already have an account?&nbsp;
                <router-link to="/sign-in"><b class="text-black font-medium">Sign In</b></router-link>
              </p>
            </form>
          </div>
        <p class="text-white text-[15px] leading-[18px] font-normal mt-[32px]">By registering you agree with our website's <a href="https://kinfolk.vc/en/terms-of-use" target="_blank" class="underline">Terms of Use</a></p>
        </div>
        <div v-else class="lg:w-[50%] w-full flex flex-col justify-center items-center min-w-0 h-full lg:py-0 py-[34px] px-0 z-50">
          <div class="signup-card max-w-[458px] w-full bg-white lg:px-[48px] px-[30px] py-[40px] rounded-[6px]">
            <h1 class="text-center font-Playfair text-[32px] leading-[42.66px]">Create Account</h1>
            <p class="text-center text-greyText font-Rubik text-[15px] leading-[17.78px] font-light mt-3 mb-6">Let’s create your account</p>
            <div v-if="error" class="rounded-md bg-red-50 p-4 mb-3">
              <div class="flex">
                <div class="flex-shrink-0">
                  <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                  <p class="text-sm font-medium text-red-800">
                    {{ error }}
                  </p>
                </div>
                <div class="ml-auto pl-3 my-auto">
                  <div class="-mx-1.5 -my-1.5">
                    <button type="button" class="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600">
                      <span class="sr-only">Dismiss</span>
                      <XIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="message" class="rounded-md bg-green-50 p-4 my-6">
              <div class="flex">
                <div class="flex-shrink-0">
                  <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                  <h3 class="text-sm font-Rubik font-medium text-green-800">
                    Success!
                  </h3>
                  <div class="mt-2 font-Rubik text-sm text-green-700">
                    <p>
                      An Email has been sent to your email address. Please check your email and click on the link to create your account. <br><br>
                      We recommend using a company email (e.g example@company.com)  instead of a personal email (e.g example@gmail.com)  for Onyx. This will ensure the proper delivery of our update emails during your diligence process
                    </p>
                  </div>
                  <div class="mt-4">
                    <p class="mt-2 font-Rubik text-sm text-green-800">Didn't get the email?</p>
                    <div class="-my-1.5 flex">
                      <p class="mt-2 font-Rubik text-sm text-green-800">Confirm if email is correct </p>
                      <button type="button" class="ml-3 bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100" @click="resendlink">
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form v-if="!message">
              <div class="relative w-full mb-3">
                <label
                  class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                  htmlFor="magic-email"
                >
                  Email Address
                </label>
                <input
                  id="magic-email"
                  name="magic-email"
                  type="magic-email"
                  autocomplete="username"
                  :class="[
                    v2$.magic_email.$error ? 'border-red-400' : 'border-grey-300',
                  ]"
                  class="px-4 py-3 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-gray-200 w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                  placeholder=""
                  style="transition: 'all .15s ease'"
                  v-model="v2$.magic_email.$model"
                  @blur="v2$.magic_email.$touch()"
                />
                <!-- {{v2$.magic_email}} -->
                <div v-if="v2$.magic_email">

                </div>
                <div v-if="v2$.magic_email.$error">
                  <p
                    v-if="v2$.magic_email.required.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >Email is required</p>
                  <p
                    v-if="v2$.magic_email.email.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >Email is invalid</p>
                </div>
              </div>
              <button :disabled="v2$.$invalid" type="button" class="submit w-full py-4 text-white font-medium text-[16px] text-center leading-[19px] bg-black mb-[40px] disabled:opacity-50" @click="getMagicLink"> Get Link </button>
              <p class="text-center font-Rubik text-[15px] font-light">Already have an account?&nbsp;
                <router-link to="/sign-in"><b class="text-black font-medium">Sign In</b></router-link>
              </p>
            </form>
          </div>
        <p class="text-center text-white text-[15px] leading-[18px] font-light mt-[32px]">By registering you agree with our website's <a href="https://kinfolk.vc/en/terms-of-use" target="_blank" class="underline">Terms of Use</a> & <a href="https://kinfolk.vc/en/privacy-policy" target="_blank" class="underline">Privacy Policy</a></p>        </div>
      </div>
    </div>
    <transition name="fadeInOut">
      <Modal :modalActive="warningVisible">
        <div class="bg-white w-[399px] rounded-[6px] p-6 relative">
          <span class="w-[48px] h-[48px] mb-[12px]">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 28C4 14.7452 14.7452 4 28 4C41.2548 4 52 14.7452 52 28C52 41.2548 41.2548 52 28 52C14.7452 52 4 41.2548 4 28Z" fill="#FEF0C7"/>
              <path d="M27.9998 24V28M27.9998 32H28.0098M26.2898 18.86L17.8198 33C17.6451 33.3024 17.5527 33.6453 17.5518 33.9945C17.5508 34.3437 17.6413 34.6871 17.8142 34.9905C17.9871 35.2939 18.2365 35.5467 18.5375 35.7238C18.8385 35.9009 19.1806 35.9962 19.5298 36H36.4698C36.819 35.9962 37.1611 35.9009 37.4621 35.7238C37.7631 35.5467 38.0124 35.2939 38.1854 34.9905C38.3583 34.6871 38.4488 34.3437 38.4478 33.9945C38.4468 33.6453 38.3544 33.3024 38.1798 33L29.7098 18.86C29.5315 18.5661 29.2805 18.3231 28.981 18.1545C28.6814 17.9858 28.3435 17.8972 27.9998 17.8972C27.656 17.8972 27.3181 17.9858 27.0186 18.1545C26.7191 18.3231 26.468 18.5661 26.2898 18.86Z" stroke="#DC6803" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M28 48C16.9543 48 8 39.0457 8 28H0C0 43.464 12.536 56 28 56V48ZM48 28C48 39.0457 39.0457 48 28 48V56C43.464 56 56 43.464 56 28H48ZM28 8C39.0457 8 48 16.9543 48 28H56C56 12.536 43.464 0 28 0V8ZM28 0C12.536 0 0 12.536 0 28H8C8 16.9543 16.9543 8 28 8V0Z" fill="#FFFAEB"/>
            </svg>
          </span>
          <div class="flex flex-col items-start justify-center">
            <h3 class="font-medium font-Rubik text-[18px] leading-[24px] text-black mb-[12px]">
              Caution!
            </h3>
            <p class="font-Rubik text-[13px] leading-[20px] text-greyText max-w-[90%]"> We recommend using a company email <br/> (e.g example@company.co) instead of a personal email (e.g example@gmail.com) for Onyx. This will ensure the proper delivery of our update emails during your diligence process </p>
            <div class="flex items-center justify-between w-full">
              <button @click="warningVisible = !warningVisible" class="mt-6 border-b border-teal font-medium py-[12px] px-[35px] text-[13px] text-black leading-[15.41px] rounded-[6px] mr-[12px] border-[0.6px] border-disabledGrayText max-w-[170px] w-[50%]">Cancel</button>
              <button @click="warningVisible = !warningVisible" class="mt-6 border-b border-teal font-medium py-[12px] px-[35px] text-white text-[13px] leading-[15.41px] rounded-[6px] bg-black max-w-[170px] w-[50%]">Dismiss</button>
            </div>
          </div>
        </div>
      </Modal>
    </transition>
  </div>
</template>

<script>
import AuthService from '@/services/auth.service'
import eyeOpen from '../assets/visible-eye.svg'
import eyeClosed from '../assets/not-visible-eye.svg'
import { ref, watch, reactive, computed } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, helpers } from '@vuelidate/validators'
import { useRoute, useRouter } from 'vue-router'
import { CheckCircleIcon, XCircleIcon, XIcon } from '@heroicons/vue/solid'
import Modal from '@/components/modal/Modal.vue'

const alpha = helpers.regex(/^[a-zA-Z]{2,}\s[a-zA-Z]{2,}$/)

export default {
  components: {
    CheckCircleIcon,
    XCircleIcon,
    XIcon,
    Modal
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const token = ref(null)
    const message = ref(null)
    const error = ref(null)
    const toggleHowTo = ref(false)
    const warningVisible = ref(false)

    const fieldVisibility = ref('password')
    const toggleShow = () => {
      fieldVisibility.value === 'password' ? fieldVisibility.value = 'text' : fieldVisibility.value = 'password'
    }
    const eye = computed(() => {
      return fieldVisibility.value === 'password' ? eyeClosed : eyeOpen
    })

    const handleHowTo = () => {
      toggleHowTo.value = !toggleHowTo.value
    }

    const address = ref('')
    const submitUserSignup = () => {
      // console.log('form submission - data: ')
    }

    function getAddressData (addressData, placeResultData, id) {
      // console.log(addressData)
      address.value = addressData
    }

    function handleError (error) {
      alert(error)
    }

    function delayFunction (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }

    const data = reactive({
      full_name: '',
      email: '',
      password: ''
    })

    const data2 = reactive({
      magic_email: ''
    })

    const rules = computed(() => ({
      full_name: { required, alpha },
      email: { required, email },
      password: { minLength: minLength(8), required }
    }))

    const rules2 = computed(() => ({
      magic_email: { required, email }
    }))

    const resendlink = () => {
      message.value = null
    }
    const getMagicLink = async () => {
      try {
        const response = await AuthService.getMagicLink({
          email: data2.magic_email
        })
        if (response.status === 200 && response.data.status === 'success') {
          message.value = response.data.data
        }
      } catch (e) {
        if (e.response) {
          error.value = e.response.data.message
          setTimeout(() => {
            error.value = null
          }, 2000)
        } else if (e.message === 'Network Error') {
          error.value = 'Network Error check your internet connection'
          setTimeout(() => {
            error.value = null
          }, 3000)
        }
      }
    }

    const signUp = async () => {
      try {
        const names = data.full_name.split(' ')
        const signupData = {
          first_name: names[0],
          last_name: names[1],
          email: data.email,
          password: data.password,
          token: token.value
        }
        // console.log(signupData)
        const response = await AuthService.signUp(signupData)
        if (response.status === 200) {
          router.push('/sign-in')
        }
      } catch (e) {
        if (e.response) {
          error.value = e.response.data.message
          setTimeout(() => {
            error.value = null
          }, 2000)
        } else if (e.message === 'Network Error') {
          error.value = 'Network Error check your internet connection'
          setTimeout(() => {
            error.value = null
          }, 3000)
        }
      }
    }
    const checkMagicmail = (email) => {
      if (email.includes('@gmail.') ||
          email.includes('@GMAIL.') ||
          email.includes('@yahoo.') ||
          email.includes('@YAHOO.') ||
          email.includes('@hotmail.') ||
          email.includes('@HOTMAIL.') ||
          email.includes('@outlook.') ||
          email.includes('@OUTLOOK.')) {
        delayFunction(1200).then(() => {
          console.log('workingggg')
          warningVisible.value = true
        })
      } else {
        warningVisible.value = false
      }
    }

    const closeModal = () => {
      warningVisible.value = false
    }
    watch(
      () => route,
      async (val) => {
        if (val.query.token) {
          token.value = val.query.token
          data.email = val.query.email
          // console.log(data)
        } else {
          token.value = null
        }
      },
      { immediate: true }
    )
    watch(
      () => data2.magic_email,
      (email) => {
        checkMagicmail(email)
      }
    )
    const v$ = useVuelidate(rules, data)
    const v2$ = useVuelidate(rules2, data2)

    return {
      toggleShow,
      eye,
      submitUserSignup,
      fieldVisibility,
      v$,
      v2$,
      token,
      getAddressData,
      handleError,
      getMagicLink,
      signUp,
      message,
      resendlink,
      error,
      toggleHowTo,
      handleHowTo,
      checkMagicmail,
      warningVisible,
      closeModal
    }
  }
}
</script>

<style scoped>
.how-to {
  scroll-behavior: smooth;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.how-to::-webkit-scrollbar { /* Chrome */
  display: none;
}
#instruction-items li {
  position: relative;
}
#instruction-items li::before {
  content: '';
  border: solid 1px #828282;
  background-color: black;
  width: 20px;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  border-radius: 50%;
  position: relative;
  left: 0;
}
#instruction-items li:not(:last-child)::after {
  content: '';
  width: 2px;
  height: 100%;
  max-width: 2px;
  position: absolute;
  left: 0;
  top: 25px;
  margin-left: 9px;
  border-right: dotted 2px #828282;
  background-color: transparent;
}
.fade-enter-from {
  opacity: 0;
  position: fixed;
}
.fade-enter-to {
  opacity: 1;
  position: unset;
}
.fade-enter-active {
  transition: all .6s ease;
}
.fade-leave-from {
  opacity: 1;
  position: unset;
}
.fade-leave-to {
  opacity: 0;
  position: fixed;
}
/* .fade-leave-active {
  transition: all .2s ease;
} */

</style>
