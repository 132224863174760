export const goals = [
  {
    id: 1,
    goal: 'No Poverty',
    img: require('../assets/svg/E-WEB-Goal-01.svg'),
    kpis: [
      {
        id: 1,
        title: 'By 2030, eradicate extreme poverty for all people everywhere, currently measured as people living on less than $1.25 a day.',
        kpi: ['Number of people removed from extreme poverty (less than $1.25 / day)']
      },
      {
        id: 2,
        title: 'By 2030, reduce at least by half the proportion of men, women and children of all ages living in poverty in all its dimensions according to national definitions.',
        kpi: ['Number of people removed from poverty according to local/national definitions.']
      },
      {
        id: 3,
        title: 'Implement nationally appropriate social protection systems and measures for all, including floors, and by 2030 achieve substantial coverage of the poor and the vulnerable.',
        kpi: ['Number of poor people, as determined by local/national definitions, added to Social Protection Systems that provide a floor.']
      },
      {
        id: 4,
        title: 'By 2030, ensure that all men and women, in particular, the poor and the vulnerable, have equal rights to economic resources, as well as access to basic services, ownership and control over land and other forms of property, inheritance, natural resources, appropriate new technology and financial services, including microfinance.',
        kpi: ['Number of poor, and vulnerable added to households with access to basic services.', 'Number of poor, and vulnerable living with increased access to natural resources.', 'Number of poor, and vulnerable given access to fair loans and opportunities for home or property ownership.', 'Number of poor, and vulnerable living with increased access to microfinance.']
      },
      {
        id: 5,
        title: 'By 2030, build the resilience of the poor and those in vulnerable situations and reduce their exposure and vulnerability to climate-related extreme events and other economic, social and environmental shocks and disasters.',
        kpi: ['Reduction in the number of poor and vulnerable whose death or disappearance is directly correlated to sudden disasters (natural, economic, social, economic, or otherwise) per 100,000 population.', 'Reduction of direct economic value to poor and vulnerable due to sudden disasters (natural, economic, social, economic, or otherwise).']
      },
      {
        id: 6,
        title: 'Ensure significant mobilization of resources from a variety of sources, including through enhanced development cooperation, in order to provide adequate and predictable means for developing countries, in particular, least developed countries, to implement programs and policies to end poverty in all its dimensions.',
        kpi: ['Efficiency of resources, grants, and non-debt creating inflows dedicated to poverty reduction programs.', 'Efficiency of government spending to sectors that disproportionately benefit women, the poor, and vulnerable groups.']
      }
    ]
  },
  {
    id: 2,
    goal: 'Zero Hunger',
    img: require('../assets/svg/E-WEB-Goal-02.svg'),
    kpis: [
      {
        id: 1,
        title: 'By 2030, end hunger and ensure access by all people, in particular, the poor and people in vulnerable situations, including infants, to safe, nutritious and sufficient food all year round.',
        kpi: ['Increased efficiency of logistical supply chains in agriculture which ensure access to markets and affordable food.', 'Number of people no longer classified as undernourished according to local/ national classifications.', 'Number of people removed from moderate or severe food insecurity according to local/ national classifications.']
      },
      {
        id: 2,
        title: 'By 2030, end all forms of malnutrition, including achieving, by 2025, the internationally agreed targets on stunting and wasting in children under 5 years of age, and address the nutritional needs of adolescent girls, pregnant and lactating women and older persons.',
        kpi: ['Number of children under 5 years per 100,000 no longer classified as stunted (height for age value greater than -2 standard deviations from the median World Health Organization Child Growth Standards).', 'Number of children under 5 years per 100,000 no longer classified as malnourished (a height for weight value greater than -2 standard deviations from the median World Health Organization Child Growth Standards).']
      },
      {
        id: 3,
        title: 'By 2030, double the agricultural productivity and incomes of small-scale food producers, in particular women, indigenous peoples, family farmers, pastoralists and fishers, including through secure and equal access to land, other productive resources and inputs, knowledge, financial services, markets and opportunities for value addition and non-farm employment.',
        kpi: ['Increase in production per labor unit in small-scale farming, pastoral, and forestry enterprises according to local/ national classification.', 'Increase in the average income of women performing labor in small-scale food production.', 'Increase in average income of indigenous peoples performing labor in small-scale food production.']
      },
      {
        id: 4,
        title: 'By 2030, ensure sustainable food production systems and implement resilient agricultural practices that increase productivity and production, that help maintain ecosystems, that strengthen capacity for adaptation to climate change, extreme weather, drought, flooding and other disasters and that progressively improve land and soil quality.',
        kpi: ['Increased the proportion of agricultural area using productive and sustainable agricultural practices as a percentage of total agricultural area.']
      },
      {
        id: 5,
        title: 'By 2030, maintain the genetic diversity of seeds, cultivated plants and farmed and domesticated animals and their related wild species, including through soundly managed and diversified seed and plant banks at the national, regional and international levels, and promote access to and fair and equitable sharing of benefits arising from the utilization of genetic resources and associated traditional knowledge, as internationally agreed.',
        kpi: ['Number of plant and animal genetic resources for food and agriculture added to either medium- or long-term conservation facilities', 'Reduction in the number of locally farmed breeds or cultivated phyla classified as at risk of extinction.']
      },
      {
        id: 6,
        title: 'Increase investment, including through enhanced international cooperation, in rural infrastructure, agricultural research and extension services, technology development and plant and livestock gene banks in order to enhance agricultural productive capacity in developing countries, in particular, least developed countries.',
        kpi: ['Increase in total official flows (official development assistance plus other official flows including investment) to the agriculture sectors of developing countries.']
      },
      {
        id: 7,
        title: 'Correct and prevent trade restrictions and distortions in world agricultural markets, including through the parallel elimination of all forms of agricultural export subsidies and all export measures with equivalent effect, in accordance with the mandate of the Doha Development Round.',
        kpi: ['Total reduced value of agricultural export subsidies.']
      },
      {
        id: 8,
        title: 'Adopt measures to ensure the proper functioning of food commodity markets and their derivatives and facilitate timely access to market information, including on food reserves, in order to help limit extreme food price volatility.',
        kpi: ['Reduction in food price volatility indices.']
      }
    ]
  },
  {
    id: 3,
    goal: 'Good Health and Well-being',
    img: require('../assets/svg/E-WEB-Goal-03.svg'),
    kpis: [
      {
        id: 1,
        title: 'By 2030, reduce the global maternal mortality ratio to less than 70 per 100,000 live births.',
        kpi: ['Proportional reduction of maternal mortality rate per 100,000 live births.', 'Proportion of births attended or overseen by skilled health personnel.']
      },
      {
        id: 2,
        title: 'By 2030, end preventable deaths of newborns and children under 5 years of age, with all countries aiming to reduce neonatal mortality to at least as low as 12 per 1,000 live births and under-5 mortality to at least as low as 25 per 1,000 live births.',
        kpi: ['Proportional reduction of the mortality rate for children under 5 per 1000 live births.', 'Proportional reduction of the mortality rate for neonatal children per 1000 live births.']
      },
      {
        id: 3,
        title: 'By 2030, end the epidemics of AIDS, tuberculosis, malaria and neglected tropical diseases and combat hepatitis, water-borne diseases and other communicable diseases.',
        kpi: ['Proportional reduction in number of new HIV, Tuberculosis, Malaria, or Hepatitis B as a percentage of the uninfected population.']
      },
      {
        id: 4,
        title: 'By 2030, reduce by one-third premature mortality from non-communicable diseases through prevention and treatment and promote mental health and well-being.',
        kpi: ['Proportional reduction of the mortality rate attributed to cardiovascular disease, cancer, diabetes, or chronic respiratory disease.', 'Proportional reduction of the suicide mortality rate.']
      },
      {
        id: 5,
        title: 'Strengthen the prevention and treatment of substance abuse, including narcotic drug abuse and harmful use of alcohol.',
        kpi: ['Increase in the number of people with access to treatment interventions (pharmacological, psychosocial, and rehabilitation and aftercare services) for substance use disorders.', 'Reduction in per capita alcohol consumption (defined according to the national context as alcohol per capita consumption within a calendar year in liters of pure alcohol).']
      },
      {
        id: 6,
        title: 'By 2021, halve the number of global deaths and injuries from road traffic accidents.',
        kpi: ['Reduction in mortality rates due to road traffic injuries.']
      },
      {
        id: 7,
        title: 'By 2030, ensure universal access to sexual and reproductive health-care services, including for family planning, information and education, and the integration of reproductive health into national strategies and programs.',
        kpi: ['Proportion of women of reproductive age (aged 15-49 years) who have their need for family planning satisfied with modern methods as defined by the World Health Organization.', 'Increase in the total number of reproductive health care related services provided.', 'Reduced adolescent birth rates (aged 10-19 years) per 1,000 women in that age group.']
      },
      {
        id: 8,
        title: 'Achieve universal health coverage, including financial risk protection, access to quality essential health-care services and access to safe, effective, quality and affordable essential medicines and vaccines for all.',
        kpi: ['Number of people with access to affordable essential health services.', 'Increased proportion of people spending < 20% of total household expenditure on essential health services.']
      },
      {
        id: 9,
        title: 'By 2030, substantially reduce the number of deaths and illnesses from hazardous chemicals and air, water and soil pollution and contamination.',
        kpi: ['Reduction in the mortality rate attributed to household and ambient air pollution.', 'Reduction in mortality rate attributed to unsafe water, unsafe sanitation and lack of hygiene.']
      },
      {
        id: 10,
        title: 'Strengthen the implementation of the World Health Organization Framework Convention on Tobacco Control in all countries, as appropriate.',
        kpi: ['Reduction in the age-standardized prevalence of current tobacco use among persons aged 15 years and older.']
      },
      {
        id: 11,
        title: 'Support the research and development of vaccines and medicines for the communicable and noncommunicable diseases that primarily affect developing countries, provide access to affordable essential medicines and vaccines, in accordance with the Doha Declaration on the TRIPS Agreement and Public Health, which affirms the right of developing countries to use to the full the provisions in the Agreement on Trade-Related Aspects of Intellectual Property Rights regarding flexibilities to protect public health, and, in particular, provide access to medicines for all.',
        kpi: ['Increased proportion of the target population covered by all vaccines included in their national programs.', 'Increase in official development assistance to medical research and basic health sectors.', 'Increased proportion of health facilities that have a core set of relevant essential medicines available on an affordable and sustainable basis.']
      },
      {
        id: 12,
        title: 'Substantially increase health financing and the recruitment, development, training and retention of the health workforce in developing countries, especially in least developed countries and small island developing States.',
        kpi: ['Increased number of qualified health workers, their localized density and distribution.', 'Increase qualified health workers retention rate.']
      },
      {
        id: 13,
        title: 'Strengthen the capacity of all countries, in particular developing countries, for early warning, risk reduction and management of national and global health risks.',
        kpi: ['Increased effectiveness of global health warning management frameworks according to local and national definitions.']
      }
    ]
  },
  {
    id: 4,
    goal: 'Quality Education',
    img: require('../assets/svg/E-WEB-Goal-04.svg'),
    kpis: [
      {
        id: 1,
        title: 'By 2030, ensure that all girls and boys complete free, equitable and quality primary and secondary education leading to relevant and Goal-4 effective learning outcomes.',
        kpi: ['Increasing access to quality education by equitable distribution of funding and resources to all schools for children from all socio-economic backgrounds.', 'Increase proportion of children and young people (a) in grades 2/3; (b) at the end of primary; and (c) at the end of lower secondary achieving at least a minimum proficiency level in (i) reading and (ii) mathematics.']
      },
      {
        id: 2,
        title: 'By 2030, ensure that all girls and boys have access to quality early childhood development, care and pre-primary education so that they are ready for primary education.',
        kpi: ['Improve access and reduce costs to early childhood development programs, pre-primary education, and childcare.', 'Increased proportion of children under 5 years of age who are developmentally on track in health, learning and psychosocial well-being.', 'Participation rate growth in organized learning (one year before the official primary entry age).']
      },
      {
        id: 3,
        title: 'By 2030, ensure equal access for all women and men to affordable and quality technical, vocational and tertiary education, including university.',
        kpi: ['Participation rate growth of youth and adults in formal and non-formal education and training in the previous 12 months.']
      },
      {
        id: 4,
        title: 'By 2030, substantially increase the number of youth and adults who have relevant skills, including technical and vocational skills, for employment, decent jobs and entrepreneurship.',
        kpi: ['Proportion of youth and adults with information and communications technology (ICT) skills.', 'Proportion of youth and adults with nationally recognized technical and vocational qualifications.']
      },
      {
        id: 5,
        title: 'By 2030, eliminate gender disparities in education and ensure equal access to all levels of education and vocational training for the vulnerable, including persons with disabilities, indigenous peoples and children in vulnerable situations.',
        kpi: ['Number of vulnerable, disabled, indigenous, and vulnerable children with access to education.', 'Proportion of women with access to education in comparison to men.']
      },
      {
        id: 6,
        title: 'By 2030, ensure that all youth and a substantial proportion of adults, both men and women, achieve literacy and numeracy.',
        kpi: ['Proportion of population in a given age group achieving at least a fixed level of proficiency in functional (a) literacy and (b) numeracy skills.']
      },
      {
        id: 7,
        title: "By 2030, ensure that all learners acquire the knowledge and skills needed to promote sustainable development, including, among others, through education for sustainable development and sustainable lifestyles, human rights, gender equality, promotion of a culture of peace and non-violence, global citizenship and appreciation of cultural diversity and of culture's contribution to sustainable development.",
        kpi: ['Number of people with access to educational programs focused on sustainable development, including gender equality and human rights.']
      },
      {
        id: 8,
        title: 'Build and upgrade education facilities that are child, disability and gender sensitive and provide safe, non-violent, inclusive and effective learning environments for all.',
        kpi: ['Number of schools with access to (a) electricity; (b) the Internet for pedagogical purposes; (c) computers for pedagogical purposes; (d) adapted infrastructure and materials for students with disabilities; (e) basic drinking water; (f) single-sex basic sanitation facilities; and (g) basic handwashing facilities.']
      },
      {
        id: 9,
        title: 'By 2021, substantially expand globally the number of scholarships available to developing countries, in particular, least developed countries, small island developing States and African countries, for enrolment in higher education, including vocational training and information and communications technology, technical, engineering and scientific programs, in developed countries and other developing countries.',
        kpi: ['Increase in volume of official development assistance flows for scholarships.']
      },
      {
        id: 10,
        title: 'By 2030, substantially increase the supply of qualified teachers, including through international cooperation for teacher training in developing countries, especially least developed countries and small island developing states.',
        kpi: ['Number of teachers in: (a) pre-primary; (b) primary; (c) lower secondary; and (d) upper secondary education who have received at least the minimum organized teacher training (e.g. pedagogical training) pre-service or in-service required for teaching at the relevant level in a given country']
      }
    ]
  },
  {
    id: 5,
    goal: 'Gender Equality',
    img: require('../assets/svg/E-WEB-Goal-05.svg'),
    kpis: [
      {
        id: 1,
        title: 'End all forms of discrimination against all women and girls everywhere.',
        kpi: ['Increased participation of women and girls in education, quality work, governance, and leadership according to local/national definitions.', 'Proportion of people with access to robust legal frameworks which promote, enforce and monitor equality and non-discrimination on the basis of sex.']
      },
      {
        id: 2,
        title: 'Eliminate all forms of violence against all women and girls in the public and private spheres, including trafficking and sexual and other types of exploitation.',
        kpi: ['Decreased incidences of violence against, and exploitation of, all women and girls.', 'Reduction in the number of ever-partnered women and girls subjected to physical, sexual or psychological violence by a current or former intimate partner in the previous 12 months.']
      },
      {
        id: 3,
        title: 'Eliminate all harmful practices, such as child, early and forced marriage and female genital mutilation.',
        kpi: ['Decreased incidences of harmful practices against children.', 'Proportion of women who were married or in a union prior to the age of 18.', 'Proportion of girls and women aged 15-49 years who have undergone female genital mutilation/cutting.']
      },
      {
        id: 4,
        title: 'Recognize and value unpaid care and domestic work through the provision of public services, infrastructure and social protection policies and the promotion of shared responsibility within the household and the family as nationally appropriate.',
        kpi: ['Increased local/national public services, infrastructure, and social protection supporting unpaid care and domestic work as a responsibility shared across families regardless of gender.', 'Proportion of time spent on unpaid domestic and care work.']
      },
      {
        id: 5,
        title: "Ensure women's full and effective participation and equal opportunities for leadership at all levels of decision making in political, economic and public life.",
        kpi: ['Increased opportunities and policies for female leadership in political, economic, and public life.', 'Increase in the proportion of females representative in political, economic and public life.', 'Proportion of women in managerial positions.']
      },
      {
        id: 6,
        title: 'Ensure universal access to sexual and reproductive health and reproductive rights as agreed in accordance with the Programme of Action of the International Conference on Population and Development and the Beijing Platform for Action and the outcome documents of their review conferences.',
        kpi: ['Increased universal access to sexual and reproductive health and reproductive rights. Alternatively, decreased local/national legislation to limit access to sexual and reproductive health and reproductive rights.', 'Increased universal access to sexual and reproductive health and reproductive rights.', 'Decreased local/national legislation to limit access to sexual and reproductive health and reproductive rights.']
      },
      {
        id: 7,
        title: 'Undertake reforms to give women equal rights to economic resources, as well as access to ownership and control over land and other forms of property, financial services, inheritance and natural resources, in accordance with national laws.',
        kpi: ["Increased women's access to economic resources, access to ownership, and control over land and other forms of property, financial services, inheritance and natural resources."]
      },
      {
        id: 8,
        title: 'Enhance the use of enabling technology, in particular information and communications technology, to promote the empowerment of women.',
        kpi: ['Proportion of women who own a mobile telephone and have access to internet services.']
      },
      {
        id: 9,
        title: 'Adopt and strengthen sound policies and enforceable legislation for the promotion of gender equality and the empowerment of all women and girls at all levels.',
        kpi: ['Increased local/national policies and legislation promoting gender equality and empowerment of all women and girls at all levels.']
      }
    ]
  },
  {
    id: 6,
    goal: 'Clean Water and Sanitation',
    img: require('../assets/svg/E-WEB-Goal-06.svg'),
    kpis: [
      {
        id: 1,
        title: 'By 2030, achieve universal and equitable access to safe and affordable drinking water for all.',
        kpi: ['Increased proportion of population using safely managed drinking water services.']
      },
      {
        id: 2,
        title: 'By 2030, achieve access to adequate and equitable sanitation and hygiene for all and end open defecation, paying special attention to the needs of women and girls and those in vulnerable situations.',
        kpi: ['Increased proportion of population using (a) safely managed sanitation services and (b) a hand-washing facility with soap and water.']
      },
      {
        id: 3,
        title: 'By 2030, improve water quality by reducing pollution, eliminating dumping and minimizing release of hazardous chemicals and materials, halving the proportion of untreated wastewater and substantially increasing recycling and safe reuse globally.',
        kpi: ['Increased proportion of wastewater safely treated.', 'Increased proportion of bodies of water with good ambient water quality as defined by UN-Water.']
      },
      {
        id: 4,
        title: 'By 2030, substantially increase water-use efficiency across all sectors and ensure sustainable withdrawals and supply of freshwater to address water scarcity and substantially reduce the number of people suffering from water scarcity.',
        kpi: ['Reduction of fresh-water resource usage across all industries.', 'Reduced level of water stress (freshwater withdrawal as a proportion of available freshwater resources).']
      },
      {
        id: 5,
        title: 'By 2030, implement integrated water resources management at all levels, including through transboundary cooperation as appropriate.',
        kpi: ['Degree of integrated water resources management implementation (0-100)', 'Increases the density of cross-jurisdictional relationship linkages.']
      },
      {
        id: 6,
        title: 'By 2021, protect and restore water-related ecosystems, including mountains, forests, wetlands, rivers, aquifers and lakes.',
        kpi: ['Reduction in the loss of water-related ecosystems as defined by IUCN.']
      },
      {
        id: 7,
        title: 'By 2030, expand international cooperation and capacity-building support to developing countries in water- and sanitation-related activities and programs, including water harvesting, desalination, water efficiency, wastewater treatment, recycling and reuse technologies.',
        kpi: ['Increase in government-coordinated assistance surrounding water harvesting, desalination, water efficiency, wastewater treatment, recycling and reuse technologies.']
      },
      {
        id: 8,
        title: 'Support and strengthen the participation of local communities in improving water and sanitation management.',
        kpi: ['Proportion of local administrative units with established and operational policies and procedures for participation of local communities in water and sanitation management.']
      }
    ]
  },
  {
    id: 7,
    goal: 'Affordable and Clean Energy',
    img: require('../assets/svg/E-WEB-Goal-07.svg'),
    kpis: [
      {
        id: 1,
        title: 'By 2030, ensure universal access to affordable, reliable and modern energy services.',
        kpi: ['Increased proportion of population with access to electricity.', 'Increased security and reliability of electricity supply.', 'Increased proportion of population with primary reliance on clean fuels and technology.']
      },
      {
        id: 2,
        title: 'By 2030, increase substantially the share of renewable energy in the global energy mix.',
        kpi: ['Increase in renewable energy share of total final energy consumption.']
      },
      {
        id: 3,
        title: 'By 2030, double the global rate of improvement in energy efficiency',
        kpi: ['Reduce energy intensity measured in terms of primary energy per unit of output.']
      },
      {
        id: 4,
        title: 'By 2030, enhance international cooperation to facilitate access to clean energy research and technology, including renewable energy, energy efficiency and advanced and cleaner fossil-fuel technology, and promote investment in energy infrastructure and clean energy technology.',
        kpi: ['Increased international financial flows to developing countries to include renewable energy, energy efficiency and advanced and cleaner fossil-fuel technology.', 'Increase investment in energy infrastructure and clean energy technology.']
      },
      {
        id: 5,
        title: 'By 2030, expand infrastructure and upgrade technology for supplying modern and sustainable energy services for all in developing countries, in particular least developed countries, small island developing States, and land-locked developing countries, in accordance with their respective programs of support.',
        kpi: ['Increased installed capacity of renewable and sustainable energy technologies.', 'Increase in value of energy efficiency investments.', 'Increase in foreign direct investment in financial transfer for infrastructure and technology to sustainable development services.']
      }
    ]
  },
  {
    id: 8,
    goal: 'Decent Work and Economic Growth',
    img: require('../assets/svg/E-WEB-Goal-08.svg'),
    kpis: [
      {
        id: 1,
        title: 'Sustain per capita economic growth in accordance with national circumstances and, in particular, at least 7 per cent gross domestic product growth per annum in the least developed countries.',
        kpi: ['Total number of jobs created in emerging and developing economies.', 'Increase of annual growth rate of real GDP per capita in developing countries.']
      },
      {
        id: 2,
        title: 'Achieve higher levels of economic productivity through diversification, technological upgrading and innovation, including through a focus on high-value added and labor-intensive sectors.',
        kpi: ['Increase real GDP per employed person in developing countries.', 'Increase in the total number of jobs created in emerging and developing countries.']
      },
      {
        id: 3,
        title: 'Promote development-oriented policies that support productive activities, decent job creation, entrepreneurship, creativity and innovation, and encourage the formalization and growth of micro-, small- and medium-sized enterprises, including through access to financial services.',
        kpi: ['Increase in the number of registered companies.', 'Increase in the proportion of people with access to financial services.', 'Reduction in informal employment rates.']
      },
      {
        id: 4,
        title: 'Improve progressively, through 2030, global resource efficiency in consumption and production and endeavor to decouple economic growth from environmental degradation, in accordance with the 10-year framework of programs on sustainable consumption and production, with developed countries taking the lead.',
        kpi: ['Reduction of overall material footprint.', 'Reduction of domestic material consumption.']
      },
      {
        id: 5,
        title: 'By 2030, achieve full and productive employment and decent work for all women and men, including for young people and persons with disabilities, and equal pay for work of equal value.',
        kpi: ['Increase in hourly earnings of female and male employees, youth, and the disabled.', "Total number of people employed in 'decent work' as defined by the ILO.", "Total number of people with a disability employed in 'decent work' as defined by the ILO."]
      },
      {
        id: 6,
        title: 'By 2021, substantially reduce the proportion of youth not in employment, education or training.',
        kpi: ['Number of youth employed, educated, or trained in skills that contribute to towards career advancement.', 'Proportional reduction of the number of youth (aged 15-24 years) not in education, employment or training.']
      },
      {
        id: 7,
        title: 'Take immediate and effective measures to eradicate forced labor, end modern slavery and human trafficking and secure the prohibition and elimination of the worst forms of child labor, including recruitment and use of child soldiers, and by 2025 end child labor in all its forms.',
        kpi: ['Proportional reduction of the number of children aged 5-17 years engaged in child labor.']
      },
      {
        id: 8,
        title: 'Protect labor rights and promote safe and secure working environments for all workers, including migrant workers, in particular, women migrants, and those in precarious employment.',
        kpi: ['Reduction of the number of fatal and non-fatal occupational injuries, by sex and migrant status.', 'Increased access to labor rights representation.']
      },
      {
        id: 9,
        title: 'By 2030, devise and implement policies to promote sustainable tourism that creates jobs and promotes local culture and products.',
        kpi: ['Increase in sustainable tourism in proportion to total GDP growth.', 'Proportional increase in the number of jobs in sustainable tourism as a percentage of total tourism jobs.']
      },
      {
        id: 10,
        title: 'Strengthen the capacity of domestic financial institutions to encourage and expand access to banking, insurance and financial services for all.',
        kpi: ["Total number of 'underbanked people' given access to banking and financial services.", 'Reduction in the underserved population given access to banking and financial services.', 'Reduction of the number of adults (15 years and older) with no access to banking, insurance and financial services.']
      },
      {
        id: 11,
        title: 'Increase Aid for Trade support for developing countries, in particular least developed countries, including through the Enhanced Integrated Framework for Trade-Related Technical Assistance to Least Developed Countries.',
        kpi: ['Increase the number of Aid for Trade commitments and disbursements within the Enhanced Integrated Framework for Trade-related Technical Assistance to Least Developed Countries.']
      },
      {
        id: 12,
        title: 'By 2021, develop and operationalize a global strategy for youth employment and implement the Global Jobs Pact of the International Labour Organization.',
        kpi: ['Number of youth employment pathways developed via the Global Jobs Pact of the International Labour organization.', 'Increase in value of energy efficiency investments.', 'Number of people employed under a national strategy for youth employment.']
      }
    ]
  },
  {
    id: 9,
    goal: 'Industry, Innovation, and Infrastructure',
    img: require('../assets/svg/E-WEB-Goal-09.svg'),
    kpis: [
      {
        id: 1,
        title: 'Develop quality, reliable, sustainable and resilient infrastructure, including regional and transborder infrastructure, to support economic development and human well-being, with a focus on affordable and equitable access for all.',
        kpi: ['Increase in the number of low-income people that are utilizing quality, reliable, sustainable and resilient infrastructure.', 'Increase in value of freight volumes.']
      },
      {
        id: 2,
        title: "Promote inclusive and sustainable industrialization and, by 2030, significantly raise industry's share of employment and gross domestic product, in line with national circumstances, and double its share in least developed countries.",
        kpi: ['Increase in industrial manufacturing value.', 'Increase in industry share of employment and gross domestic product.']
      },
      {
        id: 3,
        title: 'Increase the access of small-scale industrial and other enterprises, in particular in developing countries, to financial services, including affordable credit, and their integration into value chains and markets.',
        kpi: ['Increased proportion of small-scale industries with access to loans and finance in developing countries.']
      },
      {
        id: 4,
        title: 'By 2030, upgrade infrastructure and retrofit industries to make them sustainable, with increased resource-use efficiency and greater adoption of clean and environmentally sound technologies and industrial processes, with all countries taking action in accordance with their respective capabilities.',
        kpi: ['Decrease CO2 emission per unit of value-added.', 'Increased proportion of materials from sustainable sources used by infrastructure companies.', 'Increased proportion of materials reused, or recycled from industrial infrastructure.']
      },
      {
        id: 5,
        title: 'Enhance scientific research, upgrade the technological capabilities of industrial sectors in all countries, in particular developing countries, including, by 2030, encouraging innovation and substantially increasing the number of research and development workers per 1 million people and public and private research and development spending.',
        kpi: ['The number of research and development workers per 1 million people.']
      },
      {
        id: 6,
        title: 'Facilitate sustainable and resilient infrastructure development in developing countries through enhanced financial, technological and technical support to African countries, least developed countries, landlocked developing countries and small island developing states.',
        kpi: ['Percent of sales to African countries, least developed countries, landlocked developing countries and small island developing states.']
      },
      {
        id: 7,
        title: 'Support domestic technology development, research and innovation in developing countries, including by ensuring a conducive policy environment for, inter alia, industrial diversification and value addition to commodities.',
        kpi: ['Increase the proportion of technology development, research and innovation administered in a developing countries.']
      },
      {
        id: 8,
        title: 'Significantly increase access to information and communications technology and strive to provide universal and affordable access to the Internet in the least developed countries by 2030.',
        kpi: ['Increased proportion of people with access to mobile or fixed internet services.']
      }
    ]
  },
  {
    id: 10,
    goal: 'Reduced Inequalities',
    img: require('../assets/svg/E-WEB-Goal-10.svg'),
    kpis: [
      {
        id: 1,
        title: 'By 2030, progressively achieve and sustain income growth of the bottom 40 per cent of the population at a rate higher than the national average.',
        kpi: ['Increase in household expenditure or income per capita among the bottom 40 percent of the population and the total population.']
      },
      {
        id: 2,
        title: 'By 2030, empower and promote the social, economic and political inclusion of all, irrespective of age, sex, disability, race, ethnicity, origin, religion or economic or other status.',
        kpi: ['Reduction in people living below 50 per cent of median income as defined by the World Bank', 'Increase the number of underrepresented minorities participating in social, economic, and/or political arenas.']
      },
      {
        id: 3,
        title: 'Ensure equal opportunity and reduce inequalities of outcome, including by eliminating discriminatory laws, policies and practices and promoting appropriate legislation, policies and action in this regard.',
        kpi: ['Increase in the proportion of people with open access to resources to report and resolve discriminatory laws, policies and practices.']
      },
      {
        id: 4,
        title: 'Adopt policies, especially fiscal, wage and social protection policies, and progressively achieve greater equality.',
        kpi: ['Increased labor share of GDP, comprising wages and social protection transfers.']
      },
      {
        id: 5,
        title: 'Improve the regulation and monitoring of global financial markets and institutions and strengthen the implementation of such regulations.',
        kpi: ['Increased number of regulations set in place to increase the monitoring of global financial markets.']
      },
      {
        id: 6,
        title: 'Ensure enhanced representation and voice for developing countries in decision-making in global international economic and financial institutions in order to deliver more effective, credible, accountable and legitimate institutions.',
        kpi: ['Increased proportion of developing countries with membership and voting rights in international organizations.']
      },
      {
        id: 7,
        title: 'Facilitate orderly, safe, regular and responsible migration and mobility of people, including through the implementation of planned and well-managed migration policies.',
        kpi: ['Increased number of people with access to orderly, safe, regular, and responsible migration and mobility channels.']
      },
      {
        id: 8,
        title: 'Implement the principle of special and differential treatment for developing countries, in particular, least developed countries, in accordance with World Trade Organization agreements.',
        kpi: ['Proportion of tariff lines applied to imports from least developed countries and developing countries with zero-tariff.']
      },
      {
        id: 9,
        title: 'Encourage official development assistance and financial flows, including foreign direct investment, to States where the need is greatest, in particular least developed countries, African countries, small island developing States and landlocked developing countries, in accordance with their national plans and programs.',
        kpi: ['Increased volume of official resource flows for development as defined by the OECD.']
      },
      {
        id: 10,
        title: 'By 2030, reduce to less than 3 per cent the transaction costs of migrant remittances and eliminate remittance corridors with costs higher than 5 per cent.',
        kpi: ['Reduction of transaction cost of migrant remittances in regions where they exceed 3%.', 'Reduction of remittance corridors with costs higher than 5%.']
      }
    ]
  },
  {
    id: 11,
    goal: 'Sustainable Cities and Communities',
    img: require('../assets/svg/E-WEB-Goal-11.svg'),
    kpis: [
      {
        id: 1,
        title: 'By 2030, ensure access for all to adequate, safe and affordable housing and basic services and upgrade slums.',
        kpi: ['Number of individuals housed sustainably (demonstrated multi-year affordability)', 'Proportion of urban population living in slums, informal settlements or inadequate housing.']
      },
      {
        id: 2,
        title: 'By 2030, provide access to safe, affordable, accessible and sustainable transport systems for all, improving road safety, notably by expanding public transport, with special attention to the needs of those in vulnerable situations, women, children, persons with disabilities and older persons.',
        kpi: ['Percentage increase of population that has convenient access to safe, affordable, and sustainable transportation.']
      },
      {
        id: 3,
        title: 'By 2030, enhance inclusive and sustainable urbanization and capacity for participatory, integrated and sustainable human settlement planning and management in all countries.',
        kpi: ['Decrease per capita land and resource consumption rate.', 'Increased proportion of cities with a direct participation structure of civil society in urban planning and management that operate regularly and democratically.']
      },
      {
        id: 4,
        title: "Strengthen efforts to protect and safeguard the world's cultural and natural heritage.",
        kpi: ['Increase total expenditure per capita on preservation, protection and conservation of all cultural and natural heritage.']
      },
      {
        id: 5,
        title: 'By 2030, significantly reduce the number of deaths and the number of people affected and substantially decrease the direct economic losses relative to global gross domestic product caused by disasters, including water-related disasters, with a focus on protecting the poor and people in vulnerable situations.',
        kpi: ['Number of deaths, missing people and directly affected persons attributed to disasters per 100,000 population.', 'Reduce direct economic loss in relation to GDP, damage to critical infrastructure and number of disruptions to basic services, attributed to disasters.']
      },
      {
        id: 6,
        title: 'By 2030, reduce the adverse per capita environmental impact of cities, including by paying special attention to air quality and municipal and other waste management.',
        kpi: ['Reduction of solid waste regularly generated per capita.', 'Increase adequate final discharge out of total urban solid waste generated.', 'Reduce annual mean levels (e.g. PM2.5 and PM10) of fine particulate matter in cities.']
      },
      {
        id: 7,
        title: 'By 2030, provide universal access to safe, inclusive and accessible, green and public spaces, in particular for women and children, older persons and persons with disabilities.',
        kpi: ['Increase the average share of built-up area of cities that is open space for public use.', 'Reduce the proportion of persons suffering physical or sexual harassment in the previous 12 months.']
      },
      {
        id: 8,
        title: 'Support positive economic, social and environmental links between urban, peri-urban and rural areas by strengthening national and regional development planning.',
        kpi: ['Increase the proportion of the population living in cities that implement urban and regional development plans aligning population projections and resource needs.']
      },
      {
        id: 9,
        title: 'By 2021, substantially increase the number of cities and human settlements adopting and implementing integrated policies and plans towards inclusion, resource efficiency, mitigation and adaptation to climate change, resilience to disasters, and develop and implement, in line with the Sendai Framework for Disaster Risk Reduction 2015-2030, holistic disaster risk management at all levels.',
        kpi: ['Increase the number of countries that adopt and implement national disaster risk reduction strategies in line with the Sendai Framework for Disaster Risk Reduction 2015-2030.', 'Increase the proportion of local governments that adopt and implement local disaster risk reduction strategies in line with national disaster risk reduction strategies.']
      },
      {
        id: 10,
        title: 'Support least developed countries, including through financial and technical assistance, in building sustainable and resilient buildings utilizing local materials.',
        kpi: ['Increase investment in least developed countries that is allocated to the construction and retrofitting of sustainable, resilient and resource-efficient buildings utilizing local materials.']
      }
    ]
  },
  {
    id: 12,
    goal: 'Responsible Consumption and Production',
    img: require('../assets/svg/E-WEB-Goal-12.svg'),
    kpis: [
      {
        id: 1,
        title: 'Implement the 10-year framework of programs on sustainable consumption and production, all countries taking action, with developed countries taking the lead, taking into account the development and capabilities of developing countries.',
        kpi: ['Number of countries with sustainable consumption and production (SCP) national action plans or SCP mainstreamed as a priority or a target into national policies']
      },
      {
        id: 2,
        title: 'By 2030, achieve the sustainable management and efficient use of natural resources.',
        kpi: ['Reduction in the material footprint per GDP.', 'Decrease in domestic material consumption per GDP.']
      },
      {
        id: 3,
        title: 'By 2030, halve per capita global food waste at the retail and consumer levels and reduce food losses along production and supply chains, including post-harvest losses.',
        kpi: ['Percentage reduction of food waste at retail, consumer and supply chain levels.']
      },
      {
        id: 4,
        title: 'By 2021, achieve the environmentally sound management of chemicals and all wastes throughout their life cycle, in accordance with agreed international frameworks, and significantly reduce their release to air, water and soil in order to minimize their adverse impacts on human health and the environment.',
        kpi: ['Increase in the total number of parties to international multilateral environmental agreements on hazardous waste and other chemicals.', 'Decrease in total volume of hazardous waste generated per capita.', 'Proportion of hazardous waste treated, by type of treatment.']
      },
      {
        id: 5,
        title: 'By 2030, substantially reduce waste generation through prevention, reduction, recycling and reuse.',
        kpi: ['Reduction in overall waste generation, measured in tons of material recycled.']
      },
      {
        id: 6,
        title: 'Encourage companies, especially large and transnational companies, to adopt sustainable practices and to integrate sustainability information into their reporting cycle.',
        kpi: ['Increase in the number of companies publishing sustainability reports.']
      },
      {
        id: 7,
        title: 'Promote public procurement practices that are sustainable, in accordance with national policies and priorities.',
        kpi: ['Increase in the number of countries implementing sustainable public procurement policies and action plans.']
      },
      {
        id: 8,
        title: 'By 2030, ensure that people everywhere have the relevant information and awareness for sustainable development and lifestyles in harmony with nature.',
        kpi: ['Increase in funding initiatives for education for sustainable development.', 'Increase in the total number of educators focused on sustainable development.', 'Proportions of primary and secondary institutions that offer sustainable development education.']
      },
      {
        id: 9,
        title: 'Support developing countries to strengthen their scientific and technological capacity to move towards more sustainable patterns of consumption and production.',
        kpi: ['Increase in the proportion of government funding allocated to research institutions in developing countries.', 'Increase in STEM-related educational programs.']
      },
      {
        id: 10,
        title: 'Develop and implement tools to monitor sustainable development impacts for sustainable tourism that creates jobs and promotes local culture and products.',
        kpi: ['Increase in the number of tools aiding local sustainable tourism initiatives.', 'Increase in the overall growth rates of tourism.']
      },
      {
        id: 11,
        title: 'Rationalize inefficient fossil-fuel subsidies that encourage wasteful consumption by removing market distortions, in accordance with national circumstances, including by restructuring taxation and phasing out those harmful subsidies, where they exist, to reflect their environmental impacts, taking fully into account the specific needs and conditions of developing countries and minimizing the possible adverse impacts on their development in a manner that protects the poor and the affected communities.',
        kpi: ['Reduction in fossil-fuel subsidies per unit of GDP (production and consumption)', 'Decreased proportion of total national expenditure on fossil fuels.']
      }
    ]
  },
  {
    id: 13,
    goal: 'Climate Action',
    img: require('../assets/svg/E-WEB-Goal-13.svg'),
    kpis: [
      {
        id: 1,
        title: 'Strengthen resilience and adaptive capacity to climate-related hazards and natural disasters in all countries.',
        kpi: ['Decreased number of deaths, missing persons and directly affected persons attributed to disasters per 100,000 population.', 'Increase in the proportion of countries that adopt and implement national disaster risk reduction strategies.', 'Increase in the proportion of local governments that adopt and implement local disaster risk reduction strategies in line with national disaster risk reduction strategies.']
      },
      {
        id: 2,
        title: 'Integrate climate change measures into national policies, strategies and planning.',
        kpi: ['Increase in the proportion of countries that adopt and implement national disaster risk reduction strategies.', 'Increase in the number of reports published surrounding climate change.', 'Proportion of countries that have communicated a strategy/plan for climate change measures.']
      },
      {
        id: 3,
        title: 'Improve education, awareness-raising and human and institutional capacity on climate change mitigation, adaptation, impact reduction and early warning.',
        kpi: ['Increase in the number of countries that have integrated mitigation, adaptation, impact reduction and early warning into primary, secondary and tertiary curricula.', 'Increase in communication channels strengthening institutional, systemic and individual capacity-building to implement adaptation, mitigation and technology transfer, and development actions.']
      },
      {
        id: 4,
        title: 'Implement the commitment undertaken by developed-country parties to the United Nations Framework Convention on Climate Change to a goal of mobilizing jointly $100 billion annually by 2021 from all sources to address the needs of developing countries in the context of meaningful mitigation actions and transparency on implementation and fully operationalize the Green Climate Fund through its capitalization as soon as possible.',
        kpi: ['Mobilize the $100 billion commitment']
      },
      {
        id: 5,
        title: 'Promote mechanisms for raising capacity for effective climate change-related planning and management in least developed countries and small island developing States, including focusing on women, youth and local and marginalized communities.',
        kpi: ['Increase in proportion of employment of women, youth and local and marginalized communities in developing countries.', 'Increase in total expenditures to developing countries and small islands with a focus on climate-change related activities.']
      },
      {
        id: 6,
        title: 'Encourage companies, especially large and transnational companies, to adopt sustainable practices and to integrate sustainability information into their reporting cycle.',
        kpi: ['Increase in the number of companies publishing sustainability reports.']
      },
      {
        id: 7,
        title: 'Promote public procurement practices that are sustainable, in accordance with national policies and priorities.',
        kpi: ['Increase in the number of countries implementing sustainable public procurement policies and action plans.']
      },
      {
        id: 8,
        title: 'By 2030, ensure that people everywhere have the relevant information and awareness for sustainable development and lifestyles in harmony with nature.',
        kpi: ['Increase in funding initiatives for education for sustainable development.', 'Increase in the total number of educators focused on sustainable development.', 'Proportions of primary and secondary institutions that offer sustainable development education.']
      },
      {
        id: 9,
        title: 'Support developing countries to strengthen their scientific and technological capacity to move towards more sustainable patterns of consumption and production.',
        kpi: ['Increase in the proportion of government funding allocated to research institutions in developing countries.', 'Increase in STEM-related educational programs.']
      },
      {
        id: 10,
        title: 'Develop and implement tools to monitor sustainable development impacts for sustainable tourism that creates jobs and promotes local culture and products.',
        kpi: ['Increase in the number of tools aiding local sustainable tourism initiatives.', 'Increase in the overall growth rates of tourism.']
      },
      {
        id: 11,
        title: 'Rationalize inefficient fossil-fuel subsidies that encourage wasteful consumption by removing market distortions, in accordance with national circumstances, including by restructuring taxation and phasing out those harmful subsidies, where they exist, to reflect their environmental impacts, taking fully into account the specific needs and conditions of developing countries and minimizing the possible adverse impacts on their development in a manner that protects the poor and the affected communities.',
        kpi: ['Reduction in fossil-fuel subsidies per unit of GDP (production and consumption)', 'Decreased proportion of total national expenditure on fossil fuels.']
      }
    ]
  },
  {
    id: 14,
    goal: 'Life Below Water',
    img: require('../assets/svg/E-WEB-Goal-14.svg'),
    kpis: [
      {
        id: 1,
        title: 'By 2025, prevent and significantly reduce marine pollution of all kinds, in particular from land-based activities, including marine debris and nutrient pollution.',
        kpi: ['Reduction of total marine debris, total nutrient pollution.', 'Reduction in coastal eutrophication and floating plastic debris density.']
      },
      {
        id: 2,
        title: 'By 2021, sustainably manage and protect marine and coastal ecosystems to avoid significant adverse impacts, including by strengthening their resilience, and take action for their restoration in order to achieve healthy and productive oceans.',
        kpi: ['Increased proportion of national exclusive economic zones managed using ecosystem-based approaches as defined by UNEP.', 'Area of the coastal ecosystem preserved and/ or restored.']
      },
      {
        id: 3,
        title: 'Minimize and address the impacts of ocean acidification, including through enhanced scientific cooperation at all levels.',
        kpi: ['Volume of CO2 extracted from oceans.', 'Reduction in marine acidity (Ionized Hydrogen).']
      },
      {
        id: 4,
        title: 'By 2021, effectively regulate harvesting and end overfishing, illegal, unreported and unregulated fishing and destructive fishing practices and implement science-based management plans, in order to restore fish stocks in the shortest time possible, at least to levels that can produce maximum sustainable yield as determined by their biological characteristics.',
        kpi: ['Number of fish stock species above maximum sustainable yield.', 'Reduction in incidents of unreported illegal fishing.']
      },
      {
        id: 5,
        title: 'By 2021, conserve at least 10 percent of coastal and marine areas, consistent with national and international law and based on the best available scientific information.',
        kpi: ['Increase in preserved local marine area.']
      },
      {
        id: 6,
        title: 'By 2021, prohibit certain forms of fisheries subsidies which contribute to overcapacity and overfishing, eliminate subsidies that contribute to illegal, unreported and unregulated fishing and refrain from introducing new such subsidies, recognizing that appropriate and effective special and differential treatment for developing and least developed countries should be an integral part of the World Trade Organization fisheries subsidies negotiation.',
        kpi: ['Number of eliminated subsidies that contribute to illegal, unreported, or unregulated fisheries.']
      },
      {
        id: 7,
        title: 'By 2030, increase the economic benefits to Small Island developing States and least developed countries from the sustainable use of marine resources, including through sustainable management of fisheries, aquaculture and tourism.',
        kpi: ['Increase in sustainable fisheries as a percentage of GDP for Small Island Developing States and Least Developed Countries.']
      },
      {
        id: 8,
        title: 'Increase scientific knowledge, develop research capacity and transfer marine technology, taking into account the Intergovernmental Oceanographic Commission Criteria and Guidelines on the Transfer of Marine Technology, in order to improve ocean health and to enhance the contribution of marine biodiversity to the development of developing countries, in particular small island developing States and least developed countries.',
        kpi: ['Direct contribution to x% increase in marine technology research as % of total research.', 'Proportional increase in marine technology research as a percentage of total research.']
      },
      {
        id: 9,
        title: 'Provide access for small-scale artisanal fishers to marine resources and markets.',
        kpi: ['Increase of access for small-scale artisanal fishers to marine resources and markets.']
      },
      {
        id: 10,
        title: 'Enhance the conservation and sustainable use of oceans and their resources by implementing international law as reflected in UNCLOS, which provides the legal framework for the conservation and sustainable use of oceans and their resources, as recalled in paragraph 158 of The Future We Want.',
        kpi: ['Proportional increase in legal proposals related to the conservation of sustainable use of oceans that are passed in each country.']
      }
    ]
  },
  {
    id: 15,
    goal: 'Life On Land',
    img: require('../assets/svg/E-WEB-Goal-15.svg'),
    kpis: [
      {
        id: 1,
        title: 'By 2021, ensure the conservation, restoration and sustainable use of terrestrial and inland freshwater ecosystems and their services, in particular forests, wetlands, mountains and drylands, in line with obligations under international agreements.',
        kpi: ['Increase of forest area as a proportion of total land area.', 'Increase of protected areas with sites promoting terrestrial and freshwater biodiversity for each individual ecosystem type.']
      },
      {
        id: 2,
        title: 'By 2021, promote the implementation of sustainable management of all types of forests, halt deforestation, restore degraded forests and substantially increase afforestation and reforestation globally.',
        kpi: ['Increase the number of businesses emerging towards sustainable forest management.', 'Increase in the number of sustainable managerial roles per capita.']
      },
      {
        id: 3,
        title: 'By 2030, combat desertification, restore degraded land and soil, including land affected by desertification, drought and floods, and strive to achieve a land degradation-neutral world.',
        kpi: ['Reduce proportion of land that is degraded over total land area.', 'Increase the number of new trees being planted globally.']
      },
      {
        id: 4,
        title: 'By 2030, ensure the conservation of mountain ecosystems, including their biodiversity, in order to enhance their capacity to provide benefits that are essential for sustainable development.',
        kpi: ['Increase total expenditure per capita on preservation, protection and conservation of mountain ecosystems, including their biodiversity.', 'Increase total coverage for protected areas of mountain ecosystems, including their biodiversity.']
      },
      {
        id: 5,
        title: 'Take urgent and significant action to reduce the degradation of natural habitats, halt the loss of biodiversity and, by 2021, protect and prevent the extinction of threatened species.',
        kpi: ['Increase total expenditure per capita on protection and prevention of the extinction of threatened species.', 'Reduce the degradation of natural habitats and halt the loss of biodiversity (Red List Index)']
      },
      {
        id: 6,
        title: 'Promote fair and equitable sharing of the benefits arising from the utilization of genetic resources and promote appropriate access to such resources, as internationally agreed.',
        kpi: ['Increase the number of countries that have adopted legislative, administrative and policy frameworks to ensure fair and equitable sharing of benefits.']
      },
      {
        id: 7,
        title: 'Take urgent action to end poaching and trafficking of protected species of flora and fauna and address both demand and supply of illegal wildlife products.',
        kpi: ['Reduce the proportion of traded wildlife that is poached or illicitly trafficked.']
      },
      {
        id: 8,
        title: 'By 2021, introduce measures to prevent the introduction and significantly reduce the impact of invasive alien species on land and water ecosystems and control or eradicate the priority species.',
        kpi: ['Increase the proportion of countries adopting relevant national legislation on invasive alien species.', 'Reduction in the number of invasive alien species per country.']
      },
      {
        id: 9,
        title: 'By 2021, integrate ecosystem and biodiversity values into national and local planning, development processes, poverty reduction strategies and accounts.',
        kpi: ['Increase in the total number of laws passed surrounding biodiversity.', 'Increase in the number of authorities working on progressing national targets in accordance with Aichi Biodiversity Target 2 of the Strategic Plan for biodiversity.']
      },
      {
        id: 10,
        title: 'Mobilize and significantly increase financial resources from all sources to conserve and sustainably use biodiversity and ecosystems.',
        kpi: ['Increase total expenditure on conservation and sustainable use of biodiversity and ecosystems.']
      },
      {
        id: 11,
        title: 'Mobilize significant resources from all sources and at all levels to finance sustainable forest management and provide adequate incentives to developing countries to advance such management, including for conservation and reforestation.',
        kpi: ['Increase official development assistance and public expenditure on conservation and sustainable use of biodiversity and ecosystems.']
      },
      {
        id: 12,
        title: 'Enhance global support for efforts to combat poaching and trafficking of protected species, including by increasing the capacity of local communities to pursue sustainable livelihood opportunities.',
        kpi: ['Reduce proportion of traded wildlife that was poached or illicitly trafficked.']
      }
    ]
  },
  {
    id: 16,
    goal: 'Peace, Justice, and Strong Institutions',
    img: require('../assets/svg/E-WEB-Goal-16.svg'),
    kpis: [
      {
        id: 1,
        title: 'Significantly reduce all forms of violence and related death rates everywhere.',
        kpi: ['Decrease of annual intentional homicide per capita.', 'Proportional reduction of total population per country that experiences physical violence, psychological violence, or sexual violence.', 'Decrease of annual conflict-related deaths per capita.']
      },
      {
        id: 2,
        title: 'End abuse, exploitation, trafficking and all forms of violence against and torture of children.',
        kpi: ['Reduction of children aged 1-17 reporting physical punishment and or psychological aggression by caregivers monthly.', 'Reduction in the number of victims of human trafficking per 100,000 population.', 'Reduction in the number of people aged 18-29 years who experienced sexual violence by age 18.']
      },
      {
        id: 3,
        title: 'Promote the rule of law at the national and international levels and ensure equal access to justice for all.',
        kpi: ['Proportional reduction of total population per country that experiences physical violence, psychological violence, or sexual violence.', 'Reduction of unsentenced detainees as a proportion of the overall prison population.']
      },
      {
        id: 4,
        title: 'By 2030, significantly reduce illicit financial and arms flows, strengthen the recovery and return of stolen assets and combat all forms of organized crime.',
        kpi: ['Total value of inward and outward illicit financial flows prevented.', 'Number of seized, found or surrendered arms whose illicit origin or context has been traced or established by a competent authority in line with international instruments.']
      },
      {
        id: 5,
        title: 'Substantially reduce corruption and bribery in all their forms.',
        kpi: ['Total value of public bribes prevented.']
      },
      {
        id: 6,
        title: 'Develop effective, accountable and transparent institutions at all levels.',
        kpi: ['Increase in primary government expenditures of the original approved budget.']
      },
      {
        id: 7,
        title: 'Ensure responsive, inclusive, participatory and representative decision-making at all levels.',
        kpi: ['Increased diversity within national and local public institutions, including (a) the legislatures; (b) the public service; and (c) the judiciary; along lines of sex, age, race, political view, and disabilities.', 'Increased diversity within voting along lines of sex, age, race, political view, and disabilities.']
      },
      {
        id: 8,
        title: 'Broaden and strengthen the participation of developing countries in the institutions of global governance.',
        kpi: ['Increased proportion of developing countries with member and voting rights in international organizations.']
      },
      {
        id: 9,
        title: 'By 2030, provide legal identity for all, including birth registration.',
        kpi: ['Proportional increase in the total number of children under the age of 5 yrs of age who have been registered.']
      },
      {
        id: 10,
        title: 'Ensure public access to information and protect fundamental freedoms, in accordance with national legislation and international agreements.',
        kpi: ['Proportional improvement in the number of cases related to breaches in fundamental freedoms such as: killing; kidnapping; enforced disappearance; arbitrary detention; torture of journalists, media personnel, trade unionists, or human rights activists, verified in the past 12 months.', 'Proportional increase of countries with constitutional, statutory and policy guarantees for public access to information.']
      },
      {
        id: 11,
        title: 'Strengthen relevant national institutions, including through international cooperation, for building capacity at all levels, in particular in developing countries, to prevent violence and combat terrorism and crime.',
        kpi: ['Increase in number independent national human rights institutions in compliance with the Paris Principles.']
      },
      {
        id: 12,
        title: 'Promote and enforce non-discriminatory laws and policies for sustainable development.',
        kpi: ['Proportional increase of population with access to reporting mechanisms for discrimination or harassment on the basis of a ground of discrimination prohibited under international human rights law.']
      }
    ]
  },
  {
    id: 17,
    goal: 'Partnerships for the Goals',
    img: require('../assets/svg/E-WEB-Goal-17.svg'),
    kpis: [
      {
        id: 1,
        title: 'Strengthen domestic resource mobilization, including through international support to developing countries, to improve domestic capacity for tax and other revenue collection.',
        kpi: ['Increase in the amount of domestic budget that is funded by taxes.', 'Total government revenue as a proportion of GDP.']
      },
      {
        id: 2,
        title: 'Developed countries to implement fully their official development assistance commitments, including the commitment by many developed countries to achieve the target of 0.7 per cent of ODA/GNI to developing countries and 0.15 to 0.20 per cent of ODA/GNI to least developed countries ODA providers are encouraged to consider setting a target to provide at least 0.20 per cent of ODA/GNI to least developed countries.',
        kpi: ['Increase development assistance to 0.7% Gross National Income (GNI).', 'Increase development assistance to developing countries to above 0.15% GNI.', 'Increase development assistance to Least Developed Countries (LDCs) to above 0.2% GNI.']
      },
      {
        id: 3,
        title: 'Mobilize additional financial resources for developing countries from multiple sources.',
        kpi: ['Increase in foreign direct investment (FDI), official development assistance and South-South cooperation as a proportion of total domestic budget.', 'Increase the volume of remittances as a proportion of total GDP.']
      },
      {
        id: 4,
        title: 'Assist developing countries in attaining long-term debt sustainability through coordinated policies aimed at fostering debt financing, debt relief and debt restructuring, as appropriate, and address the external debt of highly indebted poor countries to reduce debt distress.',
        kpi: ['Total national debt.']
      },
      {
        id: 5,
        title: 'Adopt and implement investment promotion regimes for least developed countries.',
        kpi: ['Increase in total investment for least developed countries.']
      },
      {
        id: 6,
        title: 'Enhance North-South, South-South and triangular regional and international cooperation on and access to science, technology and innovation and enhance knowledge sharing on mutually agreed terms, including through improved coordination among existing mechanisms, in particular at the United Nations level, and through a global technology facilitation mechanism.',
        kpi: ['Increase in the number of agreements in North-South, South-South and triangular regional and international cooperation on and access to science, technology and innovation.', 'Increase in the number of fixed internet broadband subscriptions.']
      },
      {
        id: 7,
        title: 'Promote the development, transfer, dissemination and diffusion of environmentally sound technologies to developing countries on favorable terms, including on concessional and preferential terms, as mutually agreed.',
        kpi: ['Increase in the total amount of approved funding for developing countries to promote the development, transfer, dissemination and diffusion of environmentally sound technologies.']
      },
      {
        id: 8,
        title: 'Fully operationalize the technology bank and science, technology and innovation capacity-building mechanism for least developed countries by 2017 and enhance the use of enabling technology, in particular information and communications technology.',
        kpi: ['Increase in the proportion of individuals using mobile or fixed internet services.']
      },
      {
        id: 9,
        title: 'Enhance international support for implementing effective and targeted capacity-building in developing countries to support national plans to implement all the sustainable development goals, including through North-South, South-South and triangular cooperation.',
        kpi: ['Increase in value of financial and technical assistance (including through North-South, South-South and triangular cooperation) committed to developing countries.']
      },
      {
        id: 10,
        title: 'Promote a universal, rules-based, open, non-discriminatory and equitable multilateral trading system under the World Trade Organization, including through the conclusion of negotiations under its Doha Development Agenda.',
        kpi: ['Total increase tariff reductions.']
      },
      {
        id: 11,
        title: "Significantly increase the exports of developing countries, in particular with a view to doubling the least developed countries' share of global exports by 2021.",
        kpi: ["Increase in the proportion of developing countries' and least developed countries' share of global exports."]
      },
      {
        id: 12,
        title: 'Realize timely implementation of duty-free and quota-free market access on a lasting basis for all least developed countries, consistent with World Trade Organization decisions, including by ensuring that preferential rules of origin applicable to imports from least developed countries are transparent and simple, and contribute to facilitating market access.',
        kpi: ['Reduction in the average number of tariffs faced by developing countries.']
      },
      {
        id: 13,
        title: 'Enhance global macroeconomic stability, including through policy coordination and policy coherence.',
        kpi: ['Increase in multi-governmental policy coherence as defined by the World Bank.']
      },
      {
        id: 14,
        title: 'Enhance policy coherence for sustainable development',
        kpi: ['Increased proportion of countries with mechanisms in place to enhance policy coherence for sustainable development.']
      },
      {
        id: 15,
        title: "Respect each country's policy space and leadership to establish and implement policies for poverty eradication and sustainable development.",
        kpi: ['Increased use of country-owned results frameworks and planning tools by providers of development cooperation.']
      },
      {
        id: 16,
        title: 'Enhance the global partnership for sustainable development, complemented by multi-stakeholder partnerships that mobilize and share knowledge, expertise, technology and financial resources, to support the achievement of the sustainable development goals in all countries, in particular developing countries.',
        kpi: ['Increase in the number of countries reporting progress in multi-stakeholder development effectiveness monitoring frameworks that support the achievement of the sustainable development goals.']
      },
      {
        id: 17,
        title: 'Encourage and promote effective public, public-private and civil society partnerships, building on the experience and resourcing strategies of partnerships.',
        kpi: ['Increased in total amount committed to (a) public-private partnerships and (b) civil society partnerships.']
      },
      {
        id: 18,
        title: 'By 2021, enhance capacity-building support to developing countries, including for least developed countries and small island developing States, to increase significantly the availability of high-quality, timely and reliable data disaggregated by income, gender, age, race, ethnicity, migratory status, disability, geographic location and other characteristics relevant in national contexts.',
        kpi: ['Proportional increase of sustainable development indicators produced at the national level with full disaggregation when relevant to the target.', 'Increases to the number of countries that have national statistical legislation that complies with the Fundamental Principles of Official Statistics.', 'Increases in the number of countries with a national statistical plan that is fully funded and under implementation.']
      },
      {
        id: 19,
        title: 'By 2030, build on existing initiatives to develop measurements of progress on sustainable development that complement gross domestic product and support statistical capacity-building in developing countries.',
        kpi: ['Increased value of all resources made available to strengthen statistical capacity in developing countries.', 'Increased accuracy and scale of national census data in developing countries.', 'Increased accuracy and scale of birth registrations in developing countries.', 'Increased accuracy and scale of death registrations in developing countries.']
      }
    ]
  }
]

export default goals
