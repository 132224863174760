const state = {
  progress_status: null,
  disabled_status: null,
  company: null,
  employee: null,
  error: {
    status: false,
    message: null
  },
  success: {
    status: false,
    message: null
  },
  disabled_modal: {
    status: false,
    message: null
  },
  bank_modal: {
    status: false,
    message: null
  },
  alert: {
    status: false,
    type: null,
    color: null,
    message: null
  },
  current_stage: 'ONBOARDING',
  next_stage: 'ONBOARDING',
  reload: false,
  institutions: []
}
const getters = {
}
const mutations = {
  setProgress (state, progress) {
    state.progress_status = progress
  },
  setDisabled (state, status) {
    state.disabled_status = status
  },
  setCompany (state, company) {
    state.company = company
  },
  setEmployee (state, employee) {
    state.employee = employee
  },
  setError (state, error) {
    state.error = error
  },
  setSuccess (state, success) {
    state.success = success
  },
  setDisabledModal (state, data) {
    state.disabled_modal = data
  },
  setBankModal (state, data) {
    state.bank_modal = data
  },
  setAlert (state, data) {
    state.alert = data
  },
  setCurrentStage (state, stage) {
    state.current_stage = stage
  },
  setNextStage (state, stage) {
    state.next_stage = stage
  },
  setReload (state, reload) {
    state.reload = reload
  },
  setInstitutions (state, institutions) {
    state.institutions = institutions
  },
  resetApp (state) {
    state.next_stage = state.company ? state.company.next_stage : 'ONBOARDING'
    state.current_stage = state.company ? state.company.current_stage : 'ONBOARDING'
    state.progress_status = null
    state.disabled_status = null
    state.company = null
    state.employee = null
    state.reload = false
  }
}
const actions = {
  setProgress ({ commit }, progress) {
    commit('setProgress', progress)
  },
  setDisabled ({ commit }, status) {
    commit('setDisabled', status)
  },
  setCompany ({ commit }, company) {
    commit('setCompany', company)
  },
  setEmployee ({ commit }, employee) {
    commit('setEmployee', employee)
  },
  setError ({ commit }, error) {
    commit('setError', error)
  },
  setSuccess ({ commit }, success) {
    commit('setSuccess', success)
  },
  setDisabledModal ({ commit }, data) {
    commit('setDisabledModal', data)
  },
  setBankModal ({ commit }, data) {
    commit('setBankModal', data)
  },
  setAlert ({ commit }, alert) {
    commit('setAlert', alert)
  },
  setCurrentStage ({ commit }, stage) {
    commit('setCurrentStage', stage)
  },
  setNextStage ({ commit }, stage) {
    commit('setNextStage', stage)
  },
  setReload ({ commit }, reload) {
    commit('setReload', reload)
  },
  setInstitutions ({ commit }, institutions) {
    commit('setInstitutions', institutions)
  },
  resetApp ({ commit }) {
    commit('resetApp')
  }
}

export default { state, getters, mutations, actions }
