
import { EventEmitter } from 'events'

const oomDefaults = {
  units: 'week',
  uiDebug: false,
  nWeeks: Math.round(5 * 365.2425 / 7),
  minFlow: 40,
  maxFlow: 1e9 / (364.2425 / 7),
  rev0: 100,
  revGrowth: 0.025,
  exp0: 1600,
  expGrowth: 0.0
}

export const daysPerYear = 365.2425
export const weeksPerYear = daysPerYear / 7
export const weeksPerQuarter = weeksPerYear / 4
export const weeksPerMonth = weeksPerYear / 12

export class GrowthModel extends EventEmitter {
  constructor (o) {
    super()

    this.units = (o.units === 'week' || o.units === 'month' || o.units === 'year' || o.units === 'quarter') ? o.units : oomDefaults.units

    this.uiDebug = !!o.uiDebug

    this.rev0 = o.rev0 ? o.rev0 : oomDefaults.rev0
    this.revGrowth = o.revGrowth ? o.revGrowth : oomDefaults.revGrowth
    this.exp0 = o.exp0 ? o.exp0 : oomDefaults.exp0
    this.expGrowth = o.expGrowth ? o.expGrowth : oomDefaults.expGrowth

    this.minFlow = o.minFlow ? o.minFlow : oomDefaults.minFlow
    this.maxFlow = o.maxFlow ? o.maxFlow : oomDefaults.maxFlow
    this.nWeeks = o.nWeeks ? o.nWeeks : oomDefaults.nWeeks

    this.showInstructions = 0.0
    this.everDragged = false

    this.calc()
  }

  asParms () {
    return {
      units: (this.units !== oomDefaults.units) ? this.units : undefined,
      uiDebug: (this.uiDebug !== oomDefaults.uiDebug) ? true : undefined,
      rev0: (this.rev0 !== oomDefaults.rev0) ? this.rev0 : undefined,
      exp0: (this.exp0 !== oomDefaults.exp0) ? this.exp0 : undefined,
      revGrowth: (this.revGrowth !== oomDefaults.revGrowth) ? this.revGrowth : undefined,
      expGrowth: (this.expGrowth !== oomDefaults.expGrowth) ? this.expGrowth : undefined,
      minFlow: (this.minFlow !== oomDefaults.minFlow) ? this.minFlow : undefined,
      maxFlow: (this.maxFlow !== oomDefaults.maxFlow) ? this.maxFlow : undefined,
      nWeeks: (this.nWeeks !== oomDefaults.nWeeks) ? this.nWeeks : undefined
    }
  }

  setUnits (units) {
    this.units = units
    this.calc()
  }

  toggleUnits () {
    if (this.units === 'week') {
      return this.setUnits('month')
    } else if (this.units === 'month') {
      return this.setUnits('quarter')
    } else if (this.units === 'quarter') {
      return this.setUnits('year')
    } else {
      return this.setUnits('week')
    }
  }

  /*
    Set revenue/expense at a given time. Supports dragging sliders in the UI.
    If week is zero, change the initial value, otherwise change growth
  */
  setRevAtWeek (week, rev) {
    if (week === 0) {
      this.rev0 = Math.round(rev)
    } else {
      this.revGrowth = Math.exp(Math.log(rev / this.rev0) / week) - 1
    }
    this.calc()
  }

  setExpAtWeek (week, exp) {
    if (week === 0) {
      this.exp0 = Math.round(exp)
    } else {
      this.expGrowth = Math.exp(Math.log(exp / this.exp0) / week) - 1
    }
    this.calc()
  }

  /*
    Return revenue/expense at a given week
  */
  revAtWeek (week) {
    return Math.exp(this.rev0Log + this.revLogGrowth * week)
  }

  expAtWeek (week) {
    return Math.exp(this.exp0Log + this.expLogGrowth * week)
  }

  /*
    Evolve the model forward/backward in time by the given number of weeks.
  */
  evolve (weeks) {
    this.exp0 *= Math.exp(this.expLogGrowth * weeks)
    this.rev0 *= Math.exp(this.revLogGrowth * weeks)
    this.calc()
  }

  calc () {
    this.rev0Log = Math.log(this.rev0)
    this.exp0Log = Math.log(this.exp0)
    this.revLogGrowth = Math.log(1 + this.revGrowth)
    this.expLogGrowth = Math.log(1 + this.expGrowth)

    this.revNLog = this.rev0Log + this.revLogGrowth * this.nWeeks
    this.expNLog = this.exp0Log + this.expLogGrowth * this.nWeeks

    this.revN = Math.exp(this.revNLog)
    this.expN = Math.exp(this.expNLog)

    /*
      solve for exp(rev0Log + n*revLogGrowth) === exp(exp0Log + n*expLogGrowth);
      n = (exp0Log - rev0Log) / (revLogGrowth - expLogGrowth)
    */
    if (this.exp0Log > this.rev0Log) {
      this.breakevenWeek = (this.exp0Log - this.rev0Log) / (this.revLogGrowth - this.expLogGrowth)
      this.breakevenFlow = Math.exp(this.rev0Log + this.revLogGrowth * this.breakevenWeek)

      /*
        Integrate revenue from 0 to breakeven
      */
      this.breakevenTotRev = this.rev0 * (this.revLogGrowth === 0 ? this.breakevenWeek : ((Math.exp(this.revLogGrowth * this.breakevenWeek) - 1) / this.revLogGrowth))
      this.breakevenTotExp = this.exp0 * (this.expLogGrowth === 0 ? this.breakevenWeek : ((Math.exp(this.expLogGrowth * this.breakevenWeek) - 1) / this.expLogGrowth))
      this.capitalNeeded = this.breakevenTotExp - this.breakevenTotRev
    } else {
      this.breakevenWeek = 0
      this.breakevenFlow = this.rev0
      this.breakevenTotRev = 0
      this.breakevenTotExp = 0
      this.capitalNeeded = 0
    }

    // when will we make $1B / yr?
    this.ipoWeek = (Math.log(1e9 / 52) - this.rev0Log) / this.revLogGrowth
    this.emit('changed')
  }

  /*
    If we're animating the UI, move forward by dt seconds.
  */
  animate (dt) {
    if (this.everDragged && this.showInstructions > 0) {
      this.showInstructions = Math.max(0, this.showInstructions - 0.8 * dt)
      this.emit('changed')
    }
  }
}
