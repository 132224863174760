export const industries = [
  'Adtech',
  'Aerospace',
  'Agriculture',
  'Analytics',
  'B2B SaaS',
  'Biotech',
  'Community',
  'Construction',
  'Consumer',
  'Continuing Education',
  'Crypto/Blockchain',
  'Developer Tools',
  'Energy',
  'Enterprise',
  'Entertainment',
  'Education',
  'Finance',
  'Fitness',
  'Food/Beverage',
  'Freight',
  'Gaming',
  'Government',
  'Hard Tech',
  'Hardware',
  'Healthcare',
  'Insurance',
  'K-12',
  'Language Learning',
  'Marketplace',
  'Media',
  'Medical Devices',
  'Mental Health',
  'Real Estate',
  'Retail',
  'Recruiting',
  'Robotics',
  'Sales Enablement',
  'Security',
  'Sustainability',
  'Transportation',
  'VR/AR',
  'Wellness',
  'Property',
  'Other'
]

export default industries
