import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
// import DashboardLayout from '../components/layouts/DashboardLayout.vue'
// import Diligence from '../views/Diligence.vue'
import Signup from '../views/Signup.vue'
import SignIn from '../views/SignIn.vue'
import ForgotPassword from '../views/forgotpassword.vue'
import store from '@/store'
import NewDashboardLayout from '../components/layouts/NewDashboardLayout.vue'
// import SideBar from '../components/sidebar/Sidebar.vue'
// import DiligenceSidebar from '../components/sidebar/DiligenceSidebar.vue'
import Onboarding from '../views/pages/Onboarding.vue'
// import FounderJourney from '../views/pages/stage1.vue'
import DiligenceLayout from '../components/layouts/DiligenceLayout.vue'
import FinancialLayouts from '../components/layouts/FinancialLayouts.vue'
import SDGAlignment from '../views/impact-assessment/SDGAlignment.vue'
import FollowUpQuestions from '../views/impact-assessment/FollowUpQuestions.vue'
import BusinessIntegrity from '../views/impact-assessment/BusinessIntegrity.vue'
import SocialManagement from '../views/impact-assessment/SocialManagement.vue'
import ImpactAssessmentLayout from '../components/layouts/ImpactAssessmentLayout.vue'
import Integrations from '../views/Integrations.vue'
import GrowthModel from '../views/GrowthModel.vue'
import Oauth from '../views/oauth.vue'
import ProfileLayout from '../components/layouts/ProfileLayout.vue'
import UserDetails from '../views/settings/UserDetails.vue'
import TeamSettings from '../views/settings/TeamSettings.vue'
import { computed } from 'vue'

const company = computed(() => store.state.app.company)

const redirect = () => {
  if (company.value) {
    if (company.value.stage_status === 'Approved') {
      return company.value.next_stage
    } else {
      return company.value.current_stage
    }
  }
  return 'ONBOARDING'
}

const redirectRoute = redirect()

const routes = [
  {
    path: '',
    redirect: '/dashboard',
    name: 'NewDashboardLayout',
    component: NewDashboardLayout,
    children: [
      {
        path: '/dashboard',
        name: 'Home',
        component: Home
      },
      {
        path: 'impact-assessment',
        name: 'Impact Assessment',
        redirect: '/impact-assessment/sdg-alignment',
        component: ImpactAssessmentLayout,
        children: [
          {
            path: 'sdg-alignment',
            name: 'SDG Alignment',
            component: SDGAlignment
          },
          {
            path: 'follow-up-questions',
            name: 'FollowUp Questions',
            component: FollowUpQuestions
          },
          {
            path: 'business-integrity',
            name: 'Business Integrity',
            component: BusinessIntegrity
          },
          {
            path: 'social-management',
            name: 'Environmental and Social Management',
            component: SocialManagement
          }
          // /impact-assessment/social-management
        ]
      },
      {
        path: 'financial-insights',
        redirect: '/financial-insights/integrations',
        name: 'Financial',
        component: FinancialLayouts,
        children: [
          {
            path: 'integrations',
            name: 'Integrations',
            component: Integrations
          },
          {
            path: 'growth-model',
            name: 'Growth Model',
            component: GrowthModel
          }
        ]
      },
      {
        path: 'profile',
        redirect: '/profile/user-details',
        name: 'Profile',
        component: ProfileLayout,
        children: [
          {
            path: 'user-details',
            name: 'User Details',
            component: UserDetails
          },
          {
            path: 'team',
            name: 'Team Settings',
            component: TeamSettings
          }
          // {
          //   path: 'growth-model',
          //   name: 'Growth Model',
          //   component: GrowthModel
          // }
        ]
      },
      {
        path: '/diligence',
        redirect: { name: `${redirectRoute}` },
        name: 'Deligence',
        component: DiligenceLayout,
        children: [
          {
            path: 'onboarding',
            name: 'ONBOARDING',
            component: Onboarding
          },
          {
            path: 'founder-journey',
            name: 'STAGE 1',
            component: () => import('../views/pages/stage1.vue')
          },
          {
            path: 'data-request-one',
            name: 'STAGE 2',
            component: () => import('../views/pages/stage2.vue')
          },
          {
            path: 'product-tech',
            name: 'STAGE 3',
            component: () => import('../views/pages/stage3.vue')
          },
          {
            path: 'data-request-two',
            name: 'STAGE 4',
            component: () => import('../views/pages/stage4.vue')
          },
          {
            path: 'unit-economics',
            name: 'STAGE 5',
            component: () => import('../views/pages/stage5.vue')
          },
          {
            path: 'data-request-three',
            name: 'STAGE 6',
            component: () => import('../views/pages/stage6.vue')
          },
          {
            path: 'fundraising',
            name: 'STAGE 7',
            component: () => import('../views/pages/stage7.vue')
          },
          {
            path: 'data-request-four',
            name: 'STAGE 8',
            component: () => import('../views/pages/stage8.vue')
          },
          {
            path: 'survey',
            name: 'STAGE 9',
            component: () => import('../views/pages/stage9.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/sign-up',
    name: 'Signup',
    component: Signup,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/oauth',
    name: 'Oauth',
    component: Oauth,
    meta: {
      allowAnonymous: true
    }
  }
  // {
  //   path: '',
  //   redirect: '/dashboard',
  //   name: 'Home',
  //   component: DashboardLayout,
  //   children: [
  //     {
  //       path: '/dashboard',
  //       name: 'Home',
  //       component: Home
  //     },
  //     {
  //       path: '/diligence',
  //       name: 'Diligence',
  //       component: Diligence
  //     }
  //   ]
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if ((to.name === 'SignIn' || to.name === 'Signup' || to.name === 'ForgotPassword') && store.state.auth.isUserLoggedIn) {
    next({ path: '/dashboard' })
  } else if (!to.meta.allowAnonymous && !store.state.auth.isUserLoggedIn && to.name === 'Diligence') {
    next({
      path: '/sign-in',
      query: { redirect: to.fullPath }
    })
  } else if (!to.meta.allowAnonymous && !store.state.auth.isUserLoggedIn && (to.name !== 'Diligence')) {
    next({
      path: '/sign-up'
    })
  } else {
    next()
  }
})

export default router
