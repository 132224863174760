<template>
  <div>
  </div>
</template>

<script>
// import { useRouter }  from 'vue-router'
import { useStore } from 'vuex'
export default {
  name: 'Oauth',
  async setup () {
    // const router = useRouter()
    const store = useStore()
    const apiUrl = process.env.VUE_APP_API_URL
    const postLinkSuccessEvent = async (type, metadata) => {
      await fetch(`${apiUrl}/api/v1/app/plaid/log-link-event`, {
        method: 'POST',
        body: JSON.stringify({
          type: type,
          session_id: metadata.link_session_id,
          request_id: metadata.request_id || null,
          error_type: null,
          error_code: null,
          status: metadata.status || null
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + ' ' + appToken
        }
      })
    }
    const postLinkEvent = async (type, metadata, error) => {
      await fetch(`${apiUrl}/api/v1/app/plaid/log-link-event`, {
        method: 'POST',
        body: JSON.stringify({
          type: type,
          session_id: metadata.link_session_id,
          request_id: metadata.request_id,
          error_type: error.error_type || null,
          error_code: error.error_code || null,
          status: metadata.status
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + ' ' + appToken
        }
      })
    }
    const appToken = localStorage.getItem('app-token')

    const linkToken = localStorage.getItem('link_token')
    const createLinkToken = async () => {
      const res = await fetch(`${apiUrl}/api/v1/app/plaid/create-link-token`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer' + ' ' + appToken
        }
      })
      const data = await res.json()
      const linkToken = data.data.link_token
      localStorage.setItem('link_token', linkToken)
      return linkToken
    }
    // eslint-disable-next-line no-undef
    const handler = Plaid.create({
      token: linkToken,
      receivedRedirectUri: window.location.href,
      onSuccess: async (publicToken, metadata) => {
        await postLinkSuccessEvent('Success', metadata, null)
        await fetch(`${apiUrl}/api/v1/app/plaid/get-access-token`, {
          method: 'POST',
          body: JSON.stringify({
            public_token: publicToken,
            institution_id: metadata.institution.institution_id
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + ' ' + appToken
          }
        })
        window.location.href = '/financial-insights/integrations'
      },
      onEvent: (eventName, metadata) => {
        console.log('Event:', eventName)
        console.log('Metadata:', metadata)
        if (eventName === 'HANDOFF') {
          const success = {
            status: true,
            message: 'Your data has been added successfully. You can add multiple accounts to complete your financial profile.'
          }
          createLinkToken()
          store.commit('setBankModal', success)
        }
      },
      onExit: async (error, metadata) => {
        await postLinkEvent('Exit', metadata, error)
        console.log(error, metadata)
      }
    })
    handler.open()
    return {
    }
  }
}
</script>

<style lang="scss" scoped></style>
