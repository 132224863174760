<template>
  <div class="flex flex-col w-full mx-auto rounded-lg bg-white px-2 pt-16 sm:px-0">
    <!-- Team content -->
    <div
      :class="[
        'bg-white p-0 relative',
        'ring-white ring-opacity-60 ring-offset-2 ring-offset-[transparent] focus:outline-none focus:ring-2',
      ]"
    >
      <svg v-if="loading" class="w-full h-full absolute left-1/2 top-1/2 bottom-1/2 right-1/2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" stroke="#000000" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
        </circle>
      </svg>
      <div
        v-else
        class="p-6 border-[1px] border-[#E5E6E6] rounded-[8px] w-full overflow-x-scroll z-[5] h-screen max-h-[500px]"
      >
        <div class="w-full flex items-center justify-between mb-8">
          <h2 class="font-medium text-[20px] leading-[28px]">Members</h2>
          <button
            @click="isCreatingTeamMember = true"
            class="px-[10px] py-[8px] text-[#187CB4] font-medium text-[13px] leading-[15px] flex items-center w-[150px] bg-[#F0FAFF] rounded-[6px]"
          >
            <span class="mr-[5px]">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00065 14.6666C11.6673 14.6666 14.6673 11.6666 14.6673 7.99992C14.6673 4.33325 11.6673 1.33325 8.00065 1.33325C4.33398 1.33325 1.33398 4.33325 1.33398 7.99992C1.33398 11.6666 4.33398 14.6666 8.00065 14.6666Z"
                  stroke="#187CB4"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.33398 8H10.6673"
                  stroke="#187CB4"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 10.6666V5.33325"
                  stroke="#187CB4"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            Add new member
          </button>
        </div>
        <table
          class="text-black sm:text-[11px] text-[8px] font-normal sm:leading-[11px] leading-[8px] w-full"
        >
          <thead>
            <tr>
              <th class="text-greyText text-left w-1/5 py-4 font-normal whitespace-nowrap pr-4">FIRST NAME</th>
              <th class="text-greyText text-left w-1/5 py-4 font-normal whitespace-nowrap pr-4">LAST NAME</th>
              <th class="text-greyText text-left w-1/5 py-4 font-normal whitespace-nowrap pr-4">ROLE</th>
              <th class="text-greyText text-left w-1/5 py-4 font-normal whitespace-nowrap pr-4">LAST LOGIN</th>
              <th class="text-greyText text-left w-1/5 py-4 font-normal whitespace-nowrap pr-4">STATUS</th>
              <!-- <th class="text-greyText text-left w-1/5"></th> -->
            </tr>
          </thead>
          <tbody class="text-black w-full">
            <tr v-for="(member, idx) in allUsers" :key="idx" class="flex w-[max-content] text-sm">
              <td class="font-normal whitespace-nowrap py-6">
                {{ member.first_name }}
              </td>
              <td class="font-normal whitespace-nowrap py-6">
                {{ member.last_name }}
              </td>
              <td class="font-normal whitespace-nowrap py-6">
                {{ member.role }}
              </td>
              <td class="font-normal whitespace-nowrap py-6">
                {{ formatDate(member.last_logged_in) }}
              </td>
              <td class="font-normal whitespace-nowrap py-6">
                <span
                  :class="[
                    'w-[max-content] border-[0.6px] border-[transparent] bg-[#F5FFFA] py-[2px] px-[8px] rounded-[100px] font-normal text-[12px] text-[#1FC16D] leading-[20px] flex items-center justify-center',
                    member.status === 'Active'
                      ? 'border-[transparent] bg-[#F5FFFA] text-[#1FC16D]'
                      : 'border-[transparent] bg-[#FFF6F5] text-[#F45252]',
                  ]"
                >
                  {{ member.status }}
                </span>
              </td>
              <td
                @click="selectedTeamMemberIdx = idx"
                class="font-normal whitespace-nowrap py-6"
              >
                <Menu as="div" class="relative inline-block text-left">
                  <MenuButton>
                    <svg
                      width="25"
                      height="26"
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4.5" cy="13" r="2.5" fill="#666666" />
                      <circle cx="12.5" cy="13" r="2.5" fill="#666666" />
                      <circle cx="20.5" cy="13" r="2.5" fill="#666666" />
                    </svg>
                  </MenuButton>
                  <transition
                    enter-active-class="transition duration-100 ease-out"
                    enter-from-class="transform scale-95 opacity-0"
                    enter-to-class="transform scale-100 opacity-100"
                    leave-active-class="transition duration-75 ease-in"
                    leave-from-class="transform scale-100 opacity-100"
                    leave-to-class="transform scale-95 opacity-0"
                  >
                    <MenuItems
                      class="absolute right-0 mt-2 w-24 origin-top-right divide-y divide-gray-100 rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[10]"
                    >
                      <div class="px-1 py-1">
                        <MenuItem v-slot="{ active }">
                          <button
                            @click="openEditUserModal(idx)"
                            :class="[
                              active ? 'bg-black text-white' : 'text-gray-900',
                              'group flex w-full items-center rounded-sm px-2 py-2 text-sm font-normal',
                            ]"
                          >
                            Edit
                          </button>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                          <button
                            @click="isRemovingTeamMember = true"
                            :class="[
                              active ? 'bg-[#F45252] text-white' : 'text-[#F45252]',
                              'group flex w-full items-center rounded-sm px-2 py-2 text-sm font-normal',
                            ]"
                          >
                            Remove
                          </button>
                        </MenuItem>
                      </div>
                    </MenuItems>
                  </transition>
                </Menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modals -->

    <!-- Adding a member -->
    <Modal :modalActive="isCreatingTeamMember === true">
      <div class="bg-white w-[399px] rounded-[6px] p-6 relative">
        <button
          @click="closeModal()"
          class="absolute top-[20px] right-[20px] w-[24px] h-[24px]"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
              fill="#0D0D0D"
            />
          </svg>
        </button>
        <div class="flex flex-col items-start justify-center">
          <h3
            class="font-medium font-Rubik text-[18px] leading-[24px] text-black mb-[12px] text-left md:w-[auto] w-full"
          >
            Create
          </h3>
          <hr class="w-full" />
          <div class="font-Rubik w-full text-left mt-6">
            <div class="relative w-full mb-0">
              <label
                class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
                for="createFirstname"
              >
                First name
              </label>
              <input
                id="createFirstname"
                name="createFirstname"
                type="text"
                rows="1"
                placeholder="Mary"
                class="px-4 py-4 mt-2 mb-6 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border-[#D1D2D2] rounded-[4px] bg-white w-full focus:outline-[#D1D2D2] focus:border-[#D1D2D2] hover:border-[#D1D2D2] focus:ring-0"
                style="transition: 'all .15s ease'"
                v-model="initialNewUserData.first_name"
              />

              <!-- <div v-if="v$.motivation.$error">
                    <p
                      v-if="v$.motivation.required.$invalid"
                      class="text-red-400 text-[10px] font-light"
                    >
                      Motivation is required
                    </p>
                    <p
                      v-if="v$.motivation.minLengthValue.$invalid"
                      class="text-red-400 text-[10px] font-light"
                    >
                      Motivation should be at least 3 characters long
                    </p>
                  </div> -->
            </div>
            <div class="relative w-full mb-6">
              <label
                class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
                for="createLastname"
              >
                Last name
              </label>
              <input
                id="createLastname"
                name="createLastname"
                type="text"
                rows="1"
                placeholder="Basset-Browne"
                class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border-[#D1D2D2] rounded-[4px] bg-white w-full focus:outline-[#D1D2D2] focus:border-[#D1D2D2] hover:border-[#D1D2D2] focus:ring-0"
                style="transition: 'all .15s ease'"
                v-model="initialNewUserData.last_name"
              />

              <!-- <div v-if="v$.motivation.$error">
                    <p
                      v-if="v$.motivation.required.$invalid"
                      class="text-red-400 text-[10px] font-light"
                    >
                      Motivation is required
                    </p>
                    <p
                      v-if="v$.motivation.minLengthValue.$invalid"
                      class="text-red-400 text-[10px] font-light"
                    >
                      Motivation should be at least 3 characters long
                    </p>
                  </div> -->
            </div>
            <div class="relative w-full mb-6">
              <label
                class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
                for="createMemberEmail"
              >
                Email
              </label>
              <input
                id="createMemberEmail"
                name="createMemberEmail"
                type="text"
                rows="1"
                placeholder="mary@exaample.com"
                class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border-[#D1D2D2] rounded-[4px] bg-white w-full focus:outline-[#D1D2D2] focus:border-[#D1D2D2] hover:border-[#D1D2D2] focus:ring-0"
                style="transition: 'all .15s ease'"
                v-model="initialNewUserData.email"
              />

              <!-- <div v-if="v$.motivation.$error">
                    <p
                      v-if="v$.motivation.required.$invalid"
                      class="text-red-400 text-[10px] font-light"
                    >
                      Motivation is required
                    </p>
                    <p
                      v-if="v$.motivation.minLengthValue.$invalid"
                      class="text-red-400 text-[10px] font-light"
                    >
                      Motivation should be at least 3 characters long
                    </p>
                  </div> -->
            </div>
            <div class="relative w-full mb-6">
              <label
                class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
                for="createMemberRole"
              >
                Role
              </label>
              <Listbox v-model="selectedUserRole">
                <div class="relative mt-1">
                  <ListboxButton
                    class="relative w-full cursor-pointer rounded-[4px] bg-white py-4 pl-4 pr-10 text-left focus:outline-[#D1D2D2] focus:border-[#D1D2D2] hover:border-[#D1D2D2] focus:ring-0 text-sm border-[#D1D2D2] border-[1px]"
                  >
                    <span class="block truncate">{{ selectedUserRole }}</span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        v-slot="{ active, selected }"
                        v-for="role in userRoles"
                        :key="role.name"
                        :value="role"
                        as="template"
                      >
                        <li
                          :class="[
                            active ? 'bg-black text-white font-light' : 'text-greyText',
                            'relative cursor-pointer select-none py-2 pl-10 pr-4 font-normal',
                          ]"
                        >
                          <span
                            :class="[
                              selected ? 'font-medium' : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ role }}</span
                          >
                          <!-- <span
                            v-if="selected"
                            class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                          >
                          </span> -->
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>

              <!-- <div v-if="v$.motivation.$error">
                    <p
                      v-if="v$.motivation.required.$invalid"
                      class="text-red-400 text-[10px] font-light"
                    >
                      Motivation is required
                    </p>
                    <p
                      v-if="v$.motivation.minLengthValue.$invalid"
                      class="text-red-400 text-[10px] font-light"
                    >
                      Motivation should be at least 3 characters long
                    </p>
                  </div> -->
            </div>
          </div>
          <div class="flex items-center md:justify-end justify-center w-full">
            <button
              @click="saveNewUser()"
              class="font-Rubik mt-6 border-b border-teal font-medium py-[12px] md:px-[20px] px-[32px] text-white text-[13px] leading-[15.41px] rounded-[6px] bg-black md:max-w-[65px] max-w-[100%] w-[50%]"
            >
              <span :class="loading ? 'mr-2' : 'mr-0'" class="text-white">Save</span>
                <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                  <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                  </circle>
                </svg>
            </button>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Editing a member -->
    <Modal :modalActive="isEditingTeamMember === true">
      <div class="bg-white w-[399px] rounded-[6px] p-6 relative">
        <button
          @click="closeModal()"
          class="absolute top-[20px] right-[20px] w-[24px] h-[24px]"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
              fill="#0D0D0D"
            />
          </svg>
        </button>
        <template v-if="isEditingTeamMember === true">
          <div class="flex flex-col items-start justify-center">
            <h3
              class="font-medium font-Rubik text-[18px] leading-[24px] text-black mb-[12px] text-left md:w-[auto] w-full"
            >
              Edit member details
            </h3>
            <hr class="w-full" />
            <div class="font-Rubik w-full text-left mt-6">
              <div class="relative w-full mb-0">
                <label
                  class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
                  for="editFirstname"
                >
                  First name
                </label>
                <input
                  id="editFirstname"
                  name="editFirstname"
                  type="text"
                  rows="1"
                  placeholder="Mary"
                  class="px-4 py-4 mt-2 mb-6 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border-[#D1D2D2] rounded-[4px] bg-white w-full focus:outline-[#D1D2D2] focus:border-[#D1D2D2] hover:border-[#D1D2D2] focus:ring-0"
                  style="transition: 'all .15s ease'"
                  v-model="editUserData.first_name"
                />
              </div>
              <div class="relative w-full mb-6">
                <label
                  class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
                  for="editLastname"
                >
                  Last name
                </label>
                <input
                  id="editLastname"
                  name="editLastname"
                  type="text"
                  rows="1"
                  placeholder="Basset-Browne"
                  class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border-[#D1D2D2] rounded-[4px] bg-white w-full focus:outline-[#D1D2D2] focus:border-[#D1D2D2] hover:border-[#D1D2D2] focus:ring-0"
                  style="transition: 'all .15s ease'"
                  v-model="editUserData.last_name"
                />
              </div>
              <div class="relative w-full mb-6">
                <label
                  class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
                  for="editMemberRole"
                >
                  Role
                </label>
                <Listbox v-model="selectedUserRole">
                  <div class="relative mt-1">
                    <ListboxButton
                      class="relative w-full rounded-[4px] bg-white py-4 pl-4 pr-10 text-left focus:outline-[#D1D2D2] focus:border-[#D1D2D2] hover:border-[#D1D2D2] focus:ring-0 text-sm border-[#D1D2D2] border-[1px] cursor-pointer"
                    >
                      <span class="block truncate">{{ selectedUserRole }}</span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition duration-100 ease-in"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions
                        class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                      >
                        <ListboxOption
                          v-slot="{ active, selected }"
                          v-for="role in userRoles"
                          :key="role.name"
                          :value="role"
                          as="template"
                        >
                          <li
                            :class="[
                              active ? 'bg-black text-white font-light' : 'text-greyText',
                              'relative cursor-pointer select-none py-2 pl-10 pr-4 font-normal',
                            ]"
                          >
                            <span
                              :class="[
                                selected ? 'font-medium' : 'font-normal',
                                'block truncate',
                              ]"
                              >{{ role }}</span
                            >
                            <!-- <span
                              v-if="selected"
                              class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                            >
                            </span> -->
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
              </div>
            </div>
            <div class="flex items-center md:justify-end justify-center w-full">
              <button
                @click="editUser(allUsers[selectedTeamMemberIdx].id)"
                class="font-Rubik mt-6 border-b border-teal font-medium py-[12px] md:px-[20px] px-[32px] text-white text-[13px] leading-[15.41px] rounded-[6px] bg-black md:max-w-[65px] max-w-[100%] w-[50%]"
              >
                Save
              </button>
            </div>
          </div>
        </template>
      </div>
    </Modal>

    <!-- Removing a member -->
    <Modal :modalActive="isRemovingTeamMember === true">
      <div class="bg-white w-[399px] rounded-[6px] p-6 relative">
        <span class="block w-[48px] h-[48px] mb-[12px]">
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEF0C7" />
            <path
              d="M27.9988 24V28M27.9988 32H28.0088M26.2888 18.86L17.8188 33C17.6442 33.3024 17.5518 33.6453 17.5508 33.9945C17.5498 34.3437 17.6403 34.6871 17.8132 34.9905C17.9862 35.2939 18.2355 35.5467 18.5365 35.7238C18.8375 35.9009 19.1796 35.9961 19.5288 36H36.4688C36.818 35.9961 37.1601 35.9009 37.4611 35.7238C37.7621 35.5467 38.0114 35.2939 38.1844 34.9905C38.3573 34.6871 38.4478 34.3437 38.4468 33.9945C38.4458 33.6453 38.3534 33.3024 38.1788 33L29.7088 18.86C29.5305 18.5661 29.2795 18.3231 28.98 18.1544C28.6805 17.9858 28.3425 17.8972 27.9988 17.8972C27.6551 17.8972 27.3171 17.9858 27.0176 18.1544C26.7181 18.3231 26.4671 18.5661 26.2888 18.86Z"
              stroke="#DC6803"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="4"
              y="4"
              width="48"
              height="48"
              rx="24"
              stroke="#FFFAEB"
              stroke-width="8"
            />
          </svg>
        </span>
        <div class="flex flex-col justify-center">
          <h3
            class="font-medium font-Rubik text-[18px] leading-[24px] text-black mb-[12px] text-left md:w-[auto] w-full"
          >
            Removing a team member?
          </h3>
          <p
            class="font-Rubik text-[13px] leading-[20px] text-greyText max-w-[95%] text-left"
          >
            You are about to remove a member of your team. <br /><br /> Do you wish to proceed?
          </p>
          <div class="flex items-center justify-center w-full">
            <button
              @click="closeModal()"
              class="font-Rubik mt-6 border-[0.3px] border-greyText font-medium py-[12px] md:px-[20px] px-[32px] text-black text-[13px] leading-[15.41px] rounded-[6px] bg-white w-full mr-2"
            >
              Dismiss
            </button>
            <button
              @click="removeUser(selectedTeamMemberIdx)"
              class="font-Rubik mt-6 border-[0.3px] border-[transparent] font-medium py-[12px] md:px-[20px] px-[32px] text-white text-[13px] leading-[15.41px] rounded-[6px] bg-[#F45252] w-full"
            >
              Yes, Remove
            </button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem, ListboxButton, Listbox, ListboxOptions, ListboxOption } from '@headlessui/vue'
import { reactive, ref } from 'vue'
import Modal from '../../components/modal/Modal.vue'
import TeamService from '../../services/team.service'
import Helpers from '../../utils/helpers'
import { useRouter } from 'vue-router'
import store from '@/store'

export default {
  name: 'Team Settings',
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    Modal,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    Listbox
  },
  setup () {
    const router = useRouter()
    const isCreatingTeamMember = ref(false)
    const isEditingTeamMember = ref(false)
    const isRemovingTeamMember = ref(false)
    const selectedTeamMemberIdx = ref(null)
    const userRoles = ref([
      '-- Select role --', 'Founder', 'CEO', 'CTO', 'CFO', 'Engineer/Developer',
      'Designer', 'Accountant', 'Lawyer', 'Others'
    ])
    const selectedUserRole = ref(userRoles.value[0])
    const allUsers = ref(null)
    const loading = ref(false)

    const initialNewUserData = reactive({
      first_name: '',
      last_name: '',
      email: '',
      role: ''
    })

    const editUserData = reactive({
      first_name: '',
      last_name: '',
      role: '',
      status: ''
    })

    const fetchTeamData = async () => {
      loading.value = true
      try {
        const response = await TeamService.fetchCompanyUsers()
        if (response.status === 200 && response.data.status === 'success') {
          loading.value = false
          allUsers.value = response.data.data
        }
      } catch (e) {
        loading.value = false
        Helpers.processError(e, store, router)
      }
    }
    fetchTeamData()

    // const resetUserForm = () => {
    //   for (const [key, value] of Object.entries(initialNewUserData)) {
    //     allUsers[key] = value
    //     console.log(allUsers[key])
    //   }
    // }

    // const setUserForm = (payload) => {
    //   for (const key of Object.keys(initialNewUserData)) {
    //     allUsers[key] = payload[key]
    //     // console.log(allUsers[key])
    //   }
    // }

    const closeModal = () => {
      isCreatingTeamMember.value = false
      isEditingTeamMember.value = false
      isRemovingTeamMember.value = false
    }

    const saveNewUser = async () => {
      const payload = {
        first_name: initialNewUserData.first_name,
        last_name: initialNewUserData.last_name,
        email: initialNewUserData.email,
        role: selectedUserRole.value
      }
      try {
        const response = await TeamService.createNewUser(payload)
        if (response.status === 200 && response.data.status === 'success') {
          loading.value = true
          const success = {
            status: true,
            message: 'New member has been added successfully.'
          }
          store.dispatch('setAlert', success)
          setTimeout(() => {
            store.dispatch('setAlert', {
              status: false,
              message: null,
              color: null,
              type: null
            })
          }, 2000)
          fetchTeamData()
        }
      } catch (e) {
        loading.value = false
        Helpers.processError(e, store, router)
      }
      closeModal()
    }
    const openEditUserModal = (index) => {
      selectedTeamMemberIdx.value = index
      isEditingTeamMember.value = true
      editUserData.first_name = allUsers.value[index].first_name
      editUserData.last_name = allUsers.value[index].last_name
      editUserData.role = allUsers.value[index].role
      editUserData.status = allUsers.value[index].status
    }
    const editUser = async (userId) => {
      loading.value = true
      const payload = {
        first_name: editUserData.first_name,
        last_name: editUserData.last_name,
        role: selectedUserRole.value,
        status: editUserData.status
      }
      // console.log(payload)
      try {
        const response = await TeamService.updateCreatedUser(userId, payload)
        if (response.status === 200 && response.data.status === 'success') {
          loading.value = false
          const success = {
            status: true,
            message: 'Member updated successfully'
          }
          store.dispatch('setAlert', success)
          setTimeout(() => {
            store.dispatch('setAlert', {
              status: false,
              message: null,
              color: null,
              type: null
            })
          }, 2000)

          fetchTeamData()
        }
      } catch (e) {
        loading.value = false
        Helpers.processError(e, store, router)
      }
      closeModal()
    }
    const removeUser = async (index) => {
      loading.value = true
      const userId = allUsers.value[index].id
      try {
        const response = await TeamService.deleteCreatedUser(userId)
        if (response.status === 200 && response.data.status === 'success') {
          loading.value = false
          const success = {
            status: true,
            message: 'User has been removed successfully.'
          }
          store.dispatch('setAlert', success)
          setTimeout(() => {
            store.dispatch('setAlert', {
              status: false,
              message: null,
              color: null,
              type: null
            })
          }, 2000)
          fetchTeamData()
        }
      } catch (e) {
        loading.value = false
        Helpers.processError(e, store, router)
      }
      closeModal()
    }

    const formatDate = (d) => {
      if (d) {
        const date = new Date(d.split('T')[0])
        return new Intl.DateTimeFormat('en-GB', {
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        }).format(date)
      }
    }
    return {
      isCreatingTeamMember,
      isEditingTeamMember,
      isRemovingTeamMember,
      selectedTeamMemberIdx,
      initialNewUserData,
      saveNewUser,
      openEditUserModal,
      removeUser,
      closeModal,
      fetchTeamData,
      allUsers,
      userRoles,
      selectedUserRole,
      editUser,
      editUserData,
      loading,
      formatDate
    }
  }
}
</script>

<style scoped>
table tr {
  display: table-row;
}
/* table tr:not(:first-of-type) {
  border-bottom: solid 1px #E5E6E6;
} */
table tr {
  border-bottom: solid 1px #e5e6e6;
}
table td {
  padding-right: 12px;
  white-space: no-wrap;
}
</style>
