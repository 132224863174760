import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class FinancialService {
  static getConnectedAccounts () {
    return axios.get(`${url}/app/financials/accounts`)
  }
}

export default FinancialService
