<template>
  <div v-if="!isEditing" id="account-settings" class="my-12 relative">
    <!-- Heading -->
    <div class="absolute right-0 top-[-5%]">
      <button
        disabled
        class="flex items-center justify-between rounded-[4px] bg-white border-[0.6px] border-greyText py-[8px] px-[10px] font-[500] text-[13px] leading-[15.41px]"
        @click="goToEditProfile()"
      >
        <span class="w-3 h-3 mr-[7px]">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9335 2.89324L9.10686 1.06657C8.86846 0.842636 8.55606 0.714144 8.22908 0.70554C7.90211 0.696935 7.58338 0.808818 7.33353 1.01991L1.33353 7.01991C1.11804 7.23722 0.983867 7.52204 0.953531 7.82657L0.666864 10.6066C0.657884 10.7042 0.670554 10.8026 0.703972 10.8948C0.73739 10.987 0.790733 11.0707 0.860198 11.1399C0.922491 11.2017 0.996369 11.2506 1.07759 11.2838C1.15882 11.3169 1.24579 11.3337 1.33353 11.3332H1.39353L4.17353 11.0799C4.47806 11.0496 4.76289 10.9154 4.9802 10.6999L10.9802 4.69991C11.2131 4.45388 11.3389 4.12558 11.3302 3.78693C11.3214 3.44828 11.1788 3.12691 10.9335 2.89324ZM8.66686 5.11991L6.8802 3.33324L8.1802 1.99991L10.0002 3.81991L8.66686 5.11991Z"
              fill="black"
            />
          </svg>
        </span>
        Edit Details
      </button>
    </div>

    <!-- Tabs -->
    <div class="flex flex-col w-full mx-auto rounded-lg bg-white px-2 sm:px-0">
      <!-- User details content -->
      <div
        :class="[
          'bg-white p-0 mt-8',
          'ring-white ring-opacity-60 ring-offset-2 ring-offset-[transparent] focus:outline-none focus:ring-2',
        ]"
      >
        <div class="flex items-center mb-12">
          <span class="mr-[24px]">
            <img
              src="../../assets/L-company.webp"
              class="w-[100px]"
              alt="user-image"
              width="100px"
              height="100px"
            />
          </span>
          <div class="">
            <h2 class="font-semibold text-[24px] leading-[32px] text-black">
              Company XYZ
            </h2>
            <p class="font-normal text-greyText text-[15px] leading-[24px] mb-2">
              Joined January 2022
            </p>
            <span
              class="w-[max-content] border-[0.6px] border-[#1FC16D] py-[2px] px-[8px] rounded-[100px] font-normal text-[12px] text-[#1FC16D] leading-[20px] flex items-center justify-center"
              >Active</span
            >
          </div>
        </div>

        <div class="max-w-[320px]">
          <div class="relative w-full mb-6">
            <label
              class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
              for="firstName"
            >
              First Name
            </label>
            <input
              id="firstName"
              name="firstName"
              type="text"
              readonly
              class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border-none rounded-[4px] bg-faintGrey w-full focus:outline-none focus:border-none hover:border-none focus:ring-0"
              placeholder="Enter Full Name"
              style="transition: 'all .15s ease'"
              v-model="userDetailsData.first_name"
            />

            <!-- <div v-if="v$.motivation.$error">
                    <p
                      v-if="v$.motivation.required.$invalid"
                      class="text-red-400 text-[10px] font-light"
                    >
                      Motivation is required
                    </p>
                    <p
                      v-if="v$.motivation.minLengthValue.$invalid"
                      class="text-red-400 text-[10px] font-light"
                    >
                      Motivation should be at least 3 characters long
                    </p>
                  </div> -->
          </div>
          <div class="relative w-full mb-6">
            <label
              class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
              for="lastName"
            >
              Last Name
            </label>
            <input
              id="lastName"
              name="lastName"
              type="text"
              readonly
              class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border-none rounded-[4px] bg-faintGrey w-full focus:outline-none focus:border-none hover:border-none focus:ring-0"
              placeholder="Enter Last Name"
              style="transition: 'all .15s ease'"
              v-model="userDetailsData.last_name"
            />

            <!-- <div v-if="v$.motivation.$error">
                    <p
                      v-if="v$.motivation.required.$invalid"
                      class="text-red-400 text-[10px] font-light"
                    >
                      Motivation is required
                    </p>
                    <p
                      v-if="v$.motivation.minLengthValue.$invalid"
                      class="text-red-400 text-[10px] font-light"
                    >
                      Motivation should be at least 3 characters long
                    </p>
                  </div> -->
          </div>
          <div class="relative w-full mb-6">
            <label
              class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
              for="account_email"
            >
              Email
            </label>
            <input
              id="account_email"
              name="account_email"
              type="text"
              readonly
              class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border-none rounded-[4px] bg-faintGrey w-full focus:outline-none focus:border-none hover:border-none focus:ring-0"
              placeholder="Enter Email"
              style="transition: 'all .15s ease'"
              v-model="userDetailsData.email"
            />

            <!-- <div v-if="v$.motivation.$error">
                    <p
                      v-if="v$.motivation.required.$invalid"
                      class="text-red-400 text-[10px] font-light"
                    >
                      Motivation is required
                    </p>
                    <p
                      v-if="v$.motivation.minLengthValue.$invalid"
                      class="text-red-400 text-[10px] font-light"
                    >
                      Motivation should be at least 3 characters long
                    </p>
                  </div> -->
          </div>
          <div class="relative w-full mb-6">
            <label
              class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
              for="account_company_address"
            >
              Company Address
            </label>
            <input
              id="account_company_address"
              name="account_company_address"
              type="text"
              readonly
              class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border-none rounded-[4px] bg-faintGrey w-full focus:outline-none focus:border-none hover:border-none focus:ring-0"
              placeholder="Enter Company Address"
              style="transition: 'all .15s ease'"
              v-model="userDetailsData.company_address"
            />

            <!-- <div v-if="v$.motivation.$error">
                    <p
                      v-if="v$.motivation.required.$invalid"
                      class="text-red-400 text-[10px] font-light"
                    >
                      Motivation is required
                    </p>
                    <p
                      v-if="v$.motivation.minLengthValue.$invalid"
                      class="text-red-400 text-[10px] font-light"
                    >
                      Motivation should be at least 3 characters long
                    </p>
                  </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
  </div>

  <!-- Edit profile screen -->
  <div v-if="isEditing" id="edit-profile" class="my-12">
    <button class="flex justify-between">
      <h1 class="font-Playfair text-[32px] leading-[42.66px]">Edit Details</h1>
    </button>
    <div class="flex flex-start">
      <div
        class="left w-full max-w-[30%] h-screen flex flex-col justify-start py-10 border-r-0 md:border-r-[0.6px] border-faintGrey"
      >
        <div class="profile-image flex flex-col items-center mr-4">
          <span class="block relative w-[120px] h-[120px]">
            <img
              src="../../assets/L-company.webp"
              class="w-full z-[2] object-cover"
              alt="user-image"
              width="120px"
              height="120px"
            />
            <span
              class="z-[3] absolute bottom-0 right-0 w-[40px] h-[40px] flex items-center justify-center rounded-full bg-white"
            >
              <svg
                width="21"
                height="16"
                viewBox="0 0 21 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.29094 0.292405C7.47848 0.104869 7.73283 -0.000488281 7.99805 -0.000488281H13.998C14.2633 -0.000488281 14.5176 0.104869 14.7052 0.292405L16.4123 1.99951H18.998C20.1026 1.99951 20.998 2.89494 20.998 3.99951V13.9995C20.998 15.1041 20.1026 15.9995 18.998 15.9995H2.99805C1.89348 15.9995 0.998047 15.1041 0.998047 13.9995V3.99951C0.998047 2.89494 1.89348 1.99951 2.99805 1.99951H5.58383L7.29094 0.292405ZM8.41226 1.99951L6.70515 3.70662C6.51762 3.89415 6.26326 3.99951 5.99805 3.99951L2.99805 3.99951V13.9995H18.998V3.99951H15.998C15.7328 3.99951 15.4785 3.89415 15.2909 3.70662L13.5838 1.99951H8.41226ZM10.998 6.49951C9.89348 6.49951 8.99805 7.39494 8.99805 8.49951C8.99805 9.60408 9.89348 10.4995 10.998 10.4995C12.1026 10.4995 12.998 9.60408 12.998 8.49951C12.998 7.39494 12.1026 6.49951 10.998 6.49951ZM6.99805 8.49951C6.99805 6.29037 8.78891 4.49951 10.998 4.49951C13.2072 4.49951 14.998 6.29037 14.998 8.49951C14.998 10.7087 13.2072 12.4995 10.998 12.4995C8.78891 12.4995 6.99805 10.7087 6.99805 8.49951Z"
                  fill="#3D3D3D"
                />
              </svg>
            </span>
          </span>
          <p class="mt-[24px] text-[13px] leading-[20px] text-center text-greyText">
            Click image to upload new avatar
          </p>
        </div>
      </div>
      <div class="right pl-8 max-w-[70%] w-full">
        <div class="flex items-start flex-col lg:flex-row">
          <h3 class="font-medium text-[17px] leading-[28px] max-w-[216px] w-full lg:mb-0 mb-4">
            Personal Details
          </h3>
          <div class="flex flex-col w-full">
            <div class="relative w-full mb-6">
              <label
                class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
                for="firstName"
              >
                First Name
              </label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                readonly
                class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border-[#D1D2D2] rounded-[4px] bg-white w-full focus:outline-[#D1D2D2] focus:border-[#D1D2D2] hover:border-[#D1D2D2] focus:ring-0"
                placeholder="Enter Full Name"
                style="transition: 'all .15s ease'"
                v-model="userDetailsData.first_name"
              />
              <!-- <div v-if="v$.motivation.$error">
                  <p
                    v-if="v$.motivation.required.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >
                    Motivation is required
                  </p>
                  <p
                    v-if="v$.motivation.minLengthValue.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >
                    Motivation should be at least 3 characters long
                  </p>
                </div> -->
            </div>
            <div class="relative w-full mb-6">
              <label
                class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
                for="lastName"
              >
                Last Name
              </label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                readonly
                class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border-[#D1D2D2] rounded-[4px] bg-white w-full focus:outline-[#D1D2D2] focus:border-[#D1D2D2] hover:border-[#D1D2D2] focus:ring-0"
                placeholder="Enter Last Name"
                style="transition: 'all .15s ease'"
                v-model="userDetailsData.last_name"
              />
              <!-- <div v-if="v$.motivation.$error">
                  <p
                    v-if="v$.motivation.required.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >
                    Motivation is required
                  </p>
                  <p
                    v-if="v$.motivation.minLengthValue.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >
                    Motivation should be at least 3 characters long
                  </p>
                </div> -->
            </div>
            <div class="relative w-full mb-6">
              <label
                class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
                for="account_email"
              >
                Email
              </label>
              <input
                id="account_email"
                name="account_email"
                type="text"
                readonly
                class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border-[#D1D2D2] rounded-[4px] bg-white w-full focus:outline-[#D1D2D2] focus:border-[#D1D2D2] hover:border-[#D1D2D2] focus:ring-0"
                placeholder="Enter Email"
                style="transition: 'all .15s ease'"
                v-model="userDetailsData.email"
              />
              <!-- <div v-if="v$.motivation.$error">
                  <p
                    v-if="v$.motivation.required.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >
                    Motivation is required
                  </p>
                  <p
                    v-if="v$.motivation.minLengthValue.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >
                    Motivation should be at least 3 characters long
                  </p>
                </div> -->
            </div>
            <div class="relative w-full mb-6">
              <label
                class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
                for="account_company_address"
              >
                Company Address
              </label>
              <input
                id="account_company_address"
                name="account_company_address"
                type="text"
                readonly
                class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border-[#D1D2D2] rounded-[4px] bg-white w-full focus:outline-[#D1D2D2] focus:border-[#D1D2D2] hover:border-[#D1D2D2] focus:ring-0"
                placeholder="Enter Company Address"
                style="transition: 'all .15s ease'"
                v-model="userDetailsData.company_address"
              />
              <!-- <div v-if="v$.motivation.$error">
                  <p
                    v-if="v$.motivation.required.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >
                    Motivation is required
                  </p>
                  <p
                    v-if="v$.motivation.minLengthValue.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >
                    Motivation should be at least 3 characters long
                  </p>
                </div> -->
            </div>
            <div class="relative w-full flex items-center justify-end">
              <button
                disabled
                class="flex items-center justify-center bg-black text-white rounded-[6px] md:text-[13px] text-[13px] md:leading-[15px] leading-[15px] font-Rubik px-[20px] py-[12px] md:max-w-[71px] w-full outline-none focus:outline-none disabled:opacity-50"
                @click="saveDetails()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <hr class="mt-[40px] mb-[24px]" />
        <div class="flex items-start flex-col lg:flex-row">
          <h3 class="font-medium text-[17px] leading-[28px] max-w-[216px] w-full lg:mb-0 mb-4">
            Change Password
          </h3>
          <div class="flex flex-col w-full">
            <div class="relative w-full mb-6">
              <label
                class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
                for="firstName"
              >
                Old Password
              </label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                readonly
                class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border-[#D1D2D2] rounded-[4px] bg-white w-full focus:outline-[#D1D2D2] focus:border-[#D1D2D2] hover:border-[#D1D2D2] focus:ring-0"
                placeholder="Old password"
                style="transition: 'all .15s ease'"
              />
              <!-- <div v-if="v$.motivation.$error">
                  <p
                    v-if="v$.motivation.required.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >
                    Motivation is required
                  </p>
                  <p
                    v-if="v$.motivation.minLengthValue.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >
                    Motivation should be at least 3 characters long
                  </p>
                </div> -->
            </div>
            <div class="relative w-full mb-6">
              <label
                class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
                for="lastName"
              >
                New password
              </label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                readonly
                class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border-[#D1D2D2] rounded-[4px] bg-white w-full focus:outline-[#D1D2D2] focus:border-[#D1D2D2] hover:border-[#D1D2D2] focus:ring-0"
                placeholder="New password"
                style="transition: 'all .15s ease'"
                />
                <!-- <div v-if="v$.motivation.$error">
                  <p
                    v-if="v$.motivation.required.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >
                    Motivation is required
                  </p>
                  <p
                    v-if="v$.motivation.minLengthValue.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >
                    Motivation should be at least 3 characters long
                  </p>
                </div> -->
            </div>
            <div class="relative w-full mb-6">
              <label
                class="block font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal md:w-full w-[99%]"
                for="account_email"
              >
                Confirm new password
              </label>
              <input
                id="account_email"
                name="account_email"
                type="text"
                readonly
                class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border-[#D1D2D2] rounded-[4px] bg-white w-full focus:outline-[#D1D2D2] focus:border-[#D1D2D2] hover:border-[#D1D2D2] focus:ring-0"
                placeholder="Confirm new password"
                style="transition: 'all .15s ease'"
              />
              <!-- <div v-if="v$.motivation.$error">
                <p
                  v-if="v$.motivation.required.$invalid"
                  class="text-red-400 text-[10px] font-light"
                >
                  Motivation is required
                </p>
                <p
                  v-if="v$.motivation.minLengthValue.$invalid"
                  class="text-red-400 text-[10px] font-light"
                >
                  Motivation should be at least 3 characters long
                </p>
              </div> -->
            </div>
            <div class="relative w-full flex items-center justify-end">
              <button
                disabled
                class="flex items-center justify-center bg-black text-white rounded-[6px] md:text-[13px] text-[13px] md:leading-[15px] leading-[15px] font-Rubik px-[20px] py-[12px] md:max-w-[71px] w-full outline-none focus:outline-none disabled:opacity-50"
                @click="updatePassword()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import store from '@/store'

// import TeamService from '../../services/team.service'

export default {
  name: 'User Details',
  components: {},
  setup () {
    const isEditing = ref(false)
    const loggedInUser = store.state.auth.user
    const userDetailsData = reactive({
      first_name: loggedInUser.first_name,
      last_name: loggedInUser.last_name,
      email: loggedInUser.email,
      company_address: ''
    })

    const goToEditProfile = () => {
      isEditing.value = true
    }

    return {
      // user: computed(() => store.state.auth.user),
      isEditing,
      userDetailsData,
      goToEditProfile
    }
  }
}
</script>

<style scoped></style>
