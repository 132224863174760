<template>
  <div class="flex h-screen">
    <a :href="`https://api.whatsapp.com/send?phone=19179244354&text=Hello%20Kinfolk%20team`" target="_blank">
      <div class="fixed bottom-4 right-4 z-[500]">
        <svg class="h-16 w-16" fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="100px" height="100px"><path d="M 25 2 C 12.318 2 2 12.318 2 25 C 2 28.96 3.0228906 32.853062 4.9628906 36.289062 L 2.0371094 46.730469 C 1.9411094 47.073469 2.03325 47.440312 2.28125 47.695312 C 2.47225 47.892313 2.733 48 3 48 C 3.08 48 3.1612344 47.989703 3.2402344 47.970703 L 14.136719 45.271484 C 17.463719 47.057484 21.21 48 25 48 C 37.682 48 48 37.682 48 25 C 48 12.318 37.682 2 25 2 z M 16.642578 14 C 17.036578 14 17.428437 14.005484 17.773438 14.021484 C 18.136437 14.039484 18.624516 13.883484 19.103516 15.021484 C 19.595516 16.189484 20.775875 19.058563 20.921875 19.351562 C 21.069875 19.643563 21.168656 19.984047 20.972656 20.373047 C 20.776656 20.762047 20.678813 21.006656 20.382812 21.347656 C 20.086813 21.688656 19.762094 22.107141 19.496094 22.369141 C 19.200094 22.660141 18.892328 22.974594 19.236328 23.558594 C 19.580328 24.142594 20.765484 26.051656 22.521484 27.597656 C 24.776484 29.583656 26.679531 30.200188 27.269531 30.492188 C 27.859531 30.784188 28.204828 30.734703 28.548828 30.345703 C 28.892828 29.955703 30.024969 28.643547 30.417969 28.060547 C 30.810969 27.477547 31.204094 27.572578 31.746094 27.767578 C 32.288094 27.961578 35.19125 29.372062 35.78125 29.664062 C 36.37125 29.956063 36.766062 30.102703 36.914062 30.345703 C 37.062062 30.587703 37.062312 31.754234 36.570312 33.115234 C 36.078313 34.477234 33.717984 35.721672 32.583984 35.888672 C 31.565984 36.037672 30.277281 36.10025 28.863281 35.65625 C 28.006281 35.38625 26.907047 35.028734 25.498047 34.427734 C 19.575047 31.901734 15.706156 26.012047 15.410156 25.623047 C 15.115156 25.234047 13 22.46275 13 19.59375 C 13 16.72475 14.524406 15.314469 15.066406 14.730469 C 15.608406 14.146469 16.248578 14 16.642578 14 z"/></svg>
      </div>
    </a>
    <div :class="sidebarOpen ? 'block' : 'hidden'" class="fixed inset-0 bg-black opacity-50 md:hidden z-[2000]" @click="sidebarOpen = false"></div>
    <div :class="sidebarOpen ? 'translate-x-0 ease-out transition duration-300' : '-translate-x-full ease-in transition duration-300' " class=" fixed inset-y-0 left-0 z-[2000] md:static md:translate-x-0 h-full">
      <div class="flex flex-row h-[inherit]">
        <div class="flex flex-col justify-between w-[84px] h-full bg-black relative">
          <div class="flex flex-col items-center justify-center h-[max-content]">
            <div class="flex items-center justify-center w-full h-[136px] border-b-[0.4px] border-faintGrey">
              <img src="../../assets/KFLogo.svg" class=" h-8 w-8" />
            </div>
            <!-- <h1 class="text-sm text-white font-Playfair font-normal mt-3">Kinfolk Venture Capital</h1> -->
            <nav class="mt-8">
              <ul>
                <li>
                  <router-link to="/dashboard" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-[13px] px-[16px] w-[max-content] flex justify-center items-center mx-auto rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-black bg-white'
                          : 'text-faintGrey bg-black',
                      ]"
                    >
                      <svg
                        class="h-6 w-6"
                        :class="[
                        isActive
                          ? 'text-black bg-white'
                          : 'text-faintGrey bg-black',
                        ]"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4 6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6zM4 16a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2z"/>
                      </svg>
                      <!-- <span class="">Dashboard</span> -->
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/diligence" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-[13px] px-[16px] w-[max-content] flex justify-center items-center mx-auto mt-[6px] rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-black bg-white'
                          : 'text-faintGrey bg-black',
                      ]"
                    >
                      <svg
                        class="h-6 w-6"
                        :class="[
                        isActive
                          ? 'text-black bg-white'
                          : 'text-faintGrey bg-black',
                        ]"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5.586a1 1 0 0 1 .707.293l5.414 5.414a1 1 0 0 1 .293.707V19a2 2 0 0 1-2 2z"/>
                      </svg>
                      <!-- <span class="">Diligence Process</span> -->
                    </a>
                  </router-link>
                </li>
                <!-- <li>
                  <router-link to="/impact-assessment" v-slot="{ isActive }">
                    <a
                      @click="sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-[13px] px-[16px] w-[max-content] flex justify-center items-center mx-auto mt-[6px] rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-black bg-white'
                          : 'text-faintGrey bg-black',
                      ]"
                    >
                      <svg class="h-6 w-6"
                        :class="[
                        isActive
                          ? 'text-black bg-white'
                          : 'text-faintGrey bg-black',
                        ]" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle r="9" transform="matrix(1 0 0 -1 12 12)" stroke="currentColor" stroke-width="6" stroke-dasharray="3.5 3.5"/>
                      </svg>
                    </a>
                  </router-link>
                </li> -->
                <li>
                  <router-link to="/financial-insights" v-slot="{ isActive }">
                    <a
                      @click="sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-[13px] px-[16px] w-[max-content] flex justify-center items-center mx-auto mt-[6px] rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-black bg-white'
                          : 'text-faintGrey bg-black',
                      ]"
                    >
                      <svg class="h-6 w-6"
                        :class="[
                        isActive
                          ? 'text-black bg-white'
                          : 'text-faintGrey bg-black',
                        ]" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3049_10285)">
                        <path d="M4.75 20.75C4.8163 20.75 4.87989 20.7237 4.92678 20.6768C4.97366 20.6299 5 20.5663 5 20.5V18.5C5 18.2348 4.89464 17.9804 4.70711 17.7929C4.51957 17.6054 4.26522 17.5 4 17.5H2C1.73478 17.5 1.48043 17.6054 1.29289 17.7929C1.10536 17.9804 1 18.2348 1 18.5V20.5C1 20.5663 1.02634 20.6299 1.07322 20.6768C1.12011 20.7237 1.1837 20.75 1.25 20.75H4.75Z" fill="currentColor"/>
                        <path d="M10.75 20.75C10.8163 20.75 10.8799 20.7237 10.9268 20.6768C10.9737 20.6299 11 20.5663 11 20.5V13.5C11 13.2348 10.8946 12.9804 10.7071 12.7929C10.5196 12.6054 10.2652 12.5 10 12.5H8C7.73478 12.5 7.48043 12.6054 7.29289 12.7929C7.10536 12.9804 7 13.2348 7 13.5V20.5C7 20.5663 7.02634 20.6299 7.07322 20.6768C7.12011 20.7237 7.1837 20.75 7.25 20.75H10.75Z" fill="currentColor"/>
                        <path d="M16.75 20.75C16.8163 20.75 16.8799 20.7237 16.9268 20.6768C16.9737 20.6299 17 20.5663 17 20.5V15.5C17 15.2348 16.8946 14.9804 16.7071 14.7929C16.5196 14.6054 16.2652 14.5 16 14.5H14C13.7348 14.5 13.4804 14.6054 13.2929 14.7929C13.1054 14.9804 13 15.2348 13 15.5V20.5C13 20.5663 13.0263 20.6299 13.0732 20.6768C13.1201 20.7237 13.1837 20.75 13.25 20.75H16.75Z" fill="currentColor"/>
                        <path d="M22.75 20.75C22.8163 20.75 22.8799 20.7237 22.9268 20.6768C22.9737 20.6299 23 20.5663 23 20.5V8.5C23 8.23478 22.8946 7.98043 22.7071 7.79289C22.5196 7.60536 22.2652 7.5 22 7.5H20C19.7348 7.5 19.4804 7.60536 19.2929 7.79289C19.1054 7.98043 19 8.23478 19 8.5V20.5C19 20.5663 19.0263 20.6299 19.0732 20.6768C19.1201 20.7237 19.1837 20.75 19.25 20.75H22.75Z" fill="currentColor"/>
                        <path d="M3.5 13.4996C4.03043 13.4996 4.53914 13.2889 4.91421 12.9138C5.28929 12.5388 5.5 12.0301 5.5 11.4996C5.49775 11.2957 5.46402 11.0933 5.4 10.8996L8.567 8.25963C8.96446 8.47848 9.42567 8.55226 9.87157 8.46834C10.3175 8.38441 10.7203 8.148 11.011 7.79963L13.511 8.63363C13.5615 9.13865 13.8022 9.60557 14.1842 9.93976C14.5662 10.2739 15.0609 10.4504 15.5682 10.4334C16.0755 10.4163 16.5573 10.2071 16.916 9.84799C17.2747 9.48892 17.4835 9.00691 17.5 8.49963C17.498 8.18141 17.4187 7.86842 17.269 7.58763L20.556 3.75263C20.941 3.95789 21.3828 4.03118 21.8135 3.96126C22.2442 3.89134 22.6401 3.68207 22.9404 3.36555C23.2408 3.04903 23.429 2.64273 23.4763 2.20895C23.5235 1.77518 23.4272 1.33788 23.202 0.964112C22.9769 0.590347 22.6353 0.300755 22.2298 0.139744C21.8242 -0.0212676 21.3771 -0.044808 20.9569 0.0727325C20.5366 0.190273 20.1666 0.442405 19.9034 0.790463C19.6403 1.13852 19.4986 1.56329 19.5 1.99963C19.5026 2.19351 19.534 2.38594 19.593 2.57063L16.13 6.61163C15.7555 6.47816 15.3489 6.46349 14.9657 6.56961C14.5826 6.67574 14.2415 6.8975 13.989 7.20463L11.489 6.37163C11.4733 6.08831 11.3973 5.81161 11.2661 5.55999C11.1349 5.30838 10.9516 5.08764 10.7283 4.91252C10.505 4.73739 10.247 4.61191 9.97136 4.54445C9.69574 4.47699 9.4089 4.4691 9.12999 4.52132C8.85108 4.57353 8.58652 4.68463 8.35396 4.84722C8.12141 5.00981 7.92621 5.22014 7.7814 5.46416C7.6366 5.70819 7.54551 5.9803 7.51423 6.26232C7.48295 6.54435 7.51219 6.8298 7.6 7.09963L4.436 9.74363C4.14905 9.58597 3.8274 9.50212 3.5 9.49963C2.96957 9.49963 2.46086 9.71034 2.08579 10.0854C1.71071 10.4605 1.5 10.9692 1.5 11.4996C1.5 12.0301 1.71071 12.5388 2.08579 12.9138C2.46086 13.2889 2.96957 13.4996 3.5 13.4996Z" fill="currentColor"/>
                        <path d="M23 22H1C0.73607 22.0041 0.484105 22.1108 0.297455 22.2975C0.110806 22.4841 0.00412466 22.7361 0 23C0 23.2652 0.105357 23.5196 0.292893 23.7071C0.48043 23.8946 0.734784 24 1 24H23C23.2652 24 23.5196 23.8946 23.7071 23.7071C23.8946 23.5196 24 23.2652 24 23C23.9961 22.736 23.8895 22.4839 23.7028 22.2972C23.5161 22.1105 23.264 22.0039 23 22Z" fill="currentColor"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_3049_10285">
                        <rect width="24" height="24" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                      <!-- <span class="">Diligence Process</span> -->
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/profile" v-slot="{ isActive }">
                    <a
                      @click="sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-[13px] px-[16px] w-[max-content] flex justify-center items-center mx-auto mt-[6px] rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-black bg-white'
                          : 'text-faintGrey bg-black',
                      ]"
                    >
                      <svg :class="[
                        'h-6 w-6',
                        isActive
                          ? 'text-black bg-white'
                          : 'text-faintGrey bg-black',
                        ]" width="24" height="24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24">
                          <path fill="currentColor" d="M19.9,12.66a1,1,0,0,1,0-1.32L21.18,9.9a1,1,0,0,0,.12-1.17l-2-3.46a1,1,0,0,0-1.07-.48l-1.88.38a1,1,0,0,1-1.15-.66l-.61-1.83A1,1,0,0,0,13.64,2h-4a1,1,0,0,0-1,.68L8.08,4.51a1,1,0,0,1-1.15.66L5,4.79A1,1,0,0,0,4,5.27L2,8.73A1,1,0,0,0,2.1,9.9l1.27,1.44a1,1,0,0,1,0,1.32L2.1,14.1A1,1,0,0,0,2,15.27l2,3.46a1,1,0,0,0,1.07.48l1.88-.38a1,1,0,0,1,1.15.66l.61,1.83a1,1,0,0,0,1,.68h4a1,1,0,0,0,.95-.68l.61-1.83a1,1,0,0,1,1.15-.66l1.88.38a1,1,0,0,0,1.07-.48l2-3.46a1,1,0,0,0-.12-1.17ZM18.41,14l.8.9-1.28,2.22-1.18-.24a3,3,0,0,0-3.45,2L12.92,20H10.36L10,18.86a3,3,0,0,0-3.45-2l-1.18.24L4.07,14.89l.8-.9a3,3,0,0,0,0-4l-.8-.9L5.35,6.89l1.18.24a3,3,0,0,0,3.45-2L10.36,4h2.56l.38,1.14a3,3,0,0,0,3.45,2l1.18-.24,1.28,2.22-.8.9A3,3,0,0,0,18.41,14ZM11.64,8a4,4,0,1,0,4,4A4,4,0,0,0,11.64,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,11.64,14Z"/>
                      </svg>
                    </a>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
          <div class="flex mb-10 w-full">
            <div
              class="text-sm font-normal font-Rubik py-[13px] px-[16px] w-[max-content] flex justify-center items-center mx-auto rounded-[4px] text-red-600 cursor-pointer"
              @click="confirmLogout = !confirmLogout"
            >
              <svg
                class="h-6 w-6 text-red-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
              <!-- <span class="">Logout</span> -->
            </div>
          </div>
        </div>
        <div class="h-full w-[215px] overflow-y-scroll border-r bg-white">
          <div v-if="route.path.includes('/impact-assessment') && route.path !== '/dashboard' && !(route.path.includes('diligence'))">
            <h1 class="text-base text-left ml-6 font-Rubik font-medium mt-20">Impact Assessment</h1>
            <nav class="mt-8">
              <ul>
                <li>
                  <router-link to="/impact-assessment/sdg-alignment" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-white bg-black'
                          : 'text-black bg-white'
                      ]"
                    >
                      <span class="">SDG Alignment</span>
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/impact-assessment/follow-up-questions" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-white bg-black'
                          : 'text-black bg-white'
                      ]"
                    >
                      <span class="">SDG Follow-Up</span>
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/impact-assessment/business-integrity" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-white bg-black'
                          : 'text-black bg-white'
                      ]"
                    >
                      <span class="">Business Integrity</span>
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/impact-assessment/social-management" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-white bg-black'
                          : 'text-black bg-white'
                      ]"
                    >
                      <span class="">Social Management</span>
                    </a>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
          <div v-if="route.path.includes('profile') && (route.path !== '/dashboard') && !(route.path.includes('diligence'))">
            <h1 class="text-base text-left ml-6 font-Rubik font-medium mt-20">Profile</h1>
            <nav class="mt-8">
              <ul>
                <li>
                  <router-link to="/profile" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-white bg-black'
                          : 'text-black bg-white'
                      ]"
                    >
                      <span class="">Account Settings</span>
                    </a>
                  </router-link>
                </li>
                <!-- <li>
                  <router-link to="/profile/connected-sources" disabled v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-white bg-black hover:text-greyText'
                          : 'text-greyText bg-white hover:text-black'
                      ]"
                    >
                      <span>Connected Sources</span>
                    </a>
                  </router-link>
                </li> -->
              </ul>
            </nav>
          </div>
          <div v-if="route.path.includes('financial-insights')&& (route.path !== '/dashboard') && !(route.path.includes('diligence'))">
            <h1 class="text-base text-left ml-6 font-Rubik font-medium mt-20">Financial Insights</h1>
            <nav class="mt-8">
              <ul>
                <li>
                  <router-link to="/financial-insights/integrations" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-white bg-black'
                          : 'text-black bg-white'
                      ]"
                    >
                      <span class="">Connect your data</span>
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/financial-insights/growth-model" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-white bg-black'
                          : 'text-black bg-white'
                      ]"
                    >
                      <span class="">Growth Calculator</span>
                    </a>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
          <div v-if="route.path === '/dashboard'">
            <h1 class="text-base text-left ml-6 font-Rubik font-medium mt-20">Home</h1>
            <nav class="mt-8">
              <ul>
                <li>
                  <router-link to="" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate, sidebarOpen = false"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="[
                        isActive
                          ? 'text-white bg-black'
                          : 'text-black bg-white'
                      ]"
                    >
                      <span class="">Dashboard</span>
                    </a>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
          <div v-if="route.path.includes('diligence')">
            <h1 class="text-base text-left ml-6 font-Rubik font-medium mt-20">Diligence Process</h1>
            <nav v-if="progress" class="mt-8 mb-20">
              <ul>
                <li>
                  <router-link to="/diligence/onboarding" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="checkState(isActive, progress.onboarding, disabled.onboarding)"
                    >
                      <svg v-if="progress.onboarding === 'Approved'"
                        class="h-4 w-4"
                        :class="checkState(isActive, progress.onboarding)"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                      </svg>
                      <span :class="progress.onboarding ==='Approved' ? 'pl-1': '' ">Onboarding</span>
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/diligence/founder-journey" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 rounded-[4px]"
                      :class="checkState(isActive, progress.stage_one, disabled.stage_one)"
                    >
                      <svg v-if="progress.stage_one === 'Approved'"
                        class="h-4 w-4"
                        :class="checkState(isActive, progress.stage_one)"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                      </svg>
                      <span :class="progress.stage_one === 'Approved' ? 'pl-1': ''">Founder Journey</span>
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/diligence/data-request-one" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="checkState(isActive, progress.stage_two, disabled.stage_two)"
                    >
                      <svg v-if="progress.stage_two === 'Approved'"
                        class="h-4 w-4"
                        :class="checkState(isActive, progress.stage_two)"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                      </svg>
                      <span :class="progress.stage_two === 'Approved' ? 'pl-1': ''">Data Request 1</span>
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/diligence/product-tech" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="checkState(isActive, progress.stage_three, disabled.stage_three)"
                    >
                      <svg v-if="progress.stage_three === 'Approved'"
                        class="h-4 w-4"
                        :class="checkState(isActive, progress.stage_three)"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                      </svg>
                      <span :class="progress.stage_three === 'Approved' ? 'pl-1': ''">Product Tech</span>
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/diligence/data-request-two" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="checkState(isActive, progress.stage_four, disabled.stage_four)"
                    >
                      <svg v-if="progress.stage_four === 'Approved'"
                        class="h-4 w-4"
                        :class="checkState(isActive, progress.stage_four)"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                      </svg>
                      <span :class="progress.stage_four === 'Approved' ? 'pl-1': ''">Data Request 2</span>
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/diligence/unit-economics" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="checkState(isActive, progress.stage_five, disabled.stage_five)"
                    >
                      <svg v-if="progress.stage_five === 'Approved'"
                        class="h-4 w-4"
                        :class="checkState(isActive, progress.stage_five)"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                      </svg>
                      <span :class="progress.stage_five === 'Approved' ? 'pl-1': ''">Unit Economics</span>
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/diligence/data-request-three" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="checkState(isActive, progress.stage_six, disabled.stage_six)"
                    >
                      <svg v-if="progress.stage_six === 'Approved'"
                        class="h-4 w-4"
                        :class="checkState(isActive, progress.stage_six)"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                      </svg>
                      <span :class="progress.stage_six === 'Approved' ? 'pl-1': ''">Data Request 3</span>
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/diligence/fundraising" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="checkState(isActive, progress.stage_seven, disabled.stage_seven)"
                    >
                      <svg v-if="progress.stage_seven === 'Approved'"
                        class="h-4 w-4"
                        :class="checkState(isActive, progress.stage_seven)"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                      </svg>
                      <span :class="progress.stage_seven === 'Approved' ? 'pl-1': ''">Fundraising</span>
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/diligence/data-request-four" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="checkState(isActive, progress.stage_eight, disabled.stage_eight)"
                    >
                      <svg v-if="progress.stage_eight === 'Approved'"
                        class="h-4 w-4"
                        :class="checkState(isActive, progress.stage_eight)"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                      </svg>
                      <span :class="progress.stage_eight === 'Approved' ? 'pl-1': ''">Data Request 4</span>
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/diligence/survey" v-slot="{ href, navigate, isActive }">
                    <a
                      :href="href"
                      @click="navigate"
                      class="text-sm font-normal font-Rubik py-2 flex mx-6 mb-4 pl-4 items-center rounded-[4px]"
                      :class="checkState(isActive, progress.feedback_survey, disabled.feedback_survey)"
                    >
                      <svg v-if="progress.stage_nine === 'Approved'"
                        class="h-4 w-4"
                        :class="checkState(isActive, progress.feedback_survey)"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                      </svg>
                      <span :class="progress.feedback_survey === 'Approved' ? 'pl-1': ''">Survey</span>
                    </a>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="scrolling flex-1 h-screen min-w-0 overflow-y-auto">
      <Modal :modalActive="success.status === true">
        <div class="bg-white w-[399px] rounded-[6px] p-6 relative">
          <span class="block w-[48px] h-[48px] mb-[12px] mx-auto md:mx-0">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 28C4 14.7452 14.7452 4 28 4C41.2548 4 52 14.7452 52 28C52 41.2548 41.2548 52 28 52C14.7452 52 4 41.2548 4 28Z" fill="#D1FADF"/>
              <path d="M38 27.08V28C37.9988 30.1564 37.3005 32.2547 36.0093 33.9818C34.7182 35.709 32.9033 36.9725 30.8354 37.5839C28.7674 38.1953 26.5573 38.1219 24.5345 37.3746C22.5117 36.6273 20.7847 35.2461 19.611 33.4371C18.4373 31.628 17.8798 29.4881 18.0217 27.3363C18.1636 25.1846 18.9972 23.1363 20.3983 21.4971C21.7994 19.8578 23.6928 18.7154 25.7962 18.2401C27.8996 17.7649 30.1003 17.9823 32.07 18.86M38 20L28 30.01L25 27.01" stroke="#039855" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M28 48C16.9543 48 8 39.0457 8 28H0C0 43.464 12.536 56 28 56V48ZM48 28C48 39.0457 39.0457 48 28 48V56C43.464 56 56 43.464 56 28H48ZM28 8C39.0457 8 48 16.9543 48 28H56C56 12.536 43.464 0 28 0V8ZM28 0C12.536 0 0 12.536 0 28H8C8 16.9543 16.9543 8 28 8V0Z" fill="#ECFDF3"/>
            </svg>
          </span>
          <div class="flex flex-col items-start justify-center">
            <h3 class="font-medium font-Rubik text-[18px] leading-[24px] text-black mb-[12px] text-center md:w-[auto] w-full">
              Success!
            </h3>
            <p class="font-Rubik text-[13px] leading-[20px] text-greyText max-w-[95%] md:text-left text-center"> {{success.message}}</p>
            <div class="flex items-center md:justify-end justify-center w-full">
              <button @click="closeModal" class="font-Rubik mt-6 border-b border-teal font-medium py-[12px] md:px-[20px] px-[32px] text-white text-[13px] leading-[15.41px] rounded-[6px] bg-black md:max-w-[100px] max-w-[100%] w-[50%]">Dismiss</button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal :modalActive="bank_modal.status === true">
        <div class="bg-white w-[399px] rounded-[6px] p-6 relative">
          <span class="block w-[48px] h-[48px] mb-[12px] mx-auto">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 28C4 14.7452 14.7452 4 28 4C41.2548 4 52 14.7452 52 28C52 41.2548 41.2548 52 28 52C14.7452 52 4 41.2548 4 28Z" fill="#D1FADF"/>
              <path d="M38 27.08V28C37.9988 30.1564 37.3005 32.2547 36.0093 33.9818C34.7182 35.709 32.9033 36.9725 30.8354 37.5839C28.7674 38.1953 26.5573 38.1219 24.5345 37.3746C22.5117 36.6273 20.7847 35.2461 19.611 33.4371C18.4373 31.628 17.8798 29.4881 18.0217 27.3363C18.1636 25.1846 18.9972 23.1363 20.3983 21.4971C21.7994 19.8578 23.6928 18.7154 25.7962 18.2401C27.8996 17.7649 30.1003 17.9823 32.07 18.86M38 20L28 30.01L25 27.01" stroke="#039855" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M28 48C16.9543 48 8 39.0457 8 28H0C0 43.464 12.536 56 28 56V48ZM48 28C48 39.0457 39.0457 48 28 48V56C43.464 56 56 43.464 56 28H48ZM28 8C39.0457 8 48 16.9543 48 28H56C56 12.536 43.464 0 28 0V8ZM28 0C12.536 0 0 12.536 0 28H8C8 16.9543 16.9543 8 28 8V0Z" fill="#ECFDF3"/>
            </svg>
          </span>
          <div class="flex flex-col justify-center">
            <h3 class="font-medium font-Rubik text-[18px] leading-[24px] text-black mb-[12px] text-center md:w-[auto] w-full">
              Your bank has been added
            </h3>
            <p class="font-Rubik text-[13px] leading-[20px] text-greyText max-w-[95%] text-center"> {{bank_modal.message}}</p>
            <div class="flex items-center justify-center w-full">
              <button @click="closeBankModal" class="font-Rubik mt-6 border-b border-teal font-medium py-[12px] md:px-[20px] px-[32px] text-white text-[13px] leading-[15.41px] rounded-[6px] bg-black md:max-w-[100px] max-w-[100%] w-[50%]">Dismiss</button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal :modalActive="confirmLogout === true">
        <div class="bg-white w-[399px] rounded-[6px] p-6 relative">
          <span class="block w-[48px] h-[48px] mb-[12px] mx-auto">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 28C4 14.7452 14.7452 4 28 4C41.2548 4 52 14.7452 52 28C52 41.2548 41.2548 52 28 52C14.7452 52 4 41.2548 4 28Z" fill="#FEE4E2"/>
              <path d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z" stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M28 48C16.9543 48 8 39.0457 8 28H0C0 43.464 12.536 56 28 56V48ZM48 28C48 39.0457 39.0457 48 28 48V56C43.464 56 56 43.464 56 28H48ZM28 8C39.0457 8 48 16.9543 48 28H56C56 12.536 43.464 0 28 0V8ZM28 0C12.536 0 0 12.536 0 28H8C8 16.9543 16.9543 8 28 8V0Z" fill="#FEF3F2"/>
            </svg>
          </span>
          <div class="flex flex-col items-start justify-center">
            <h3 class="font-medium font-Rubik text-[18px] leading-[24px] text-black mb-[12px] text-center w-full">
              Logout?
            </h3>
            <p class="font-Rubik text-[13px] leading-[20px] text-greyText max-w-[95%] text-center w-full"> Are you sure you want to log out? </p>
            <div class="flex items-center justify-between w-full">
              <button @click="confirmLogout = false" class="font-Rubik mt-6 border-b border-teal font-medium py-[12px] px-[45px] text-[13px] text-black leading-[15.41px] rounded-[6px] mr-[24px] border-[0.6px] border-lineGray max-w-[170px] w-[50%]">Cancel</button>
              <button @click="signout" class="font-Rubik mt-6 border-b border-teal font-medium py-[12px] px-[45px] text-white text-[13px] leading-[15.41px] rounded-[6px] bg-originsRed max-w-[170px] w-[50%]">Logout</button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal :modalActive="disabled_modal.status === true">
        <div class="bg-white w-[399px] rounded-[6px] p-6 relative">
          <span class="block w-[48px] h-[48px] mb-[12px] mx-auto md:mx-0">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 28C4 14.7452 14.7452 4 28 4C41.2548 4 52 14.7452 52 28C52 41.2548 41.2548 52 28 52C14.7452 52 4 41.2548 4 28Z" fill="#FEF0C7"/>
              <path d="M27.9998 24V28M27.9998 32H28.0098M26.2898 18.86L17.8198 33C17.6451 33.3024 17.5527 33.6453 17.5518 33.9945C17.5508 34.3437 17.6413 34.6871 17.8142 34.9905C17.9871 35.2939 18.2365 35.5467 18.5375 35.7238C18.8385 35.9009 19.1806 35.9962 19.5298 36H36.4698C36.819 35.9962 37.1611 35.9009 37.4621 35.7238C37.7631 35.5467 38.0124 35.2939 38.1854 34.9905C38.3583 34.6871 38.4488 34.3437 38.4478 33.9945C38.4468 33.6453 38.3544 33.3024 38.1798 33L29.7098 18.86C29.5315 18.5661 29.2805 18.3231 28.981 18.1545C28.6814 17.9858 28.3435 17.8972 27.9998 17.8972C27.656 17.8972 27.3181 17.9858 27.0186 18.1545C26.7191 18.3231 26.468 18.5661 26.2898 18.86Z" stroke="#DC6803" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M28 48C16.9543 48 8 39.0457 8 28H0C0 43.464 12.536 56 28 56V48ZM48 28C48 39.0457 39.0457 48 28 48V56C43.464 56 56 43.464 56 28H48ZM28 8C39.0457 8 48 16.9543 48 28H56C56 12.536 43.464 0 28 0V8ZM28 0C12.536 0 0 12.536 0 28H8C8 16.9543 16.9543 8 28 8V0Z" fill="#FFFAEB"/>
            </svg>
          </span>
          <div class="flex flex-col items-start justify-center">
            <h3 class="font-medium font-Rubik text-[18px] leading-[24px] text-black mb-[12px] text-center md:w-[auto] w-full">
              Permission denied!
            </h3>
            <p class="font-Rubik text-[13px] leading-[20px] text-greyText max-w-[95%] md:text-left text-center"> {{disabled_modal.message}}</p>
            <div class="flex items-center md:justify-end justify-center w-full">
              <button @click="closeDisabledModal" class="font-Rubik mt-6 border-b border-teal font-medium py-[12px] md:px-[20px] px-[32px] text-white text-[13px] leading-[15.41px] rounded-[6px] bg-black md:max-w-[100px] max-w-[100%] w-[50%]">Dismiss</button>
            </div>
          </div>
        </div>
      </Modal>
      <header class="flex justify-between md:justify-end sticky top-0 z-[1000] bg-white pb-4 px-4 lg:px-[88px]">
        <div class="flex items-center md:hidden pt-10">
          <button class="justify-center items-center rounded-full bg-coolGray-100 h-10 w-10 mr-6" :class="sidebarOpen ? 'hidden' : 'flex'" @click="sidebarOpen = true">
            <svg class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
        <div class="flex items-center pt-10">
          <h1 class="mr-8 font-light">Welcome, <span v-if="user" class="font-medium">{{ user.first_name }}</span></h1>
          <Menu as="div" class="relative inline-block text-left">
            <MenuButton>
              <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20.5" cy="20.5" r="20.5" fill="#F4F5F5"/>
                <g clip-path="url(#clip0_2820_7992)">
                  <path d="M20.4996 13.7C18.6218 13.7 17.0996 15.2222 17.0996 17.1C17.0996 18.9778 18.6218 20.5 20.4996 20.5C22.3774 20.5 23.8996 18.9778 23.8996 17.1C23.8996 15.2222 22.3774 13.7 20.4996 13.7ZM15.3996 17.1C15.3996 14.2833 17.683 12 20.4996 12C23.3163 12 25.5996 14.2833 25.5996 17.1C25.5996 19.9167 23.3163 22.2 20.4996 22.2C17.683 22.2 15.3996 19.9167 15.3996 17.1ZM17.0996 25.6C15.6913 25.6 14.5496 26.7417 14.5496 28.15C14.5496 28.6194 14.1691 29 13.6996 29C13.2302 29 12.8496 28.6194 12.8496 28.15C12.8496 25.8028 14.7524 23.9 17.0996 23.9H23.8996C26.2468 23.9 28.1496 25.8028 28.1496 28.15C28.1496 28.6194 27.7691 29 27.2996 29C26.8302 29 26.4496 28.6194 26.4496 28.15C26.4496 26.7417 25.3079 25.6 23.8996 25.6H17.0996Z" fill="#0D0D0D"/>
                </g>
                <defs>
                  <clipPath id="clip0_2820_7992">
                    <rect width="17" height="17" fill="white" transform="translate(12 12)"/>
                  </clipPath>
                </defs>
              </svg>
            </MenuButton>
            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class="absolute right-0 mt-2 w-[120px] origin-top-right divide-y divide-gray-100 rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[10]"
              >
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                    <button class="w-full py-2 px-2"
                      :class="[
                      active ? 'bg-black text-white' : 'text-gray-900',
                        'group flex text-center w-full items-center rounded-sm text-sm font-normal',
                      ]"
                        >
                      <router-link to="/profile">Profile settings</router-link>
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="isParentUser">
                    <button class="w-full py-2 px-2"
                      :class="[
                      active ? 'bg-black text-white' : 'text-gray-900',
                        'group flex text-center w-full items-center rounded-sm text-sm font-normal',
                      ]"
                        >
                      <router-link to="/profile/team">Team settings</router-link>
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <div
          v-if="alert.status"
          :class="[alert.type === 'error' ? 'bg-red-50 border-red-200': 'bg-green-50 border-green-200']"
          class="absolute rounded-md bg-red-50 p-2 top-10 z-50 border">
          <div class="flex">
            <div
              :class="[alert.type === 'error' ? 'bg-red-600': 'bg-green-600']"
              class="mx-auto flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-full">
              <svg
                v-if="alert.type === 'error'"
                class="h-4 w-4"
                :class="[alert.type === 'error' ? 'text-red-50': 'text-green-50']"
                viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
              <svg v-else
                class="h-4 w-4"
                :class="[alert.type === 'error' ? 'text-red-50': 'text-green-50']"
                viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3 my-auto">
              <p
                :class="[alert.type === 'error' ? 'text-red-800': 'text-green-800']"
              class="text-sm font-medium text-red-800">
                {{ alert.message }}
              </p>
            </div>
          </div>
        </div>
      </header>
      <div class="px-4 lg:px-[88px]">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import Modal from '@/components/modal/Modal.vue'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import jwt_decode from 'jwt-decode'

export default {
  components: {
    Modal,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem

  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      count: 0
    })
    const sidebarOpen = ref(false)
    const confirmLogout = ref(false)
    const isParentUser = ref(null)
    const token = computed(() => store.state.auth.usertoken)

    const signout = () => {
      confirmLogout.value = true
      if (confirmLogout.value === true) {
        console.log('confirm logout?')
        store.dispatch('logoutUser')
        window.localStorage.removeItem('vuex')
        localStorage.removeItem('link_token')
        localStorage.removeItem('app-token')
        // store.dispatch('resetApp', null)
        router.push('/sign-in')
      } else {
        confirmLogout.value = false
      }
    }
    const modalActive = ref(true)
    const closeModal = () => {
      store.commit('setSuccess', {
        status: false,
        message: null
      })
    }
    const closeBankModal = () => {
      store.commit('setBankModal', {
        status: false,
        message: null
      })
    }
    const closeDisabledModal = () => {
      store.commit('setDisabledModal', {
        status: false,
        message: null
      })
    }

    const checkState = (active, progress, disbaled) => {
      if (active === true && (progress === 'Processing' || progress === 'New')) {
        return 'text-white bg-black'
      } else if (active === false && (progress === 'Processing' || progress === 'New') && disbaled === false) {
        return 'text-black bg-white'
      } else if (active === false && (progress === 'Processing' || progress === 'New') && disbaled === true) {
        return 'text-greyText bg-white'
      } else if (active === true && progress === 'Approved') {
        return 'text-originsGreen bg-lightGreen'
      } else if (active === false && progress === 'Approved') {
        return 'text-originsGreen bg-white'
      } else if (active === true && progress === 'Rejected') {
        return 'text-originsRed bg-lightRed'
      } else if (active === false && progress === 'Rejected') {
        return 'text-originsRed bg-white'
      }
    }
    onMounted(() => {
      if (token.value) {
        const user = jwt_decode(token.value)
        isParentUser.value = user.is_parent_user
      }
    })
    return {
      user: computed(() => store.state.auth.user),
      error: computed(() => store.state.app.error),
      success: computed(() => store.state.app.success),
      progress: computed(() => store.state.app.progress_status),
      disabled: computed(() => store.state.app.disabled_status),
      disabled_modal: computed(() => store.state.app.disabled_modal),
      bank_modal: computed(() => store.state.app.bank_modal),
      alert: computed(() => store.state.app.alert),
      logout: computed(() => store.state.app.logout),
      ...toRefs(state),
      sidebarOpen,
      signout,
      closeModal,
      route,
      checkState,
      modalActive,
      confirmLogout,
      closeDisabledModal,
      closeBankModal,
      isParentUser
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
