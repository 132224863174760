<template>
  <main class="founder-journey mt-12">
    <div class="flex justify-between mb-10">
      <h1 class="font-Playfair text-[32px] leading-[42.66px]">SDG Follow-up Questions</h1>
    </div>
    <div class="flex flex-col xl:flex-row justify-between pt-4 text-sm text-gray-500">
      <div class="mr-16 mb-4">
        <!-- <h3 class="font-Rubik text-[18px] leading-[21.33px] font-medium text-black">The Business (Onboarding)</h3> -->
        <p class="font-Rubik text-[16px] leading-[24px] font-light w-[303px] mt-3">
          Kindly provide all the required information to proceed to the next stage.
        </p>
        <!-- <p class="font-Rubik text-[16px] leading-[24px] font-light w-[303px] mt-3">Click on the save draft button to save your progress</p> -->
        <p class="font-Rubik text-[16px] leading-[24px] font-normal w-[303px] mt-2">
          Estimated completion time: <span class="text-black">3mins</span>
        </p>
        <p class="font-Rubik text-[14px] leading-[24px] font-normal text-red-400 mt-2">
          *Required
        </p>
      </div>
      <div class="flex flex-col flex-grow lg:pb-10 pb-8">
        <div class="max-w-[613px]">
          <div class="contain">
            <div>
              <div class="form-outer">
                <div class="forms">
                  <div class="relative w-full mb-6">
                    <label
                      class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                      for="accountability"
                    >
                      <span class="text-red-400">*</span>
                      How does your company intend to stay accountable and maintain
                      oversight of its ESG goals, standards and policies?
                    </label>
                    <textarea
                      id="accountability"
                      name="accountability"
                      type="text"
                      rows="4"
                      maxlength="400"
                      class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                      placeholder=""
                      style="transition: 'all .15s ease'"
                      v-model="data.accountability"
                    />
                    <span
                      class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]"
                      >{{ `${data.accountability.length} of 400` }}</span
                    >

                    <!-- <div>
                          <p
                            v-if="data.accountability.length < 1"
                            class="text-red-400 text-[10px] font-light"
                          >ESG accountability is required</p>
                          <p
                            v-if="data.accountability.length < 3"
                            class="text-red-400 text-[10px] font-light"
                          >ESG accountability should be at least 3 characters long</p>
                        </div> -->
                  </div>
                  <div class="relative w-full mb-6">
                    <label
                      class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                      for="communication"
                    >
                      <span class="text-red-400">*</span>
                      How does your company communicate its ESG goals to employees and
                      other stakeholders?
                    </label>
                    <textarea
                      id="communication"
                      name="communication"
                      type="text"
                      rows="4"
                      maxlength="400"
                      class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                      placeholder=""
                      style="transition: 'all .15s ease'"
                      v-model="data.communication"
                    />
                    <span
                      class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]"
                      >{{ `${data.communication.length} of 400` }}</span
                    >

                    <!-- <div>
                          <p
                            v-if="data.communication.length < 1"
                            class="text-red-400 text-[10px] font-light"
                          >ESG communication is required</p>
                          <p
                            v-if="data.communication.length < 3"
                            class="text-red-400 text-[10px] font-light"
                          >ESG communication should be at least 3 characters long</p>
                        </div> -->
                  </div>
                  <div class="relative w-full mb-6">
                    <label
                      class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                      for="commitments"
                    >
                      <span class="text-red-400">*</span>
                      What public commitments on reporting on ESG progress does your
                      company intend to make?
                    </label>
                    <textarea
                      id="commitments"
                      name="commitments"
                      type="text"
                      rows="4"
                      maxlength="400"
                      class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                      placeholder=""
                      style="transition: 'all .15s ease'"
                      v-model="data.commitments"
                    />
                    <span
                      class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]"
                      >{{ `${data.commitments.length} of 400` }}</span
                    >

                    <!-- <div>
                          <p
                            v-if="data.commitments.length < 1"
                            class="text-red-400 text-[10px] font-light"
                          >ESG commitments is required</p>
                          <p
                            v-if="data.commitments.length < 3"
                            class="text-red-400 text-[10px] font-light"
                          >ESG commitments should be at least 3 characters long</p>
                        </div> -->
                  </div>
                  <div class="relative w-full mb-6">
                    <label
                      class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                      for="control"
                    >
                      <span class="text-red-400">*</span>
                      How does your company intend to control and set expectations of its
                      ESG policy with third party contractors, suppliers, and other
                      stakeholders?
                    </label>
                    <textarea
                      id="control"
                      name="control"
                      type="text"
                      rows="4"
                      maxlength="400"
                      class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                      placeholder=""
                      style="transition: 'all .15s ease'"
                      v-model="data.control"
                    />
                    <span
                      class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]"
                      >{{ `${data.control.length} of 400` }}</span
                    >

                    <!-- <div>
                          <p
                            v-if="data.control.length < 1"
                            class="text-red-400 text-[10px] font-light"
                          >ESG control is required</p>
                          <p
                            v-if="data.control.length < 3"
                            class="text-red-400 text-[10px] font-light"
                          >ESG control should be at least 3 characters long</p>
                        </div> -->
                  </div>
                  <div class="relative w-full mb-6">
                    <label
                      class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                      for="primary_contact"
                    >
                      <span class="text-red-400">*</span>
                      Who within your company is primarily responsible for the
                      implementation of your company’s ESG policy and progress?
                    </label>
                    <textarea
                      id="primary_contact"
                      name="primary_contact"
                      type="text"
                      rows="4"
                      maxlength="400"
                      class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                      placeholder=""
                      style="transition: 'all .15s ease'"
                      v-model="data.primary_contact"
                    />
                    <span
                      class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]"
                      >{{ `${data.commitments.length} of 400` }}</span
                    >

                    <!-- <div>
                          <p
                            v-if="data.primary_contact.length < 1"
                            class="text-red-400 text-[10px] font-light"
                          >ESG primary contact is required</p>
                          <p
                            v-if="data.commitments.length < 3"
                            class="text-red-400 text-[10px] font-light"
                          >ESG primary contact should be at least 3 characters long</p>
                        </div> -->
                  </div>

                  <div
                    v-if="windowWidth > 768"
                    class="relative flex justify-between w-full mb-6"
                  >
                    <div class="flex w-full items-center justify-end">
                      <button
                        disabled
                        @click="onSubmit"
                        class="flex bg-black text-white rounded-[6px] text-[16px] font-Rubik px-[28px] py-[14px] outline-none focus:outline-none disabled:opacity-50"
                      >
                        <span :class="loading ? 'mr-2' : 'mr-0'" class="text-white">Submit</span>
                        <!-- <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                              <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                              </circle>
                            </svg> -->
                      </button>
                    </div>
                  </div>
                  <div v-else class="relative flex flex-col justify-center w-full mb-6">
                    <button
                      disabled
                      @click="onSubmit"
                      class="flex items-center justify-center bg-black text-white rounded-[6px] text-[16px] font-Rubik px-[28px] py-[14px] outline-none focus:outline-none disabled:opacity-50"
                    >
                      <span :class="loading ? 'mr-2' : 'mr-0'" class="text-white"
                        >Submit</span
                      >
                      <!-- <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                            </circle>
                          </svg> -->
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { reactive, ref } from 'vue'

export default {
  setup () {
    const windowWidth = ref(window.innerWidth)
    const loading = ref(false)

    const data = reactive({
      accountability: '',
      communication: '',
      commitments: '',
      control: '',
      primary_contact: ''
    })

    return {
      data,
      windowWidth,
      loading
    }
  }
}
</script>

<style scoped></style>
