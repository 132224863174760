const state = {
  usertoken: null,
  user: null,
  isUserLoggedIn: false,
  isUsertoken: false
}
const getters = {
  getUsertoken (state) {
    return state.usertoken
  }
}
const mutations = {
  setUsertoken (state, usertoken) {
    state.usertoken = usertoken
    state.isUserLoggedIn = !!(usertoken)
    state.isUsertoken = !!(usertoken)
  },
  setUser (state, user) {
    state.user = user
    state.usertoken = user.token
    state.isUserLoggedIn = !!(user.token)
    state.isUsertoken = !!(user.token)
  },
  logoutUser (state) {
    state.user = null
    state.usertoken = null
    state.isUserLoggedIn = false
    state.isUsertoken = false
  }
}
const actions = {
  setUsertoken ({ commit }, usertoken) {
    commit('setUsertoken', usertoken)
  },
  setUser ({ commit }, user) {
    commit('setUser', user)
  },
  logoutUser ({ commit }) {
    commit('logoutUser')
  }
}

export default { state, getters, mutations, actions }
