<template>
  <div id="account-settings" class="my-12">
    <!-- Heading -->
    <div class="flex justify-between">
      <h1 class="font-Playfair text-[32px] leading-[42.66px]">Account Settings</h1>
    </div>

    <!-- Tabs -->
    <div class="flex flex-col w-full mx-auto rounded-lg bg-white px-2 py-16 sm:px-0">
      <div class="flex space-x-1 rounded-xl bg-white p-1 w-full max-w-[80%]">
        <div
          class="max-w-[max-content] rounded-lg text-sm font-medium leading-5 text-greyText mr-6 relative focus:outline-none focus:ring-0 flex w-full justify-between"
        >
          <router-link to="/profile/user-details" v-slot="{ href, navigate, isActive }">
            <a
              :href="href"
              @click="navigate"
              :class="[
                'py-2.5',
                isActive
                  ? 'border-b-[2px] border-black bg-white text-black'
                  : 'border-b-[2px] border-[transparent] text-greyText bg-white/[0.12] hover:text-black',
              ]"
            >
              User Details
            </a>
          </router-link>
        </div>

        <div
          v-if="isParentUser"
          class="max-w-[max-content] rounded-lg text-sm font-medium leading-5 text-greyText mr-6 relative focus:outline-none focus:ring-0 flex w-full justify-between"
        >
          <router-link to="/profile/team" v-slot="{ href, navigate, isActive }">
            <a
              :href="href"
              @click="navigate"
              :class="[
                'py-2.5',
                isActive
                  ? 'border-b-[2px] border-black bg-white text-black'
                  : 'border-b-[2px] border-[transparent] text-greyText bg-white/[0.12] hover:text-black',
              ]"
            >
              Team
            </a>
          </router-link>
        </div>
      </div>
      <!-- Tab content display -->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  setup () {
    const isParentUser = JSON.parse(localStorage.getItem('vuex')).auth.user.is_parent_user
    return {
      isParentUser
    }
  }
}
</script>

<style scoped>
table tr {
  display: table-row;
}
/* table tr:not(:first-of-type) {
  border-bottom: solid 1px #E5E6E6;
} */
table tr {
  border-bottom: solid 1px #e5e6e6;
}
table td {
  padding-right: 12px;
  white-space: no-wrap;
  font-size: 15px;
}
</style>
