import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class PlaidService {
  static getPlaidInstitution (institutionId) {
    return axios.get(`${url}/plaid/institution/${institutionId}`)
  }
}

export default PlaidService
