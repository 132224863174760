<template>
  <!-- <div class="h-screen flex flex-col items-center justify-center px-10" v-if="device === 'mobile' ">
    <svg width="87" height="87" viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="43.5" cy="43.5" r="43.5" fill="#F4F5F5"/>
    <path d="M48.9422 55.3909C48.9422 58.392 46.5005 60.8337 43.4994 60.8337C40.4983 60.8337 38.0566 58.392 38.0566 55.3909C38.0566 52.3898 40.4983 49.9481 43.4994 49.9481C46.5005 49.9481 48.9422 52.3898 48.9422 55.3909ZM38.6866 27.7144L39.6119 46.2198C39.6554 47.0888 40.3726 47.771 41.2427 47.771H45.7561C46.6262 47.771 47.3434 47.0888 47.3869 46.2198L48.3122 27.7144C48.3588 26.7817 47.6152 26 46.6814 26H40.3174C39.3836 26 38.64 26.7817 38.6866 27.7144Z" fill="#828282" fill-opacity="0.6"/>
    </svg>
    <h3 class="font-medium text-lg mt-4">Oops!</h3>
    <p class="text-center font-Rubik text-sm text-greyText mt-2">This dashboard cannot be viewed on this device. Kindly make use of a laptop or tablet to view content.</p>
  </div> -->
  <div>
    <router-view/>
  </div>
</template>

<script>
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { computed, onMounted, watch, ref } from 'vue'
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()

    const token = computed(() => store.state.auth.usertoken)

    const isMobile = ref(false)

    const reSize = () => {
      isMobile.value = window.innerWidth < 850
    }
    const device = ref(null)

    const options = { method: 'GET', headers: { accept: 'application/json' } }

    fetch('https://api.withmono.com/v1/institutions', options)
      .then(response => response.json())
      .then(response => store.dispatch('setInstitutions', response))
      .catch(err => console.error(err))

    const deviceType = () => {
      const ua = navigator.userAgent
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet'
      } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return 'mobile'
      }
      return 'desktop'
    }
    localStorage.removeItem('link_token')
    const apiUrl = process.env.VUE_APP_API_URL
    const appToken = localStorage.getItem('app-token')
    const linkToken = localStorage.getItem('link_token')
    const createLinkToken = async () => {
      try {
        const res = await fetch(`${apiUrl}/api/v1/app/plaid/create-link-token`, {
          method: 'POST',
          headers: {
            Authorization: 'Bearer' + ' ' + appToken
          }
        })
        const data = await res.json()
        const linkToken = data.data.link_token || null
        localStorage.setItem('link_token', linkToken)
        return linkToken
      } catch (e) {
        console.log(e)
      }
    }
    console.log('link_token', linkToken)
    if (linkToken === null) {
      createLinkToken()
    }
    setInterval(() => {
      createLinkToken()
    }, 10800000)
    // const setStore = () => {
    //   if (!token.value) {
    //     store.dispatch('logoutUser')
    //   }
    // }
    // setStore()

    onMounted(() => {
      if (token.value) {
        const { exp } = jwt_decode(token.value)
        if (Date.now() >= exp * 1000) {
          store.dispatch('logoutUser')
        }
        axios.defaults.headers.common.Authorization = `Bearer ${token.value}`
      }
      window.addEventListener('resize', reSize(), { passive: true })
      device.value = deviceType()
    })

    watch(
      () => token.value,
      async (val) => {
        if (val) {
          // console.log(val)
          axios.defaults.headers.common.Authorization = `Bearer ${token.value}`
        } else {
          delete axios.defaults.headers.common.Authorization
        }
      },
      { immediate: true }
    )

    return {
      isMobile,
      deviceType,
      device
    }
  }
}
</script>
<style>
</style>
