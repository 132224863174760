<template>
  <main v-if="progress" class="mt-12">
    <div class="flex justify-between mb-10">
      <h1 class="font-Playfair text-[32px] leading-[42.66px]">Governance & Business Integrity</h1>
    </div>
    <div>
      <div class="scrolling flex flex-col xl:flex-row justify-between pt-4 text-sm text-gray-500">
        <div class="mr-16 mb-4">
          <h3 class="font-Rubik text-[18px] leading-[21.33px] font-medium text-black">Governance & Business Integrity</h3>
          <p class="font-Rubik text-[16px] leading-[24px] font-light w-[303px] mt-3">Kindly provide all the required information to proceed to the next stage.</p>
          <p class="font-Rubik text-[16px] leading-[24px] font-light w-[303px] mt-3">Click on the save draft button to save your progress</p>
          <p class="font-Rubik text-[16px] leading-[24px] font-normal w-[303px] mt-2">Estimated completion time: <span class="text-black">10mins</span></p>
          <p class="font-Rubik text-[14px] leading-[24px] font-normal text-red-400 mt-2">*Required</p>
        </div>
        <div class="flex flex-col flex-grow lg:pb-10 pb-8">
          <div class="max-w-[613px]">
            <div class="contain">
              <div class="progress relative bg-faintGrey rounded-[4px] py-3 px-4 mb-8">
                <div class="current-progress relative flex items-center justify-between active">
                  <div class="min-w-[60%] font-Rubik text-black leading-[24px] font-medium">
                    Anti-Corruption Management
                  </div>
                  <div class="max-w-[40%] text-[#828282] font-Rubik leading-[24px] font-medium">
                    <span class="text-black">1</span> of 3
                  </div>
                </div>
                <div class="current-progress relative flex items-center justify-between">
                  <div class="min-w-[60%] font-Rubik text-black leading-[24px] font-medium">
                    Whistleblower management
                  </div>
                  <div class="pages max-w-[40%] text-[#828282] font-Rubik leading-[24px] font-medium">
                    <span class="current-page text-black">2</span> of 3
                  </div>
                </div>
                <div class="current-progress relative flex items-center justify-between">
                  <div class="min-w-[60%] font-Rubik text-black leading-[24px] font-medium">
                    Corporate Governance Management
                  </div>
                  <div class="pages max-w-[40%] text-[#828282] font-Rubik leading-[24px] font-medium">
                    <span class="current-page text-black">3</span> of 3
                  </div>
                </div>
              </div>
              <div>
                <div class="form-outer">
                  <div class="forms">
                    <div class="page slide-page">
                      <div class="relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Do your competitors actively use bribery in your company’s target markets?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Do your competitors actively use bribery in your company’s target markets?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="bribery" type="radio" value="Yes" v-model="data.bribery" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="bribery" type="radio" value="No" v-model="data.bribery" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Is your company dependent on large contracts?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Is your company dependent on large contracts?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="large_contracts" type="radio" value="Yes" v-model="data.large_contracts" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="large_contracts" type="radio" value="No" v-model="data.large_contracts" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Is your company dependent on government contracts?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Is your company dependent on government contracts?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="government_contracts" type="radio" value="Yes" v-model="data.government_contracts" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="government_contracts" type="radio" value="No" v-model="data.government_contracts" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Is your company dependent on licenses or permits issued by government agencies?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Is your company dependent on licenses or permits issued by government agencies?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="government_permits" type="radio" value="Yes" v-model="data.government_permits" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="government_permits" type="radio" value="No" v-model="data.government_permits" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Is your company dependent on agents, intermediaries/brokers for business?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Is your company dependent on agents, intermediaries/brokers for business?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="agent_dependency" type="radio" value="Yes" v-model="data.agent_dependency" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="agent_dependency" type="radio" value="No" v-model="data.agent_dependency" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          Where do you believe your greatest risk for corruption may occur?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          How does your company intend to demonstrate a credible commitment to an anti-corruption culture?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="multi relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">
                          <span class="text-red-400">*</span>
                          Does your company have a formal written anti-corruption policy?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does your company have a formal written anti-corruption policy?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="anticorruption_policy" type="radio" value="Yes" v-model="data.anticorruption_policy" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="anticorruption_policy" type="radio" value="No" v-model="data.anticorruption_policy" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <template v-if="data.anticorruption_policy === 'Yes'">
                          <div class="relative w-full mb-6 mt-4 pl-8">
                            <div
                              class="font-Rubik text-greyText text-xs font-normal mb-2"
                              for="contract-file-upload"
                            >
                              <span class="text-red-400">*</span>
                              Anti-Corruption Policy
                            </div>
                            <div class="flex md:items-center items-start md:flex-row flex-col relative">
                              <label for="anticorruption_policy_upload" class="flex flex-nowrap items-center justify-center px-2 py-3 w-[40%] relative cursor-pointer bg-white font-Rubik text-black rounded-sm border border-dashed focus:outline-none focus:border-black hover:border-black focus:ring-0">
                                <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                  </svg>
                                </span>
                                Upload file
                                <input
                                  id="anticorruption_policy_upload"
                                  name="anticorruption_policy_upload"
                                  type="file"
                                  class="sr-only"
                                  @change="choosefile($event, 'anticorruption_policy_upload', 'GOVERNANCE')"
                                  :disabled="true"
                                  >
                              </label>
                              <!-- loader -->
                              <svg v-if="loading.state && loading.data === 'anticorruption_policy_upload'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                <circle cx="50" cy="50" fill="none" stroke="#000000" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                </circle>
                              </svg>
                              <div v-else class="md:ml-5 md:mt-0 mt-4 ml-0 text-blueLink flex items-center justify-between flex-nowrap md:w-[60%] w-full">
                                <span v-if="data.anticorruption_policy_upload" class="mr-3">
                                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_487_4554)">
                                      <path d="M1.80078 1.8C1.80078 0.805888 2.60667 0 3.60078 0H10.8008C11.0395 0 11.2684 0.0948211 11.4372 0.263604L15.9372 4.7636C16.106 4.93239 16.2008 5.1613 16.2008 5.4V16.2C16.2008 17.1941 15.3949 18 14.4008 18H3.60078C2.60667 18 1.80078 17.1941 1.80078 16.2V1.8ZM14.028 5.4L10.8008 2.17279V5.4H14.028ZM9.00078 1.8L3.60078 1.8V16.2H14.4008V7.2H9.90078C9.40372 7.2 9.00078 6.79706 9.00078 6.3V1.8ZM5.40078 9.9C5.40078 9.40294 5.80372 9 6.30078 9H11.7008C12.1978 9 12.6008 9.40294 12.6008 9.9C12.6008 10.3971 12.1978 10.8 11.7008 10.8H6.30078C5.80372 10.8 5.40078 10.3971 5.40078 9.9ZM5.40078 13.5C5.40078 13.0029 5.80372 12.6 6.30078 12.6H11.7008C12.1978 12.6 12.6008 13.0029 12.6008 13.5C12.6008 13.9971 12.1978 14.4 11.7008 14.4H6.30078C5.80372 14.4 5.40078 13.9971 5.40078 13.5Z" fill="#187CB4"/>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_487_4554">
                                        <rect width="18" height="18" fill="white"/>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                                <div class="flex items-center justify-between w-full relative">
                                  <p class="font-Rubik">{{ data.anticorruption_policy_upload }}</p>
                                  <span v-if="data.anticorruption_policy_upload" @click="removefile('anticorruption_policy_upload')" class="flex items-center pr-4">
                                    <svg class="h-5 w-5 text-originsRed cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                                      <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="date_time flex flex-col items-start justify-between pl-8">
                            <label
                              class="font-Rubik text-greyText text-xs font-normal"
                              for="policy_approval"
                            >
                              <!-- <span class="text-red-400">*</span> -->
                              When was the policy last approved by your company’s Board or governing body?
                            </label>

                            <div class="relative w-full">
                              <input
                                v-model="data.policy_approval"
                                id="policy_approval"
                                name="policy_approval"
                                type="date"
                                class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                style="transition: 'all .15s ease'"
                              />
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="relative w-full mb-6 mt-4 pl-8">
                            <label
                              class="font-Rubik text-greyText text-xs font-normal"
                              for="anti-corruption_method"
                            >
                              <span class="text-red-400">*</span>
                              How does your company set out its anti-corruption policy, procedures, and expectations across the organization?
                            </label>
                            <textarea
                              id="anti-corruption_method"
                              name="anti-corruption_method"
                              type="text"
                              rows="4"
                              maxlength="250"
                              class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                              placeholder=""
                              style="transition: 'all .15s ease'"
                            />
                            <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                          </div>
                        </template>
                      </div>
                      <div class="relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Is bribery and corruption defined and explicitly prohibited across your company?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Is bribery and corruption defined and explicitly prohibited across your company?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="prohibit_bribery" type="radio" value="Yes" v-model="data.prohibit_bribery" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="prohibit_bribery" type="radio" value="No" v-model="data.prohibit_bribery" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Does your company make it clear that there will be no adverse consequences for employees for not paying or receiving a bribe?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does your company make it clear that there will be no adverse consequences for employees for not paying or receiving a bribe?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="no_bribery_consequences" type="radio" value="Yes" v-model="data.no_bribery_consequences" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="no_bribery_consequences" type="radio" value="No" v-model="data.no_bribery_consequences" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          How does your company set out its approach to the payment of expenses of, or by, third parties?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          What is your company’s policy on making political and charitable contributions of cash, resources, or time?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          What is your company’s policy regarding conflicts of interests of employees, directors, and other stakeholders?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          Who within your company takes responsibility for anti-corruption issues?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          Who does this person report to?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          What regular reports does this person provide?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          Does or will your company produce an annual report prepared by the responsible officer for anti-corruption issues analyzing the effectiveness and application of your company’s anti-corruption policies?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="date_time flex flex-col items-start justify-between">
                        <label
                          class="font-Rubik text-greyText text-sm font-normal"
                          for="policy_approval"
                        >
                          <span class="text-red-400">*</span>
                          When does your company intend on producing its next report on anti-corruption issues and policies?
                        </label>

                        <div class="relative w-full">
                          <input
                            id="policy_approval"
                            name="policy_approval"
                            type="date"
                            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                            style="transition: 'all .15s ease'"
                          />
                        </div>
                      </div>
                      <div class="relative w-full mb-6 mt-4">
                        <label
                          class="font-Rubik text-greyText text-sm font-normal"
                          for="policy_approval"
                        >
                          <span class="text-red-400">*</span>
                          What corruption issues have been reported or noted lately?
                        </label>

                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          How does your company evaluate the corruption/bribery risk of each supplier and customer?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          How does your company seek to ensure that suppliers, customers, and other stakeholders adopt the same standards of conduct as a condition of doing business with your company?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          Has your company ceased to deal with any supplier or customer because of concerns over corruption?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="multi relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Does your company provide training to employees on anti-corruption issues?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does your company provide training to employees on anti-corruption issues?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="anticorruption_training" type="radio" value="Yes" v-model="data.anticorruption_training" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="anticorruption_training" type="radio" value="No" v-model="data.anticorruption_training" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <template v-if="data.anticorruption_training === 'Yes'">
                          <div class="date_time flex flex-col items-start justify-between my-4">
                            <label
                              class="font-Rubik text-greyText text-xs font-normal"
                              for="policy_approval"
                            >
                              <!-- <span class="text-red-400">*</span> -->
                              When was the last anti-corruption training provided?
                            </label>

                            <div class="relative w-full">
                              <input
                                v-model="data.policy_approval"
                                id="policy_approval"
                                name="policy_approval"
                                type="date"
                                class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                style="transition: 'all .15s ease'"
                              />
                            </div>
                          </div>
                          <div class="relative w-full mb-6">
                            <label
                              class="font-Rubik text-greyText text-xs font-normal"
                              for="policy_approval"
                            >
                              <span class="text-red-400">*</span>
                              How frequently is this training provided within your company?
                            </label>

                            <div class="relative w-full">
                              <textarea
                                id="corruption_risk"
                                name="corruption_risk"
                                type="text"
                                rows="4"
                                maxlength="250"
                                class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                placeholder=""
                                style="transition: 'all .15s ease'"
                              />
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <!-- <div class="relative w-full mb-6 mt-4">
                            <label
                              class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                              for="anti-corruption_method"
                            >
                              <span class="text-red-400">*</span>
                              How does your company set out its anti-corruption policy, procedures, and expectations across the organization?
                            </label>
                            <textarea
                              id="anti-corruption_method"
                              name="anti-corruption_method"
                              type="text"
                              rows="4"
                              maxlength="250"
                              class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                              placeholder=""
                              style="transition: 'all .15s ease'"
                            />
                          </div> -->
                        </template>
                      </div>
                      <div class="relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Does or will your company publish its anti-corruption policy on its website?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does or will your company publish its anti-corruption policy on its website?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="public_anticorruption" type="radio" value="Yes" v-model="data.public_anticorruption" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="public_anticorruption" type="radio" value="No" v-model="data.public_anticorruption" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="boolean relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Does your company’s anti-corruption policy and supporting employment contract make it clear that any breach of your company’s policy may amount to serious misconduct potentially leading to dismissal?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does your company’s anti-corruption policy and supporting employment contract make it clear that any breach of your company’s policy may amount to serious misconduct potentially leading to dismissal?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="anticorruption_dismissal" type="radio" value="Yes" v-model="data.anticorruption_dismissal" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="anticorruption_dismissal" type="radio" value="No" v-model="data.anticorruption_dismissal" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-sm font-normal"
                          for="policy_approval"
                        >
                          <span class="text-red-400">*</span>
                          What is your company’s gifts/corporate entertaining policy?
                        </label>

                        <div class="relative w-full">
                          <textarea
                            id="gifting_policy"
                            name="gifting_policy"
                            type="text"
                            rows="4"
                            maxlength="250"
                            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                            placeholder=""
                            style="transition: 'all .15s ease'"
                          />
                        </div>
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-sm font-normal"
                          for="policy_approval"
                        >
                          <span class="text-red-400">*</span>
                          What are your company’s record-keeping procedures for instances or allegations of corruption?
                        </label>

                        <div class="relative w-full">
                          <textarea
                            id="corruption_updates"
                            name="corruption_updates"
                            type="text"
                            rows="4"
                            maxlength="250"
                            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                            placeholder=""
                            style="transition: 'all .15s ease'"
                          />
                        </div>
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-sm font-normal"
                          for="policy_approval"
                        >
                          <span class="text-red-400">*</span>
                          Which local authorities, if any, should be notified if there is a suspicion of bribery and corruption?
                        </label>

                        <div class="relative w-full">
                          <textarea
                            id="authorities_alert"
                            name="authorities_alert"
                            type="text"
                            rows="4"
                            maxlength="250"
                            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                            placeholder=""
                            style="transition: 'all .15s ease'"
                          />
                        </div>
                      </div>
                      <div class="boolean relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Will your company perform regularly scheduled internal and external audits of the operational effectiveness of your company’s anti-bribery and corruption systems?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Will your company perform regularly scheduled internal and external audits of the operational effectiveness of your company’s anti-bribery and corruption systems?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="system_audits" type="radio" value="Yes" v-model="data.system_audits" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="system_audits" type="radio" value="No" v-model="data.system_audits" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="relative flex md:justify-end justify-between w-full mb-6">
                        <!-- <button
                          class="flex items-center justify-center bg-faintGrey text-black rounded-[6px] md:text-[16px] text-[14px] md:leading-[24px] leading-[20px] font-Rubik px-[28px] py-[14px] md:max-w-[157px] w-full outline-none focus:outline-none disabled:opacity-50 mr-8"
                          @click="onSaveDraft"
                        >
                          Save draft
                        </button> -->
                        <button class="bg-black text-white rounded-[6px] text-[16px] font-Rubik px-[28px] py-[14px] md:max-w-[118px] w-full outline-none focus:outline-none disabled:opacity-50" @click="goToStep2">
                          Continue
                        </button>
                      </div>
                    </div>
                    <div class="page">
                      <div class="multi relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">
                          <span class="text-red-400">*</span>
                          Does your company have a formal written whistleblower policy?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does your company have a formal written whistleblower policy?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="has_whistleblower_policy" type="radio" value="Yes" v-model="data.has_whistleblower_policy" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="has_whistleblower_policy" type="radio" value="No" v-model="data.has_whistleblower_policy" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <template v-if="data.has_whistleblower_policy === 'Yes'">
                          <div class="relative w-full mb-6 mt-4 pl-8">
                            <div
                              class="font-Rubik text-greyText text-xs font-normal mb-2"
                              for="whistleblower_policy_upload"
                            >
                              <span class="text-red-400">*</span>
                              Whistleblower Policy
                            </div>
                            <div class="flex md:items-center items-start md:flex-row flex-col relative">
                              <label for="whistleblower_policy_upload" class="flex flex-nowrap items-center justify-center px-2 py-3 w-[40%] relative cursor-pointer bg-white font-Rubik text-black rounded-sm border border-dashed focus:outline-none focus:border-black hover:border-black focus:ring-0">
                                <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                  </svg>
                                </span>
                                Upload file
                                <input
                                  id="whistleblower_policy_upload"
                                  name="whistleblower_policy_upload"
                                  type="file"
                                  class="sr-only"
                                  @change="choosefile($event, 'whistleblower_policy_upload', 'GOVERNANCE')"
                                  :disabled="true"
                                  >
                              </label>
                              <!-- loader -->
                              <svg v-if="loading.state && loading.data === 'whistleblower_policy_upload'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                <circle cx="50" cy="50" fill="none" stroke="#000000" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                </circle>
                              </svg>
                              <div v-else class="md:ml-5 md:mt-0 mt-4 ml-0 text-blueLink flex items-center justify-between flex-nowrap md:w-[60%] w-full">
                                <span v-if="data.whistleblower_policy_upload" class="mr-3">
                                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_487_4554)">
                                      <path d="M1.80078 1.8C1.80078 0.805888 2.60667 0 3.60078 0H10.8008C11.0395 0 11.2684 0.0948211 11.4372 0.263604L15.9372 4.7636C16.106 4.93239 16.2008 5.1613 16.2008 5.4V16.2C16.2008 17.1941 15.3949 18 14.4008 18H3.60078C2.60667 18 1.80078 17.1941 1.80078 16.2V1.8ZM14.028 5.4L10.8008 2.17279V5.4H14.028ZM9.00078 1.8L3.60078 1.8V16.2H14.4008V7.2H9.90078C9.40372 7.2 9.00078 6.79706 9.00078 6.3V1.8ZM5.40078 9.9C5.40078 9.40294 5.80372 9 6.30078 9H11.7008C12.1978 9 12.6008 9.40294 12.6008 9.9C12.6008 10.3971 12.1978 10.8 11.7008 10.8H6.30078C5.80372 10.8 5.40078 10.3971 5.40078 9.9ZM5.40078 13.5C5.40078 13.0029 5.80372 12.6 6.30078 12.6H11.7008C12.1978 12.6 12.6008 13.0029 12.6008 13.5C12.6008 13.9971 12.1978 14.4 11.7008 14.4H6.30078C5.80372 14.4 5.40078 13.9971 5.40078 13.5Z" fill="#187CB4"/>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_487_4554">
                                        <rect width="18" height="18" fill="white"/>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                                <div class="flex items-center justify-between w-full relative">
                                  <p class="font-Rubik">{{ data.whistleblower_policy_upload }}</p>
                                  <span v-if="data.whistleblower_policy_upload" @click="removefile('whistleblower_policy_upload')" class="flex items-center pr-4">
                                    <svg class="h-5 w-5 text-originsRed cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                                      <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="date_time flex flex-col items-start justify-between pl-8">
                            <label
                              class="font-Rubik text-greyText text-xs font-normal"
                              for="policy_approval"
                            >
                              <span class="text-red-400">*</span>
                              <!-- <span class="text-red-400">*</span> -->
                              When was a Whistleblower Policy drafted and approved by your Company’s Board or governing body?
                            </label>

                            <div class="relative w-full">
                              <input
                                v-model="data.whistleblower_draft_date"
                                id="policy_approval"
                                name="policy_approval"
                                type="date"
                                class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                style="transition: 'all .15s ease'"
                              />
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="relative w-full mb-6 mt-4 pl-8">
                            <label
                              class="font-Rubik text-greyText text-xs font-normal"
                              for="report_encouragement"
                            >
                              <span class="text-red-400">*</span>
                              How does your company encourage employees to report instances of bribery, corruption, and other corporate wrongdoing?
                            </label>
                            <textarea
                              id="report_encouragement"
                              name="report_encouragement"
                              type="text"
                              rows="4"
                              maxlength="250"
                              class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                              placeholder=""
                              style="transition: 'all .15s ease'"
                            />
                            <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                          </div>
                        </template>
                      </div>
                      <div class="relative w-full mb-6 mt-4">
                        <label
                          class="font-Rubik text-greyText text-sm font-normal"
                          for="whistleblowing_management"
                        >
                          <span class="text-red-400">*</span>
                          How are whistleblowing concerns handled by your company?
                        </label>

                        <textarea
                          id="whistleblowing_management"
                          name="whistleblowing_management"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                      </div>
                      <div class="relative w-full mb-6 mt-4">
                        <label
                          class="font-Rubik text-greyText text-sm font-normal"
                          for="thirdparty_whistleblowing"
                        >
                          <span class="text-red-400">*</span>
                          How does your company encourage third parties to inform it of any bribes sought, received or paid, or other corporate malpractices?
                        </label>

                        <textarea
                          id="thirdparty_whistleblowing"
                          name="thirdparty_whistleblowing"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                      </div>
                      <div class="relative w-full mb-6 mt-4">
                        <label
                          class="font-Rubik text-greyText text-sm font-normal"
                          for="thirdparty_whistleblower"
                        >
                          <span class="text-red-400">*</span>
                          Who within your company takes responsibility for whistleblowing concerns?
                        </label>

                        <textarea
                          id="thirdparty_whistleblower"
                          name="thirdparty_whistleblower"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                      </div>
                      <div class="relative w-full mb-6 mt-4">
                        <label
                          class="font-Rubik text-greyText text-sm font-normal"
                          for="whistleblower_superior"
                        >
                          <span class="text-red-400">*</span>
                          Who does this person report to?
                        </label>

                        <textarea
                          id="whistleblower_superior"
                          name="whistleblower_superior"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                      </div>
                      <div class="relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Does/Will your company produce an annual report prepared by the responsible officer for anti-corruption issues analyzing the effectiveness and application of your company’s whistleblower policies?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does/Will your company produce an annual report prepared by the responsible officer for anti-corruption issues analyzing the effectiveness and application of your company’s whistleblower policies?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="annual_corruption_report" type="radio" value="Yes" v-model="data.annual_corruption_report" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="annual_corruption_report" type="radio" value="No" v-model="data.annual_corruption_report" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Does your company intend to distribute said report to your company’s Board of Directors and Shareholders?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does your company intend to distribute said report to your company’s Board of Directors and Shareholders?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="report_transparency" type="radio" value="Yes" v-model="data.report_transparency" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="report_transparency" type="radio" value="No" v-model="data.report_transparency" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="date_time flex flex-col items-start justify-between">
                        <label
                          class="font-Rubik text-greyText text-sm font-normal"
                          for="next_report_date"
                        >
                          <span class="text-red-400">*</span>
                          When does your company intend on producing its next report on whistleblower issues and policies?
                        </label>

                        <div class="relative w-full">
                          <input
                            id="next_report_date"
                            name="next_report_date"
                            type="date"
                            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                            style="transition: 'all .15s ease'"
                          />
                        </div>
                      </div>
                      <div class="relative w-full mb-6 mt-4">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="recent_whitsleblower_report"
                        >
                          <span class="text-red-400">*</span>
                          What whistleblower issues have been reported or noted lately?
                        </label>
                        <textarea
                          id="recent_whitsleblower_report"
                          name="recent_whitsleblower_report"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="whistleblower_communication"
                        >
                          <span class="text-red-400">*</span>
                          How does your company communicate its whistleblower policy internally and externally?
                        </label>
                        <textarea
                          id="whistleblower_communication"
                          name="whistleblower_communication"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="multi relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">
                          <span class="text-red-400">*</span>
                          Does your company provide training to employees on whistleblower issues?
                        </label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does your company provide training to employees on whistleblower issues?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="whistleblower_training" type="radio" value="Yes" v-model="data.whistleblower_training" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="whistleblower_training" type="radio" value="No" v-model="data.whistleblower_training" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <template v-if="data.whistleblower_training === 'Yes'">
                          <div class="date_time flex flex-col items-start justify-between my-4 pl-8">
                            <label
                              class="font-Rubik text-greyText text-xs font-normal"
                              for="policy_approval"
                            >
                              <span class="text-red-400">*</span>
                              When was the last whistleblower training provided?
                            </label>

                            <div class="relative w-full">
                              <input
                                v-model="data.last_whistleblower_training_date"
                                id="policy_approval"
                                name="policy_approval"
                                type="date"
                                class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                style="transition: 'all .15s ease'"
                              />
                            </div>
                          </div>
                          <div class="relative w-full mb-6 pl-8">
                            <label
                              class="font-Rubik text-greyText text-xs font-normal"
                              for="policy_approval"
                            >
                              <span class="text-red-400">*</span>
                              How frequently is this training provided within your company?
                            </label>

                            <div class="relative w-full">
                              <textarea
                                id="corruption_risk"
                                name="corruption_risk"
                                type="text"
                                rows="4"
                                maxlength="250"
                                class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                                placeholder=""
                                style="transition: 'all .15s ease'"
                              />
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <!-- <div class="relative w-full mb-6 mt-4">
                            <label
                              class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                              for="anti-corruption_method"
                            >
                              <span class="text-red-400">*</span>
                              How does your company set out its anti-corruption policy, procedures, and expectations across the organization?
                            </label>
                            <textarea
                              id="anti-corruption_method"
                              name="anti-corruption_method"
                              type="text"
                              rows="4"
                              maxlength="250"
                              class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                              placeholder=""
                              style="transition: 'all .15s ease'"
                            />
                          </div> -->
                        </template>
                      </div>
                      <div class="relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Does or will your company publish its whistleblower policy on its website?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does or will your company publish its whistleblower policy on its website?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="public_whistleblowing" type="radio" value="Yes" v-model="data.public_whistleblowing" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="public_whistleblowing" type="radio" value="No" v-model="data.public_whistleblowing" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Does your company’s whistleblower policy and supporting employment contract make it clear that any breach of your company’s policy may amount to serious misconduct potentially leading to dismissal?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does your company’s whistleblower policy and supporting employment contract make it clear that any breach of your company’s policy may amount to serious misconduct potentially leading to dismissal?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="employment_whistleblowing" type="radio" value="Yes" v-model="data.employment_whistleblowing" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="employment_whistleblowing" type="radio" value="No" v-model="data.employment_whistleblowing" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          What are your company’s record-keeping procedures for whistleblower reports?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Will your company perform regularly scheduled internal and external audits of the operational effectiveness of your company’s whistleblower systems?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Will your company perform regularly scheduled internal and external audits of the operational effectiveness of your company’s whistleblower systems?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="regular_whistleblower_audits" type="radio" value="Yes" v-model="data.regular_whistleblower_audits" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="regular_whistleblower_audits" type="radio" value="No" v-model="data.regular_whistleblower_audits" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>

                      <div v-if="windowWidth > 768" class="relative flex justify-between w-full mb-6">
                        <button class="flex items-center justify-center border-[0.6px] border-[#D1D2D2] text-black rounded-[6px] text-[16px] font-Rubik px-[28px] py-[14px] outline-none focus:outline-none" @click="returnToStep1">
                          <span class="block mr-[8px] mb-[2px]">
                            <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.16406 4.99935C1.16406 4.82254 1.2343 4.65297 1.35932 4.52794L5.35933 0.527944C5.61967 0.267594 6.04178 0.267594 6.30213 0.527944C6.56248 0.788293 6.56248 1.2104 6.30213 1.47075L3.4402 4.33268L11.1641 4.33268C11.5323 4.33268 11.8307 4.63116 11.8307 4.99935C11.8307 5.36754 11.5323 5.66602 11.1641 5.66602L3.4402 5.66601L6.30213 8.52794C6.56248 8.78829 6.56248 9.2104 6.30213 9.47075C6.04178 9.7311 5.61967 9.7311 5.35932 9.47075L1.35932 5.47075C1.2343 5.34573 1.16406 5.17616 1.16406 4.99935Z" fill="#0D0D0D" stroke="black" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </span>
                          Back
                        </button>
                        <div class="flex items-center justify-end">
                          <!-- <button
                            class="flex bg-faintGrey text-[#666666] hover:text-black rounded-[6px] text-[16px] leading-[24px] font-Rubik px-[28px] py-[14px] outline-none focus:outline-none disabled:opacity-50 mr-10"
                            @click="onSaveDraft"
                          >
                            Save draft
                          </button> -->
                          <button class="bg-black text-white rounded-[6px] text-[16px] font-Rubik px-[28px] py-[14px] outline-none focus:outline-none disabled:opacity-50" @click="goToStep3">
                            Continue
                          </button>
                        </div>
                      </div>
                      <div v-else class="relative flex flex-col justify-center w-full mb-6">
                        <div class="flex items-center justify-between w-full mb-8">
                          <button class="flex items-center justify-center border-[0.6px] border-[#D1D2D2] text-black rounded-[6px] text-[14px] font-Rubik leading-[20px] px-[28px] py-[14px] md:max-w-[157px] w-full mr-8 outline-none focus:outline-none" @click="returnToStep1">
                            <span class="block mr-[8px] mb-[2px]">
                              <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.16406 4.99935C1.16406 4.82254 1.2343 4.65297 1.35932 4.52794L5.35933 0.527944C5.61967 0.267594 6.04178 0.267594 6.30213 0.527944C6.56248 0.788293 6.56248 1.2104 6.30213 1.47075L3.4402 4.33268L11.1641 4.33268C11.5323 4.33268 11.8307 4.63116 11.8307 4.99935C11.8307 5.36754 11.5323 5.66602 11.1641 5.66602L3.4402 5.66601L6.30213 8.52794C6.56248 8.78829 6.56248 9.2104 6.30213 9.47075C6.04178 9.7311 5.61967 9.7311 5.35932 9.47075L1.35932 5.47075C1.2343 5.34573 1.16406 5.17616 1.16406 4.99935Z" fill="#0D0D0D" stroke="black" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </span>
                            Back
                          </button>
                          <!-- <button
                            class="flex items-center justify-center bg-faintGrey text-black rounded-[6px] md:text-[16px] text-[14px] md:leading-[24px] leading-[20px] font-Rubik px-[28px] py-[14px] md:max-w-[157px] w-full outline-none focus:outline-none disabled:opacity-50"
                            @click="onSaveDraft"
                          >
                            Save draft
                          </button> -->
                        </div>
                        <button class="bg-black text-white rounded-[6px] text-[16px] font-Rubik px-[28px] py-[14px] outline-none focus:outline-none disabled:opacity-50" @click="goToStep3">
                          Continue
                        </button>
                      </div>
                    </div>
                    <div class="page">
                      <div class="multi relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">
                          <span class="text-red-400">*</span>
                          Do your company’s directors voluntarily abide by the code of corporate governance in the countries where your company is operational?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Do your company’s directors voluntarily abide by the code of corporate governance in the countries where your company is operational?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="corporate_governance_dedication" type="radio" value="Yes" v-model="data.corporate_governance_dedication" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="corporate_governance_dedication" type="radio" value="No" v-model="data.corporate_governance_dedication" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <template v-if="data.corporate_governance_dedication === 'Yes'">
                          <div class="text relative w-full mb-6 mt-8 pl-8">
                            <label
                              class="font-Rubik text-greyText text-xs font-normal"
                              for="corruption_risk"
                            >
                              <span class="text-red-400">*</span>
                              How is this compliance disclosed?
                            </label>
                            <textarea
                              id="corruption_risk"
                              name="corruption_risk"
                              type="text"
                              rows="4"
                              maxlength="250"
                              class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                              placeholder=""
                              style="transition: 'all .15s ease'"
                            />
                            <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                          </div>
                        </template>
                        <template v-else>
                        </template>
                      </div>
                      <div class="relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Does your company have its own standalone corporate governance code and/or policies?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does your company have its own standalone corporate governance code and/or policies?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="has_governance_polices" type="radio" value="Yes" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="has_governance_polices" type="radio" value="No" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="text relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          How does your company monitor compliance with external and internal corporate governance standards?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="text relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          How does your company disclose to its stakeholders the extent of its compliance with its corporate governance policies and procedures?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="multi relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">
                          <span class="text-red-400">*</span>
                          Does your company have a code of ethics?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does your company have a code of ethics?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="has_codeof_ethics" type="radio" value="Yes" v-model="data.has_codeof_ethics" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="has_codeof_ethics" type="radio" value="No" v-model="data.has_codeof_ethics" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <template v-if="data.has_codeof_ethics === 'Yes'">
                          <div class="text relative w-full mb-6 mt-8 pl-8">
                            <label
                              class="font-Rubik text-greyText text-xs font-normal"
                              for="corruption_risk"
                            >
                              <span class="text-red-400">*</span>
                              What is your company’s code of ethics?
                            </label>
                            <textarea
                              id="corruption_risk"
                              name="corruption_risk"
                              type="text"
                              rows="4"
                              maxlength="250"
                              class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                              placeholder=""
                              style="transition: 'all .15s ease'"
                            />
                            <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                          </div>
                        </template>
                        <template v-else>
                        </template>
                      </div>
                      <div class="text relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          Who within your company takes responsibility for ensuring compliance with your company’s CG policies and code of ethics?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="text relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          Who does this person report to?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="text relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          What regular reports does this person provide?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="bool relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Does the management/Board of Directors approve an annual calendar of corporate events (Board meeting, shareholders meetings, etc?)</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does the management/Board of Directors approve an annual calendar of corporate events (Board meeting, shareholders meetings, etc?)</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="annual_calendar_approval" type="radio" value="Yes" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="annual_calendar_approval" type="radio" value="No" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="text relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          How is the composition of the Board of Directors determined?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="multi-text relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          How many of the Board are Executive Directors and how many are Non-Executive?
                        </label>
                        <div class="flex items-center lg:flex-row flex-col">
                          <input
                            id="corruption_risk"
                            name="corruption_risk"
                            type="number"
                            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0 mr-2"
                            placeholder="Executive"
                            style="transition: 'all .15s ease'"
                          />
                          <input
                            id="corruption_risk"
                            name="corruption_risk"
                            type="number"
                            class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0 mr-2"
                            placeholder="Non-Executive"
                            style="transition: 'all .15s ease'"
                          />
                        </div>
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="boolean relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Is there an independent Chairman of the Board?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Is there an independent Chairman of the Board?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="board_chairman" type="radio" value="Yes" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="board_chairman" type="radio" value="No" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          How are directors selected and appointed at your company?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          Does your company have any independent directors and/or advisors?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          What role should a Board of Directors play with management in setting the strategy and vision of your company?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          What role should a Board of Directors play in selecting and compensating the CEO and senior management of your company?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          What role should a Board of Directors play in overall risk management, including but not limited to oversight of internal controls, external audit and preparation of financial statements?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          What role should a Board of Directors play in major capital expenditures and large-value transactions?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6 mt-4">
                        <label
                          class="font-Rubik text-greyText text-sm font-normal"
                          for="policy_approval"
                        >
                          <span class="text-red-400">*</span>
                          How does the Board of Directors review material transactions that involve conflicts of interest and related parties?
                        </label>

                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                      </div>
                      <div class="boolean relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Is there a formal or informal succession plan for the current CEO in the case of emergencies?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Is there a formal or informal succession plan for the current CEO in the case of emergencies?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="succession_plan" type="radio" value="Yes" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="succession_plan" type="radio" value="No" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          How long does the current CEO intend to remain in this position?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="boolean relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Does your company offer induction or regular corporate governance training to members of the Board?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does your company offer induction or regular corporate governance training to members of the Board?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="corporate_governance_offers" type="radio" value="Yes" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="corporate_governance_offers" type="radio" value="No" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          How often does the Board of Directors conduct self-evaluations or other reviews of its effectiveness?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          How often does the Board typically meet?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="boolean relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Does your company currently prepare and distribute agendas ahead of each meeting?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does your company currently prepare and distribute agendas ahead of each meeting?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="meeting_agendas" type="radio" value="Yes" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="meeting_agendas" type="radio" value="No" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="multi relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">
                          <span class="text-red-400">*</span>
                          Does your company have a corporate secretary?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does your company have a corporate secretary?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="has_secretary" type="radio" value="Yes" v-model="data.has_secretary" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="has_secretary" type="radio" value="No" v-model="data.has_secretary" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <template v-if="data.has_secretary === 'Yes'">
                          <div class="text relative w-full mb-6 mt-8 pl-8">
                            <label
                              class="font-Rubik text-greyText text-xs font-normal"
                              for="corruption_risk"
                            >
                              <span class="text-red-400">*</span>
                              Who is your company secretary?
                            </label>
                            <textarea
                              id="corruption_risk"
                              name="corruption_risk"
                              type="text"
                              rows="4"
                              maxlength="250"
                              class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                              placeholder=""
                              style="transition: 'all .15s ease'"
                            />
                            <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                          </div>
                        </template>
                        <template v-else>
                          <div class="text relative w-full mb-6 mt-8 pl-8">
                            <label
                              class="font-Rubik text-greyText text-xs font-normal"
                              for="corruption_risk"
                            >
                              <span class="text-red-400">*</span>
                              Who is responsible for organizing Board meetings?
                            </label>
                            <textarea
                              id="corruption_risk"
                              name="corruption_risk"
                              type="text"
                              rows="4"
                              maxlength="250"
                              class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                              placeholder=""
                              style="transition: 'all .15s ease'"
                            />
                            <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                          </div>
                        </template>
                      </div>
                      <div class="multi relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">
                          <span class="text-red-400">*</span>
                          Does your company have Board Committees?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does your company have Board Committees?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="has_boardcomittees" type="radio" value="Yes" v-model="data.has_boardcomittees" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="has_boardcomittees" type="radio" value="No" v-model="data.has_boardcomittees" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <template v-if="data.has_boardcomittees === 'Yes'">
                          <div class="text relative w-full mb-6 mt-8 pl-8">
                            <label
                              class="font-Rubik text-greyText text-xs font-normal"
                              for="corruption_risk"
                            >
                              <span class="text-red-400">*</span>
                              What are the current Board Committees and how were they established?
                            </label>
                            <textarea
                              id="corruption_risk"
                              name="corruption_risk"
                              type="text"
                              rows="4"
                              maxlength="250"
                              class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                              placeholder=""
                              style="transition: 'all .15s ease'"
                            />
                            <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                          </div>
                          <div class="text relative w-full mb-6 mt-8 pl-8">
                            <label
                              class="font-Rubik text-greyText text-xs font-normal"
                              for="corruption_risk"
                            >
                              <span class="text-red-400">*</span>
                              Who sits on each committee?
                            </label>
                            <textarea
                              id="corruption_risk"
                              name="corruption_risk"
                              type="text"
                              rows="4"
                              maxlength="250"
                              class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                              placeholder=""
                              style="transition: 'all .15s ease'"
                            />
                            <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                          </div>
                          <div class="text relative w-full mb-6 mt-8 pl-8">
                            <label
                              class="font-Rubik text-greyText text-xs font-normal"
                              for="corruption_risk"
                            >
                              <span class="text-red-400">*</span>
                              How do those committees report to the board and how often?
                            </label>
                            <textarea
                              id="corruption_risk"
                              name="corruption_risk"
                              type="text"
                              rows="4"
                              maxlength="250"
                              class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                              placeholder=""
                              style="transition: 'all .15s ease'"
                            />
                            <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                          </div>
                          <div class="text relative w-full mb-6 mt-8 pl-8">
                            <label
                              class="font-Rubik text-greyText text-xs font-normal"
                              for="corruption_risk"
                            >
                              <span class="text-red-400">*</span>
                              Which committees are made up of Non-Executive directors?
                            </label>
                            <textarea
                              id="corruption_risk"
                              name="corruption_risk"
                              type="text"
                              rows="4"
                              maxlength="250"
                              class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                              placeholder=""
                              style="transition: 'all .15s ease'"
                            />
                            <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                          </div>
                        </template>
                        <template v-else>
                        </template>
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          How often are your company’s financial statements reviewed by the Board/Audit Committee?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="relative w-full mb-6">
                        <label
                          class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                          for="corruption_risk"
                        >
                          <span class="text-red-400">*</span>
                          How does your company make all material information (financial and non financial) fully, timely and equally available to all stakeholders of your company?
                        </label>
                        <textarea
                          id="corruption_risk"
                          name="corruption_risk"
                          type="text"
                          rows="4"
                          maxlength="250"
                          class="px-4 py-4 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-borderGray rounded-[2px] w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                          placeholder=""
                          style="transition: 'all .15s ease'"
                        />
                        <!-- <span class="block w-max absolute right-0 font-Rubik font-light text-[12px] leading-[14.22px]">{{`${data.pricing.length} of 250`}}</span> -->
                      </div>
                      <div class="boolean relative w-full mb-6">
                        <label class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal">Does your company have external bookkeepers and/or auditors of its financial statements?</label>
                        <fieldset class="mt-4">
                          <legend class="sr-only">Does your company have external bookkeepers and/or auditors of its financial statements?</legend>
                          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div class="flex items-center md:ml-2 ml-0">
                              <input id="yes" name="external_auditors" type="radio" value="Yes" checked class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="yes" class="ml-3 block text-sm font-medium text-gray-700">
                                Yes
                              </label>
                            </div>

                            <div class="flex items-center">
                              <input id="no" name="external_auditors" type="radio" value="No" class="focus:ring-1 focus:ring-black h-4 w-4 text-black border-gray-600">
                              <label for="no" class="ml-3 block text-sm font-medium text-gray-700">
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>

                      <div v-if="windowWidth > 768" class="relative flex justify-between w-full mb-6">
                        <button class="flex items-center justify-center border-[0.6px] border-[#D1D2D2] text-black rounded-[6px] text-[16px] font-Rubik px-[28px] py-[14px] outline-none focus:outline-none" @click="returnToStep2">
                          <span class="block mr-[8px] mb-[2px]">
                            <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.16406 4.99935C1.16406 4.82254 1.2343 4.65297 1.35932 4.52794L5.35933 0.527944C5.61967 0.267594 6.04178 0.267594 6.30213 0.527944C6.56248 0.788293 6.56248 1.2104 6.30213 1.47075L3.4402 4.33268L11.1641 4.33268C11.5323 4.33268 11.8307 4.63116 11.8307 4.99935C11.8307 5.36754 11.5323 5.66602 11.1641 5.66602L3.4402 5.66601L6.30213 8.52794C6.56248 8.78829 6.56248 9.2104 6.30213 9.47075C6.04178 9.7311 5.61967 9.7311 5.35932 9.47075L1.35932 5.47075C1.2343 5.34573 1.16406 5.17616 1.16406 4.99935Z" fill="#0D0D0D" stroke="black" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </span>
                          Back
                        </button>
                        <div class="flex items-center justify-end">
                          <!-- <button
                            class="flex bg-faintGrey text-[#666666] hover:text-black rounded-[6px] text-[16px] leading-[24px] font-Rubik px-[28px] py-[14px] outline-none focus:outline-none disabled:opacity-50 mr-10"
                            @click="onSaveDraft"
                          >
                            Save draft
                          </button> -->
                          <button
                            @click="onSubmit"
                            disabled
                            class="flex bg-black text-white rounded-[6px] text-[16px] font-Rubik px-[28px] py-[14px] outline-none focus:outline-none disabled:opacity-50"
                          >
                            <span :class="loading ? 'mr-2' : 'mr-0'" class="text-white">Submit</span>
                            <!-- <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                              <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                              </circle>
                            </svg> -->
                          </button>
                        </div>
                      </div>
                      <div v-else class="relative flex flex-col justify-center w-full mb-6">
                        <div class="flex items-center justify-between w-full mb-8">
                          <button class="flex items-center justify-center border-[0.6px] border-[#D1D2D2] text-black rounded-[6px] text-[14px] font-Rubik leading-[20px] px-[28px] py-[14px] md:max-w-[157px] w-full mr-8 outline-none focus:outline-none" @click="returnToStep2">
                            <span class="block mr-[8px] mb-[2px]">
                              <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.16406 4.99935C1.16406 4.82254 1.2343 4.65297 1.35932 4.52794L5.35933 0.527944C5.61967 0.267594 6.04178 0.267594 6.30213 0.527944C6.56248 0.788293 6.56248 1.2104 6.30213 1.47075L3.4402 4.33268L11.1641 4.33268C11.5323 4.33268 11.8307 4.63116 11.8307 4.99935C11.8307 5.36754 11.5323 5.66602 11.1641 5.66602L3.4402 5.66601L6.30213 8.52794C6.56248 8.78829 6.56248 9.2104 6.30213 9.47075C6.04178 9.7311 5.61967 9.7311 5.35932 9.47075L1.35932 5.47075C1.2343 5.34573 1.16406 5.17616 1.16406 4.99935Z" fill="#0D0D0D" stroke="black" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </span>
                            Back
                          </button>
                          <!-- <button
                            class="flex items-center justify-center bg-faintGrey text-black rounded-[6px] md:text-[16px] text-[14px] md:leading-[24px] leading-[20px] font-Rubik px-[28px] py-[14px] md:max-w-[157px] w-full outline-none focus:outline-none disabled:opacity-50"
                            @click="onSaveDraft"
                          >
                            Save draft
                          </button> -->
                        </div>
                        <button
                          @click="onSubmit"
                          disabled
                          class="flex items-center justify-center bg-black text-white rounded-[6px] text-[16px] font-Rubik px-[28px] py-[14px] outline-none focus:outline-none disabled:opacity-50"
                        >
                          <span :class="loading ? 'mr-2' : 'mr-0'" class="text-white">Submit</span>
                          <!-- <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                            </circle>
                          </svg> -->
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

</template>

<script>
import { useRoute } from 'vue-router'
import { ref, reactive, computed } from 'vue'
import useVuelidate from '@vuelidate/core'
import { helpers, required, url, minLength } from '@vuelidate/validators'
import { useStore } from 'vuex'
import Helpers from '../../utils/helpers'
// import StageThreeService from '@/services/stagethree.service'
// import store from '@/store'
// import DraftService from '@/services/draft.service'

export default {
  components: {

  },
  setup () {
    let current = 2
    const route = useRoute()
    // const router = useRouter()
    const store = useStore()
    const typeRef = ref('')
    // const fetchCompanyData = async () => {
    //   try {
    //     const id = store.state.app.company.id
    //     const response = await CompanyService.fetchSingleCompany(id)
    //     if (response.status === 200 && response.data.status === 'success') {
    //       const disbaled = response.data.data.disabled
    //       // store.dispatch('setDisabledModal', { status: disbaled.stage_three, message: 'You currently dont have permissions to view this stage. Keep checking your mailbox for an approval of your previous submission.' })
    //       return disbaled
    //     }
    //   } catch (e) {
    //     Helpers.processError(e, store, router)
    //   }
    // }

    // onBeforeRouteLeave(async (to, from) => {
    //   if (to.name === 'SignIn' || to.name === 'Home' || to.name === 'Impact Assessment' || to.name === 'SDG Alignment' || to.name === 'Financial' || to.name === 'Integrations' || to.name === 'User Details') {
    //     return true
    //   }
    //   const response = await fetchCompanyData()
    //   if (!response) {
    //     return false
    //   }
    //   const answer = Helpers.getStageByDisabledName(to.name, response)
    //   store.dispatch('setDisabledModal', { status: answer.status, message: 'You currently dont have permissions to view this stage. Keep checking your mailbox for an approval of your previous submission.' })
    //   if (answer.status) {
    //     return false
    //   }
    // })

    const schedule_meeting = ref(false)
    const enabled = ref(false)
    // const loading = ref(false)
    const calenderLoaded = ref(false)
    const windowWidth = ref(window.innerWidth)

    const loading = reactive({
      state: false,
      data: null,
      index: null
    })

    // const stageFiles = ref({
    //   contract_file_upload: null,
    //   tech_file_upload: null,
    //   roadmap_file_upload: null,
    //   demo_account_upload: null

    // })
    // const onWidthChange = () => {
    //   windowWidth.value = window.innerWidth
    // }
    // console.log(windowWidth.value)

    // onMounted(() => {
    //   window.addEventListener('resize', onWidthChange)
    // })
    // onUnmounted(() => {
    //   window.removeEventListener('resize', onWidthChange)
    // })

    const choosefile = async (event, name, category) => {
      loading.state = true
      loading.data = name
      // const company_id = store.state.app.company.id
      if (event.target.files[0]) {
        const file = event.target.files[0]
        console.log(file)
        // const formData = new FormData()
        // formData.append('company_id', company_id)
        // formData.append('document_type', category)
        // formData.append('file', file)

        // try {
        //   const response = await FileService.upload(formData)
        //   if (response.status === 200 && response.data.status === 'success') {
        //     loading.state = false
        //     loading.data = null
        //     data[name] = response.data.data.file_name
        //     stageFiles.value[name] = response.data.data.file_name
        //   }
        // } catch (error) {
        //   loading.state = false
        //   loading.data = null
        // }
      }
    }

    const removefile = (name) => {
      if (name) {
        data[name] = ''
      }
    }

    const scrollTop = () => {
      const page = document.querySelector('.scrolling')
      page.scrollTop = 0
    }

    const goToStep2 = async () => {
      const slidePage = document.querySelector('.slide-page')
      // const bullet = document.querySelectorAll('.step .bullet')
      const currentPage = document.querySelectorAll('.progress .current-progress')
      slidePage.style.marginLeft = '-33.33%'
      // bullet[current - 1].classList.add('active')
      // bullet[current - 2].classList.remove('active')
      currentPage[current - 1].classList.add('active')
      currentPage[current - 2].classList.remove('active')
      current += 1
      scrollTop()
    }

    const returnToStep1 = () => {
      const slidePage = document.querySelector('.slide-page')
      // const bullet = document.querySelectorAll('.step .bullet')
      const currentPage = document.querySelectorAll('.progress .current-progress')
      slidePage.style.marginLeft = '0%'
      // bullet[current - 3].classList.add('active')
      // bullet[current - 2].classList.remove('active')
      currentPage[current - 3].classList.add('active')
      currentPage[current - 2].classList.remove('active')
      current -= 1
      scrollTop()
    }

    const goToStep3 = async () => {
      const slidePage = document.querySelector('.slide-page')
      // const bullet = document.querySelectorAll('.step .bullet')
      const currentPage = document.querySelectorAll('.progress .current-progress')
      slidePage.style.marginLeft = '-66.66%'
      // bullet[current - 1].classList.add('active')
      // bullet[current - 2].classList.remove('active')
      currentPage[current - 1].classList.add('active')
      currentPage[current - 2].classList.remove('active')
      current += 1
      scrollTop()
    }

    const returnToStep2 = () => {
      const slidePage = document.querySelector('.slide-page')
      // const bullet = document.querySelectorAll('.step .bullet')
      const currentPage = document.querySelectorAll('.progress .current-progress')
      slidePage.style.marginLeft = '-33.33%'
      // bullet[current - 3].classList.add('active')
      // bullet[current - 2].classList.remove('active')
      currentPage[current - 3].classList.add('active')
      currentPage[current - 2].classList.remove('active')
      current -= 1
      scrollTop()
    }

    // function isCalendlyEvent (e) {
    //   return e.data.event && e.data.event.indexOf('calendly') === 0
    // }

    // const getCalendar = () => {
    //   const name = store.state.app.company.company_name
    //   const email = store.state.auth.user.email
    //   // eslint-disable-next-line no-undef
    //   Calendly.initInlineWidget({
    //     url: process.env.VUE_APP_CALENDLY_URL,
    //     parentElement: document.getElementById('calender'),
    //     prefill: {
    //       name: name,
    //       email: email
    //     },
    //     utm: {}
    //   })
    // }

    // const getDraft = async () => {
    //   const id = store.state.app.company.id
    //   try {
    //     const response = await DraftService.getDraft(id, 'STAGE 3')
    //     if (response.status === 200 && response.data.status === 'success' && response.data.data !== null) {
    //       const draft = response.data.data
    //       data.product_milestones = draft.product_milestones
    //       data.key_features = draft.key_features
    //       data.differentiation_strategy = draft.differentiation_strategy
    //       data.industry_standards = draft.industry_standards
    //       data.pricing = draft.pricing
    //       data.pricing_justification = draft.pricing_justification
    //       data.unit_cost = draft.unit_cost
    //       data.liability_risks = draft.liability_risks
    //       data.tech_stack = draft.tech_stack
    //       data.devops = draft.devops
    //       data.development_roadmap = draft.development_roadmap
    //       data.scalability = draft.scalability
    //       data.performance_metrics = draft.performance_metrics
    //       data.data_security = draft.data_security
    //       data.security_update = draft.security_update
    //       data.business_continuity = draft.business_continuity
    //       data.data_regulations = draft.data_regulations
    //     }
    //   } catch (e) {
    //     Helpers.processError(e, store, router)
    //   }
    // }
    // getDraft()

    // const saveDraftLoading = ref(false)
    // const onSaveDraft = async () => {
    //   saveDraftLoading.value = true
    //   const stagedata = {
    //     product_milestones: data.product_milestones,
    //     key_features: data.key_features,
    //     differentiation_strategy: data.differentiation_strategy,
    //     industry_standards: data.industry_standards,
    //     pricing: data.pricing,
    //     pricing_justification: data.pricing_justification,
    //     unit_cost: data.unit_cost,
    //     liability_risks: data.liability_risks,
    //     tech_stack: data.tech_stack,
    //     devops: data.devops,
    //     development_roadmap: data.development_roadmap,
    //     scalability: data.scalability,
    //     performance_metrics: data.performance_metrics,
    //     data_security: data.data_security,
    //     security_update: data.security_update,
    //     business_continuity: data.business_continuity,
    //     data_regulations: data.data_regulations
    //   }
    //   const draft = {
    //     company_id: company_id,
    //     stage: 'STAGE 3',
    //     data: stagedata
    //   }
    //   console.log(draft)
    //   // try {
    //   //   const response = await DraftService.saveDraft(draft)
    //   //   if (response.status === 200 && response.data.status === 'success') {
    //   //     const success = {
    //   //       status: true,
    //   //       message: 'Draft has been successfully saved',
    //   //       color: 'green',
    //   //       type: 'success'
    //   //     }
    //   //     store.dispatch('setAlert', success)
    //   //     setTimeout(() => {
    //   //       store.dispatch('setAlert', {
    //   //         status: false,
    //   //         message: null,
    //   //         color: null,
    //   //         type: null
    //   //       })
    //   //     }, 2000)
    //   //   }
    //   // } catch (e) {
    //   //   saveDraftLoading.value = false
    //   //   Helpers.processError(e, store, router)
    //   // }
    // }

    const data = reactive({
      bribery: 'No',
      large_contracts: 'No',
      government_contracts: 'No',
      government_permits: 'No',
      agent_dependency: 'No',
      anticorruption_policy: 'No',
      anticorruption_policy_upload: '',
      prohibit_bribery: 'No',
      no_bribery_consequences: 'No',
      anticorruption_training: 'No',
      public_anticorruption: 'No',
      anticorruption_dismissal: 'No',
      gifting_policy: '',
      corruption_updates: '',
      authorities_alert: '',
      system_audits: '',
      has_whistleblower_policy: 'No',
      whistleblower_policy_upload: '',
      whistleblower_draft_date: '',
      annual_corruption_report: 'No',
      report_transparency: 'No',
      whistleblower_training: 'No',
      last_whistleblower_training_date: '',
      public_whistleblowing: 'No',
      employment_whistleblowing: 'No',
      regular_whistleblower_audits: 'No',
      corporate_governance_dedication: 'No',
      has_codeof_corporate_governance: 'No',
      has_codeof_ethics: 'No',
      has_secretary: 'No',
      has_boardcomittees: 'No'
    })

    const rules = computed(() => ({
      product_milestones: {
        $each: helpers.forEach({
          milestone: { required, minLengthValue: minLength(3) },
          date_time: { required }
        })
      },
      key_features: { required },
      differentiation_strategy: { required, minLengthValue: minLength(3) },
      industry_standards: { required, minLengthValue: minLength(3) },
      pricing: { required, minLengthValue: minLength(3) },
      pricing_justification: { required, minLengthValue: minLength(3) },
      unit_cost: { required, minLengthValue: minLength(3) },
      liability_risks: { required, minLengthValue: minLength(3) },

      tech_stack: {
        $each: helpers.forEach({
          data: { required, minLengthValue: minLength(3) }
        })
      },
      devops: { required, minLengthValue: minLength(3) },
      development_roadmap: { required, minLengthValue: minLength(3) },
      scalability: { required, minLengthValue: minLength(3) },
      performance_metrics: {
        $each: helpers.forEach({
          data: { required, minLengthValue: minLength(3) }
        })
      },
      data_security: { required, minLengthValue: minLength(3) },
      security_update: { required, minLengthValue: minLength(3) },
      business_continuity: { required, minLengthValue: minLength(3) }
    }))

    const v$ = useVuelidate(rules, data)

    const addNewField = (arr) => {
      arr.push(
        {
          milestone: '',
          date_time: ''
        }
      )
    }
    const addNewString = (arr) => {
      arr.push(typeRef.value)
    }
    const addDataField = (arr) => {
      arr.push({
        data: ''
      })
    }
    const removeField = (arr, index) => {
      arr.splice(index, 1)
    }

    // const checkValidForm = (v$, screen) => {
    //   switch (screen) {
    //     case 'Anti-corruption management':
    //       if (v$.product_milestones.$invalid ||
    //       v$.differentiation_strategy.$invalid ||
    //       v$.industry_standards.$invalid ||
    //       v$.pricing.$invalid ||
    //       v$.pricing_justification.$invalid ||
    //       v$.unit_cost.$invalid ||
    //       v$.liability_risks.$invalid) {
    //         return true
    //       }
    //       break
    //     case 'Technology':
    //       if (v$.tech_stack.$invalid ||
    //       v$.devops.$invalid ||
    //       v$.development_roadmap.$invalid ||
    //       v$.scalability.$invalid ||
    //       v$.performance_metrics.$invalid ||
    //       v$.data_security.$invalid ||
    //       v$.security_update.$invalid) {
    //         return true
    //       }
    //       break
    //     default:
    //       return false
    //   }
    // }

    const user_id = store.state.auth.user.id
    const company_id = store.state.app.company.id
    const onSubmit = async () => {
      // const techStack = Helpers.changeToArray(data.tech_stack, 'data')
      // const performanceMetrics = Helpers.changeToArray(data.performance_metrics, 'data')
      loading.state = true
      const stagedata = {
        user_id: user_id,
        company_id: company_id,
        product_milestones: data.product_milestones,
        differentiation_strategy: data.differentiation_strategy,
        industry_standards: data.industry_standards,
        pricing: data.pricing,
        pricing_justification: data.pricing_justification,
        unit_cost: data.unit_cost,
        liability_risk: data.liability_risks,
        // tech_stack: techStack,
        devops: data.devops,
        development_roadmap: data.development_roadmap,
        scalability: data.scalability,
        // performance_metrics: performanceMetrics,
        data_security: data.data_security,
        security_update: data.security_update,
        business_continuity: Helpers.changeEmptyStringtoNull(data.business_continuity),
        data_regulations: Helpers.changeEmptyStringtoNull(data.data_regulations)
      }
      console.log(stagedata)
      // try {
      //   const response = await StageThreeService.createStageThree(stagedata)
      //   if (response.status === 200 && response.data.status === 'success') {
      //     loading.state = false
      //     const success = {
      //       status: true,
      //       message: 'Your data has been submitted successfully. We will review and reach out to you within the next 24 to 48 hours. '
      //     }
      //     store.commit('setSuccess', success)

      //     store.commit('setCompany', response.data.data.company)
      //     store.commit('setProgress', response.data.data.progress)
      //   }
      // } catch (e) {
      //   loading.state = false
      //   Helpers.processError(e, store, router)
      // }
    }

    // onMounted(() => {
    //   window.addEventListener(
    //     'message',
    //     function (e) {
    //       if (isCalendlyEvent(e)) {
    //         if (e.data.event === 'calendly.event_scheduled') {
    //           schedule_meeting.value = true
    //           const calendar = document.getElementById('calender')
    //           calendar.style.display = 'none'
    //         } else if (e.data.event === 'calendly.event_type_viewed') {
    //           calenderLoaded.value = true
    //         } else {
    //           calenderLoaded.value = false
    //           schedule_meeting.value = false
    //         }
    //       }
    //     }
    //   )
    // })

    // watch(
    //   () => enabled.value,
    //   (value) => {
    //     if (value === true) {
    //       getCalendar()
    //     }
    //   }
    // )

    return {
      route,
      progress: computed(() => store.state.app.progress_status),
      goToStep2,
      returnToStep1,
      goToStep3,
      returnToStep2,
      enabled,
      v$,
      url,
      data,
      // checkValidForm,
      addNewField,
      addNewString,
      addDataField,
      removeField,
      onSubmit,
      loading,
      // getCalendar,
      schedule_meeting,
      calenderLoaded,
      windowWidth,
      // onSaveDraft,
      choosefile,
      removefile
    }
  }
}
</script>

<style scoped>
.contain .form-outer{
  width: 100%;
  overflow: hidden;
}
.contain .form-outer .forms{
  display: flex;
  width: 300%;
}
.form-outer .forms .page{
  width: 33.33%;
  transition: margin-left 0.3s ease-in-out;
}
.contain .progress-bar{
  display: flex;
  justify-content: space-between;
  margin: 10px 5px 60px 5px;
  user-select: none;
}
.contain .progress-bar .step{
  text-align: center;
  position: relative;
}

.progress-bar .step .bullet{
  height: 16px;
  width: 16px;
  background-color: rgba(229, 230, 230, 1);
  display: flex;
  border-radius: 50%;
  position: relative;
  transition: 0.2s;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  z-index: 100;
  justify-content: center;
  align-items: center;
}

.progress-bar .step .bullet.active{
  background-color: #000000;
  height: 20px;
  width: 20px;
}
.progress-bar .step .bullet.active + p{
  color: #000000;
}
.progress-bar .step .bullet.active::after {
  content: "";
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: solid 1px white;
  border-radius: 50%;
  position: absolute;
}
.progress .current-progress{
  display: none;
}
.progress .current-progress.active{
  display: flex;
}
</style>
