/**
 * @class HitDetector
 *
 */
export default class HitDetector {
  constructor () {
    this.hits = []
    this.scrolls = []
    this.contextMenus = []
    this.defaultActions = null
    this.buttonDown = false
    this.mdX = this.mdY = null
    this.ctx = null
    this.dragging = null
    this.dataPending = false
    this.hoverCursor = null
  }

  clear () {
    this.hits = null
    this.scrolls = null
    this.defaultActions = null
    this.ctx = null
    this.dragging = null
    this.dataPending = false
  }

  beginDrawing (ctx) {
    this.ctx = ctx
    this.hits.length = 0
    this.scrolls.length = 0
    this.contextMenus.length = 0
    this.defaultActions = null
    this.wantsKeys = false
    this.dataPending = false
    this.wantsContextMenu = false

    this.hoverCursor = null
    this.hoverPriority = 1e9
    this.hoverAction = null
  }

  endDrawing (ctx) {
    this.ctx = null
  }

  mouseIn (t, r, b, l) {
    if (this.mdX === null || this.mdY === null) return false
    return this.mdX >= l && this.mdX <= r && this.mdY >= t && this.mdY <= b
  }

  add (t, r, b, l, actions) {
    if (!(l <= r && t <= b)) {
      throw new Error(`HitDetector region (${t},${r},${b},${l}) invalid`)
    }
    const inside = this.mouseIn(t, r, b, l)
    const priority = (b - t) * (r - l) * (actions.priorityFactor || 1)
    if (actions.onClick || actions.onDown || actions.onUp) {
      this.hits.push({ t, r, b, l, actions, priority })
    }
    if (actions.onContextMenu) {
      this.contextMenus.push({ t, r, b, l, actions, priority })
      this.wantsContextMenu = true
    }
    if (actions.onScroll) {
      this.scrolls.push({ t, r, b, l, actions, priority })
    }
    if (actions.draw || actions.drawDown) {
      this.ctx.save()
      const down = this.buttonDown && inside
      if (!down) this.ctx.globalAlpha = 0.75
      if (actions.draw) actions.draw()
      if (down && actions.drawDown) actions.drawDown()
      this.ctx.restore()
    } else if (actions.drawCustom) {
      actions.drawCustom(this.buttonDown && inside)
    }
    if (inside) {
      if (actions.onHover && !this.dragging) {
        if (priority < this.hoverPriority) {
          this.hoverPriority = priority
          this.hoverAction = actions.onHover
        }
      }
      if (actions.onHoverDrag) {
        if (priority < this.hoverPriority) {
          this.hoverPriority = priority
          this.hoverAction = actions.onHoverDrag
        }
      }
    }
  }

  addDefault (actions) {
    this.defaultActions = actions
  }

  find (x, y) {
    const hits = this.hits
    const hitsLen = hits.length
    let bestPriority = 1e9
    let bestActions = null
    for (let i = 0; i < hitsLen; i++) {
      const hit = hits[i]
      if (x >= hit.l && x <= hit.r && y >= hit.t && y <= hit.b) {
        if (hit.priority < bestPriority) {
          bestPriority = hit.priority
          bestActions = hit.actions
        }
      }
    }
    return bestActions
  }

  findLoose (x, y) {
    const hits = this.hits
    const hitsLen = hits.length
    let bestPriority = 1e9
    let bestActions = null
    for (let i = 0; i < hitsLen; i++) {
      const hit = hits[i]
      const miss = 0.1 * Math.max(
        Math.max(
          Math.max(hit.l - x, 0),
          Math.max(x - hit.r, 0)),
        Math.max(
          Math.max(hit.t - y, 0),
          Math.max(y - hit.b, 0)))
      if (miss < 10) {
        if (hit.priority + miss < bestPriority) {
          bestPriority = hit.priority + miss
          bestActions = hit.actions
        }
      }
    }
    return bestActions
  }

  findContextMenus (x, y) {
    const hits = this.contextMenus
    // const hitsLen = hits.length
    const all = []
    for (const hit of hits) {
      if (x >= hit.l && x <= hit.r && y >= hit.t && y <= hit.b) {
        all.push(hit)
      }
    }

    return all.sort((a, b) => a.priority - b.priority).map((a) => a.actions)
  }

  findScroll (x, y) {
    const scrolls = this.scrolls
    const scrollsLen = scrolls.length
    let bestPriority = 1e9
    let bestActions = null
    for (let i = 0; i < scrollsLen; i++) {
      const scroll = scrolls[i]
      if (x >= scroll.l && x <= scroll.r && y >= scroll.t && y <= scroll.b) {
        if (scroll.priority < bestPriority) {
          bestPriority = scroll.priority
          bestActions = scroll.actions
        }
      }
    }
    return bestActions
  }
}
