<template>
  <div id="integrations" class="mb-12">
    <div class="flex justify-between">
      <h1 class="font-Playfair text-[32px] leading-[42.66px]">Connect Your Data</h1>
    </div>
    <h1 class="font-Rubik text-[16px] leading-[24px] font-light mt-6 text-greyText">
      As part of your onboarding process on the Kinfolk platform,
      we need to collect some information about your company’s financials. Kinfolk will never share your data with a third paty.
    </h1>
    <div>
      <!-- <Suspense>
        <OkraIntegration/>
      </Suspense> -->
      <Suspense>
        <PlaidIntegration/>
      </Suspense>
    </div>
    <div class="font-Rubik text-sm font-light mt-6 text-greyText">
      <p>Don’t see your bank?</p>
      <p>Call us at <span class="text-black">+234 803 789 8332</span> or you can send us an email at <span class="text-black">support@kinfolk.com</span></p>
    </div>
    <div class="mx-auto w-full rounded-lg bg-white mt-14">
      <ConnectedIntegration />
      <!-- <Disclosure v-slot="{ open }">
        <DisclosureButton
          class="flex w-full justify-between bg-faintGrey  px-4 py-4 text-left focus:outline-none"
          :class="open ? 'border-b' : ''"
        >
          <div class="flex">
            <span class="mr-2">Connected Data Sources</span>
            <div class="flex border rounded-full h-6 w-6 justify-center items-center">3</div>
          </div>
          <ChevronUpIcon
            :class="open ? 'rotate-180 transform' : ''"
            class="h-5 w-5 text-black"
          />
        </DisclosureButton>
        <DisclosurePanel class="px-4 pt-4 pb-4 text-sm text-gray-500 bg-faintGrey">
        </DisclosurePanel>
      </Disclosure> -->
    </div>
  </div>
</template>

<script>
// import { watch } from 'vue'
// import { useRoute } from 'vue-router'
import PlaidIntegration from '@/components/integrations/PlaidIntegration.vue'
// import OkraIntegration from '@/components/integrations/OkraIntegration.vue'
// import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
// import { ChevronUpIcon } from '@heroicons/vue/solid'
import ConnectedIntegration from '@/components/integrations/ConnectedIntegration.vue'
export default {
  components: {
    PlaidIntegration,
    // Disclosure,
    // DisclosureButton,
    // DisclosurePanel,
    // ChevronUpIcon,
    ConnectedIntegration
    // OkraIntegration
  },
  setup () {
    // const apiUrl = process.env.VUE_APP_API_URL
    // const appToken = localStorage.getItem('app-token')
    // const createLinkToken = async () => {
    //   const res = await fetch(`${apiUrl}/api/v1/app/plaid/create-link-token`, {
    //     method: 'POST',
    //     headers: {
    //       Authorization: 'Bearer' + ' ' + appToken
    //     }
    //   })
    //   const data = await res.json()
    //   const linkToken = data.data.link_token
    //   localStorage.setItem('link_token', linkToken)
    //   return linkToken
    // }
    // createLinkToken()
  }
}
</script>

<style lang="scss" scoped></style>
