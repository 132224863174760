class Helpers {
  static extractAddress (place) {
    const address = {
      city: '',
      state: '',
      country: '',
      code: ''
    }

    if (!Array.isArray(place?.address_components)) {
      return address
    }

    place.address_components.forEach(component => {
      const types = component.types
      const value = component.long_name
      const code = component.short_name

      if (types.includes('locality')) {
        address.city = value
      }

      if (types.includes('administrative_area_level_2')) {
        address.state = value
      }

      // if (types.includes("postal_code")) {
      //   address.zip = value;
      // }

      if (types.includes('country')) {
        address.country = value
        address.code = code
      }
    })

    const formatted_address = place.formatted_address ?? place.name
    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()
    const coordinates = {
      type: 'Point',
      coordinates: [lat, lng]
    }

    return {
      address,
      formatted_address,
      coordinates
    }
  }

  static numberWithCommas (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  static returnEmptyArrayOrData (res) {
    if (!res) {
      return []
    }
    return res.leadership
  }

  static changeToArray = (array, key) => {
    const newArray = array.map(item => {
      return item[key]
    })
    return newArray
  }

  static changeNoInputToEmptyArray = (array, key) => {
    let newArray = array.map(item => {
      return item[key]
    })
    if (newArray.length < 1 && newArray[0] === '') {
      newArray = []
    }
    return newArray
  }

  static changeEmptyStringtoNull = (str) => {
    if (str.length < 1) {
      return null
    }
    return str
  }

  static checkError = (status, store, router) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('link_token')
      localStorage.removeItem('app-token')
      store.dispatch('logoutUser')
      store.dispatch('resetApp')
      router.push('/sign-in')
    }
  }

  static processError = (e, store, router) => {
    if (e.response) {
      if (e.response.status === 401) {
        Helpers.checkError(e.response.status, store, router)
      }
      const errors = {
        status: true,
        message: e.response.data.message,
        color: 'red',
        type: 'error'
      }
      store.dispatch('setAlert', errors)
      setTimeout(() => {
        store.dispatch('setAlert', {
          status: false,
          message: null,
          color: null,
          type: null
        })
      }, 2000)
    } else if (e.message === 'Network Error') {
      const errors = {
        status: true,
        message: 'Network Error check your internet connection',
        color: 'red',
        type: 'error'
      }
      store.dispatch('setAlert', errors)
      setTimeout(() => {
        store.dispatch('setAlert', {
          status: false,
          message: null,
          color: null,
          type: null
        })
      }, 3000)
    }
  }

  static getCurrentStageName (stage) {
    let name
    switch (stage) {
      case 'ONBOARDING':
        name = 'Onboarding'
        break
      case 'STAGE 1':
        name = 'Founder Journey'
        break
      case 'STAGE 2':
        name = 'Data Request 1'
        break
      case 'STAGE 3':
        name = 'Product & Technology'
        break
      case 'STAGE 4':
        name = 'Data Request 2'
        break
      case 'STAGE 5':
        name = 'Unit Economics'
        break
      case 'STAGE 6':
        name = 'Data Request 3'
        break
      case 'STAGE 7':
        name = 'Fundraising'
        break
      case 'STAGE 8':
        name = 'Data Request 4'
        break
      case 'STAGE 9':
        name = 'Survey'
        break
      default:
        name = 'Onboarding'
        break
    }
    return name
  }

  static getCurrentStageRoute (stage) {
    let name
    switch (stage) {
      case 'ONBOARDING':
        name = 'onboarding'
        break
      case 'STAGE 1':
        name = 'founder-journey'
        break
      case 'STAGE 2':
        name = 'data-request-one'
        break
      case 'STAGE 3':
        name = 'product-tech'
        break
      case 'STAGE 4':
        name = 'data-request-two'
        break
      case 'STAGE 5':
        name = 'unit-economics'
        break
      case 'STAGE 6':
        name = 'data-request-three'
        break
      case 'STAGE 7':
        name = 'fundraising'
        break
      case 'STAGE 8':
        name = 'data-request-four'
        break
      case 'STAGE 9':
        name = 'survey'
        break
      default:
        name = 'onboarding'
        break
    }
    return name
  }

  static getStageByDisabledName (to, disabled_status) {
    let name
    let status
    switch (to) {
      case 'ONBOARDING':
        name = 'ONBOARDING'
        status = disabled_status.onboarding
        break
      case 'STAGE 1':
        name = 'STAGE 1'
        status = disabled_status.stage_one
        break
      case 'STAGE 2':
        name = 'STAGE 2'
        status = disabled_status.stage_two
        break
      case 'STAGE 3':
        name = 'STAGE 3'
        status = disabled_status.stage_three
        break
      case 'STAGE 4':
        name = 'STAGE 4'
        status = disabled_status.stage_four
        break
      case 'STAGE 5':
        name = 'STAGE 5'
        status = disabled_status.stage_five
        break
      case 'STAGE 6':
        name = 'STAGE 6'
        status = disabled_status.stage_six
        break
      case 'STAGE 7':
        name = 'STAGE 7'
        status = disabled_status.stage_seven
        break
      case 'STAGE 8':
        name = 'STAGE 8'
        status = disabled_status.stage_eight
        break
      case 'STAGE 9':
        name = 'STAGE 9'
        status = disabled_status.feedback_survey
        break
      default:
        name = 'ONBOARDING'
        status = true
        break
    }
    return {
      name,
      status
    }
  }
}

export default Helpers
