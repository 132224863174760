<template>
  <transition name="fadeInOut">
    <div
      v-show="modalActive"
      class="
        px-5
        modal
        bg-originsOverlay
        absolute
        h-screen
        w-screen
        left-0
        top-0
        z-[2000]
        flex
        items-center
        justify-center
      "
    >
      <!-- Modal Content -->
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: ['modalActive'],
  setup () {
    return {}
  }
}
</script>

<style scoped>
.fadeInOut-enter-from {
  opacity: 0;
}
.fadeInOut-enter-to {
  opacity: 1;
}
.fadeInOut-enter-active {
  transition: all .4s ease;
}
.fadeInOut-leave-from {
  opacity: 1;
}
.fadeInOut-leave-to {
  opacity: 0;
}
</style>
