<template>
  <div>
    <Disclosure v-slot="{ open }">
      <DisclosureButton class="flex w-full justify-between bg-faintGrey  px-4 py-4 text-left focus:outline-none"
        :class="open ? 'border-b' : ''">
        <div class="flex">
          <span class="mr-2">Connected Data Sources</span>
          <div class="flex border rounded-full h-6 w-6 justify-center items-center">{{ accounts.length}}</div>
        </div>
        <ChevronUpIcon :class="open ? 'rotate-180 transform' : ''" class="h-5 w-5 text-black" />
      </DisclosureButton>
      <DisclosurePanel class="px-4 pt-4 pb-4 text-sm text-gray-500 bg-faintGrey">
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 gap-8 text-black">
          <div v-for="(account, i) in accounts" :key=i class="relative flex items-center space-x-3 px-6 py-5">
            <div v-if="account.provider === 'mono'">
              <div class="flex-shrink-0">
                <img class="h-14 w-14 rounded-full" :src="getInstitution(account.institution_code).icon" alt="" />
              </div>
              <div class="min-w-0 flex-1">
                <p class="truncate text-[15px] leading-[17.78px]">{{ getInstitution(account.institution_code).name }} </p>
                <span class="inline-flex items-center rounded-full bg-[#DBF0DB] px-2.5 py-0.5 text-xs font-medium text-[#359735]">
                  Synced
                </span>
                <p>Account ending in {{ account.account_ending_with }}</p>
              </div>
            </div>
            <div v-if="account.provider === 'plaid'">
              <div class="flex-shrink-0">
                <img class="h-14 w-14 rounded-full" :src="'data:image/png;base64,' + `${getPlaidInstitution(account.institution_code)?.logo}`" alt="" />
              </div>
              <div class="min-w-0 flex-1">
                <p class="truncate text-[15px] leading-[17.78px]">{{ getPlaidInstitution(account.institution_code)?.name }} </p>
                <span class="inline-flex items-center rounded-full bg-[#DBF0DB] px-2.5 py-0.5 text-xs font-medium text-[#359735]">
                  Synced
                </span>
                <p>Account ending in {{ account.account_ending_with }}</p>
              </div>
            </div>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronUpIcon } from '@heroicons/vue/solid'
import Helpers from '@/utils/helpers'
import FinancialService from '@/services/financials.service'
import PlaidService from '@/services/plaid.service'
export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronUpIcon
    // OkraIntegration
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const accounts = ref([])
    const plaidInstitution = ref([])
    const getConnectedAccounts = async () => {
      try {
        const response = await FinancialService.getConnectedAccounts()
        if (response.status === 200 && response.data.status === 'success') {
          console.log(response.data.data)
          accounts.value = response.data.data
          fetchPlaidInstitution()
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }
    getConnectedAccounts()

    const institutions = computed(() => store.state.app.institutions)

    const getInstitution = (bank_code) => {
      return institutions.value.find(institution => institution.bank_code === bank_code)
    }

    const fetchPlaidInstitution = async () => {
      const plaidaccounts = accounts.value.filter(account => account.provider === 'plaid')
      // console.log(plaidaccounts)
      try {
        const result = await Promise.all(
          plaidaccounts.map(async (account) => {
            const response = await PlaidService.getPlaidInstitution(account.institution_code)
            // console.log(response.data.data)
            return response.data.data.institution
          })
        )
        console.log(result)
        plaidInstitution.value = result
        // const response = await PlaidService.getConnectedAccounts(inst)
        // if (response.status === 200 && response.data.status === 'success') {
        //   console.log(response.data.data)
        //   accounts.value = response.data.data
        // }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }

    const getPlaidInstitution = (bank_code) => {
      return plaidInstitution.value.find(institution => institution.institution_id === bank_code)
    }

    return {
      getInstitution,
      accounts,
      getPlaidInstitution
    }
  }
}
</script>
