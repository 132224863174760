import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`

class TeamService {
  static fetchCompanyUsers () {
    return axios.get(`${url}/app/user`)
  }

  static createNewUser (data) {
    return axios.post(`${url}/app/user`, data)
  }

  static deleteCreatedUser (userId) {
    return axios.delete(`${url}/app/user/${userId}`)
  }

  static updateCreatedUser (userId, data) {
    return axios.put(`${url}/app/user/${userId}`, data)
  }
}

export default TeamService
