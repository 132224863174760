import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class CompanyService {
  static createCompany (data) {
    return axios.post(`${url}/app/company/create`, data)
  }

  static fetchSingleCompany (id) {
    return axios.get(`${url}/app/company/fetch/${id}`)
  }
}

export default CompanyService
