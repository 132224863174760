<template>
  <main class="home mt-12">
    <div class="flex justify-between">
      <h1 class="font-Playfair md:text-[32px] text-[24px] md:leading-[42.66px] leading-[31.99px]">Dashboard</h1>
      <button class="flex items-center border border-borderBlack rounded text-xs leading-[15.41px] font-medium font-Rubik md:px-5 md:py-3 px-[10px] py-[6px]" @click="gotoDiligence">
        <svg
          class="h-4 w-4 text-black"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 12h6m-6 4h6m2 5H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5.586a1 1 0 0 1 .707.293l5.414 5.414a1 1 0 0 1 .293.707V19a2 2 0 0 1-2 2z"/>
        </svg>
        <span v-if="progress && progress.onboarding === 'New'" class="ml-2">Start Application</span>
        <span v-else class="ml-2">Continue Application</span>
      </button>
    </div>
    <div class="bg-black h-[120px] mt-10 flex justify-between items-center md:px-12 px-[24px] rounded">
      <div class="left flex items-center">
        <div class="flex justify-center items-center rounded-full bg-white h-[72px] w-[72px] md:mr-8 mr-4">
          <svg
            class="h-10 w-10 text-black"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6zM4 16a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2z"/>
          </svg>
        </div>
      </div>
      <div class="right w-full flex lg:flex-row flex-col lg:items-center lg:justify-between items-start">
        <h1 class="font-Rubik md:text-[24px] text-[20px] leading-[28.44px] text-white tracking-[-2%] lg:mb-0 mb-[6px]">Application Status</h1>
        <div v-if="company && company.stage_status === 'Processing'" class="text-center text-originsYellow border text-[15px] leading-[17.78px] font-medium border-originsYellow rounded-sm md:py-[14px] py-[8px] md:px-[16px] px-[30px] min-w-[108px] max-w-[141px]">
          {{ company.stage_status}}
        </div>
        <div v-if="company && company.stage_status === 'Approved'" class="text-center text-originsGreen border text-[15px] leading-[17.78px] font-medium border-originsGreen rounded-sm md:py-[14px] py-[8px] md:px-[16px] px-[30px] min-w-[108px] max-w-[141px]">
          {{ company.stage_status}}
        </div>
        <div v-if="company && company.stage_status === 'Rejected'" class="text-center text-originsRed border text-[15px] leading-[17.78px] font-medium border-originsRed rounded-sm md:py-[14px] py-[8px] md:px-[16px] px-[30px] min-w-[108px] max-w-[141px]">
          {{ company.stage_status}}
        </div>
        <div v-if="!company" class="text-center text-blue-600 border text-[15px] leading-[17.78px] font-medium border-blue-600 rounded-sm md:py-[14px] py-[8px] md:px-[16px] px-[30px] min-w-[108px] max-w-[141px]">
          New
        </div>
      </div>
    </div>
    <div class="grid lg:grid-cols-3 grid-cols-1 gap-5 mt-10">
      <div class="flex flex-row border rounded justify-between px-4 items-center border-gray-200 h-[116px] w-full  mb-2 xl:mb-0">
        <div class="flex flex-col">
          <p class="text-greyText text-[14px] leading-[16.59px]">Funding<br> Amount</p>
          <span v-if="!company" class="text-[22px] leading-[28.44px] mt-[6px]">$0 - $0</span>
          <span v-if="company" class="text-[22px] leading-[28.44px] mt-[6px]">$0 - {{ Helpers.numberWithCommas(company.max_amount)}}</span>
        </div>
        <svg
          class="h-6 w-6 text-black"
          viewBox="0 0 24 24"
          fill="none">
          <path d="M23.0105 11.1886H21.8431C21.6819 8.80965 20.6783 6.59705 18.9753 4.89405C17.2723 3.19105 15.0597 2.18745 12.6807 2.02625V0.861449C12.6833 0.481849 12.3765 0.175049 11.9969 0.175049C11.6173 0.175049 11.3105 0.481849 11.3105 0.861449V2.02885C8.93147 2.19005 6.71887 3.19365 5.01587 4.89665C3.31287 6.59965 2.30927 8.81225 2.14807 11.1912H0.983275C0.603675 11.1886 0.296875 11.4954 0.296875 11.875C0.296875 12.2546 0.603675 12.5614 0.983275 12.5614H2.15067C2.31187 14.9404 3.31547 17.153 5.01847 18.856C6.72147 20.559 8.93407 21.5626 11.3131 21.7238V22.8912C11.3131 23.2708 11.6199 23.5776 11.9995 23.5776C12.3791 23.5776 12.6859 23.2708 12.6859 22.8912V21.7238C15.0649 21.5626 17.2775 20.559 18.9805 18.856C20.6835 17.153 21.6871 14.9404 21.8483 12.5614H23.0157C23.3953 12.5614 23.7021 12.2546 23.7021 11.875C23.7021 11.4954 23.3901 11.1886 23.0105 11.1886ZM12.6833 20.3484V19.233C12.6833 18.8534 12.3765 18.5466 11.9969 18.5466C11.6173 18.5466 11.3105 18.8534 11.3105 19.233V20.3484C7.16867 20.0156 3.85627 16.7032 3.52347 12.5614H4.63888C5.01848 12.5614 5.32527 12.2546 5.32527 11.875C5.32527 11.4954 5.01848 11.1886 4.63888 11.1886H3.52347C3.85627 7.04685 7.16867 3.73445 11.3105 3.40165V4.51705C11.3105 4.89665 11.6173 5.20345 11.9969 5.20345C12.3765 5.20345 12.6833 4.89665 12.6833 4.51705V3.40165C16.8251 3.73445 20.1375 7.04685 20.4703 11.1886H19.3549C18.9753 11.1886 18.6685 11.4954 18.6685 11.875C18.6685 12.2546 18.9753 12.5614 19.3549 12.5614H20.4703C20.1375 16.7032 16.8251 20.0156 12.6833 20.3484Z" fill="currentColor"/>
          <path d="M11.9991 13.2453C12.7558 13.2453 13.3693 12.6318 13.3693 11.8751C13.3693 11.1183 12.7558 10.5049 11.9991 10.5049C11.2424 10.5049 10.6289 11.1183 10.6289 11.8751C10.6289 12.6318 11.2424 13.2453 11.9991 13.2453Z" fill="currentColor"/>
        </svg>
      </div>
      <div class="flex flex-row border rounded justify-between px-4 items-center border-gray-200 h-[116px] w-full  mb-2 xl:mb-0">
        <div class="flex flex-col">
          <p class="text-greyText text-[14px] leading-[16.59px]">Estimated<br>Response Time</p>
          <span class="text-[22px] leading-[28.44px] mt-[6px]">24 - 48 hrs</span>
        </div>
        <svg
          class="h-6 w-6 text-black"
          fill="none"
          viewBox="0 0 24 24"
          >
          <path
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M23.0984 12.0006c0 6.1308-4.9692 11.1-11.1 11.1-6.13076 0-11.09996-4.9692-11.09996-11.1C.89844 5.86977 5.86764.90057 11.9984.90057c6.1308 0 11.1 4.9692 11.1 11.10003Z" clip-rule="evenodd"/>
          <path
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="m16.1138 15.5314-4.524-2.6988V7.01624"/>
        </svg>
      </div>
      <div class="flex flex-row border rounded justify-between px-4 items-center border-gray-200 h-[116px] w-full  mb-2 xl:mb-0">
        <div class="flex flex-col">
          <p class="text-greyText text-[14px] leading-[16.59px]">Current Diligence<br> Process Stage</p>
          <span v-if="company" class="text-[22px] leading-[28.44px] mt-[6px]">{{ Helpers.getCurrentStageName(company.current_stage) }}</span>
          <span v-if="!company" class="text-[22px] leading-[28.44px] mt-[6px]">ONBOARDING</span>
        </div>
        <svg
          class="h-6 w-6 text-black"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path d="M23 6L13.5 15.5L8.5 10.5L1 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M17 6H23V12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div class="flex mt-8 min-h-[176px] max-w-[100%] rounded bg-coolGray-100 p-6 mb-8">
      <div class="flex flex-row">
        <svg
          class="h-4 w-4 text-black"
          fill="none"
          viewBox="0 0 24 24"
          >
          <path
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M23.0984 12.0006c0 6.1308-4.9692 11.1-11.1 11.1-6.13076 0-11.09996-4.9692-11.09996-11.1C.89844 5.86977 5.86764.90057 11.9984.90057c6.1308 0 11.1 4.9692 11.1 11.10003Z" clip-rule="evenodd"/>
          <path
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="m16.1138 15.5314-4.524-2.6988V7.01624"/>
        </svg>
        <div>
          <h3 class="ml-1 font-Rubik text-[16px] leading-[18.96px] font-medium">What To Expect Next</h3>
          <p class="ml-1 mt-2 font-Rubik text-[15px] leading-[24px] font-light">Thank you for completing the onboarding process, here’s what to expect next:</p>
          <span class="ml-2 flex items-center mt-2">
            <svg class="text-black h-1 w-1" viewBox="0 0 6 6" fill="currentColor">
              <circle cx="3" cy="3" r="3"/>
            </svg>
            <span class="font-Rubik text-[15px] leading-[24px] font-light ml-[10px]">A 30 to 45mins conversation with a Kinfolk personnel.</span>
          </span>
          <span class="ml-2 flex items-center mt-2">
            <svg class="text-black h-1 w-1" viewBox="0 0 6 6" fill="currentColor">
              <circle cx="3" cy="3" r="3"/>
            </svg>
            <span class="font-Rubik text-[15px] leading-[24px] font-light ml-[10px]">A thorough discussion about the information provided in the previous stage of the diligence process.</span>
          </span>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Helpers from '@/utils/helpers'
import CompanyService from '@/services/company.service'
export default {
  name: 'Home',
  components: {
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const fetchCompanyData = async () => {
      try {
        const id = store.state.app.company.id
        const response = await CompanyService.fetchSingleCompany(id)
        if (response.status === 200 && response.data.status === 'success') {
          store.dispatch('setCompany', response.data.data.company)
          store.dispatch('setProgress', response.data.data.progress)
          store.dispatch('setDisabled', response.data.data.disabled)
          store.dispatch('setCurrentStage', response.data.data.company.current_stage)
          store.dispatch('setNextStage', response.data.data.company.next_stage)
        }
      } catch (e) {
        Helpers.processError(e, store, router)
      }
    }
    fetchCompanyData()
    const gotoDiligence = () => {
      let stage

      if (store.state.app.company) {
        if (store.state.app.company.stage_status === 'Approved') {
          console.log(true)
          stage = store.state.app.company.next_stage
        } else {
          stage = store.state.app.company.current_stage
        }
      } else {
        stage = 'ONBOARDING'
      }

      router.push({ name: stage })
    }
    return {
      company: computed(() => store.state.app.company),
      progress: computed(() => store.state.app.progress_status),
      gotoDiligence,
      Helpers
    }
  }
}
</script>
