<template>
  <div class="h-screen min-h-screen overflow-y-auto lg:overflow-hidden overflow-x-hidden bg-black relative">
    <div class="max-w-[1200px] w-full mx-auto h-full">
      <div class="circle-sprite max-w-[736px] w-full max-h-[736px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-[1px] border-opacity-25 rounded-full top-[-530px] right-[-110px] lg:right-[100px] md:top-[-450px] opacity-1 z-10"></div>
      <div class="square-sprite lg:max-w-[470px] max-w-[350px] w-full lg:max-h-[470px] max-h-[350px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-[1px] border-opacity-25 transform rotate-[55deg] left-[300px] lg:bottom-[-350px] bottom-[-120px] opacity-1 z-10"></div>
      <div class="flex lg:flex-row flex-col items-center justify-center lg:h-full h-[max-content] px-[16px] lg:px-0">
        <div class="lg:w-[50%] w-full h-full flex flex-col justify-center lg:items-start items-center lg:pl-[25px] pl-auto">
          <transition name="fade">
            <template v-if="toggleHowTo === false">
              <div class="flex flex-col lg:items-start items-center justify-center lg:max-w-[310px] max-w-[350px] mx-auto lg:ml-0 lg:my-auto my-[80px]">
                <div class="mb-[28px]">
                  <img src="../assets/KFLogo.svg" class="lg:h-[108px] h-[80px] lg:w-[123px] w-[80px]" />
                </div>
                <h1 class="text-sm text-white text-[28px] font-Playfair font-normal mt-3">Kinfolk Venture Capital</h1>
                <p class="text-greyText text-[16px] lg:text-left text-center leading-[18.96px] font-normal mt-3">The Fundraising Process for Africa’s Next-Gen Startups—Fully Digitized</p>
                <button @click="handleHowTo" class="flex items-center font-Rubik font-medium text-white text-[14px] leading-[16.59px] my-[64px] border-greyText border-b-[1px] pb-[16px]">
                  How Does It Work?
                  <div class="arrow-right ml-[6px] mb-[3px]">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.9987 15.1654C11.6806 15.1654 14.6654 12.1806 14.6654 8.4987C14.6654 4.8168 11.6806 1.83203 7.9987 1.83203C4.3168 1.83203 1.33203 4.8168 1.33203 8.4987C1.33203 12.1806 4.3168 15.1654 7.9987 15.1654Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M8 11.1654L10.6667 8.4987L8 5.83203" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M5.33203 8.5H10.6654" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </button>
              </div>
            </template>
            <template v-else>
              <div class="how-to max-h-[485px] overflow-hidden hover:overflow-y-auto lg:ml-0 lg:my-auto my-[80px] z-[50]">
                <div class="head mb-[26px]">
                  <button @click="handleHowTo" class="flex items-center font-Rubik font-normal text-greyText hover:text-white transition-all text-[15px] leading-[17.78px]">
                    <div class="arrow-right mr-[12px] mb-[2px]">
                      <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.84853 2.84853C10.3172 2.3799 10.3172 1.6201 9.84853 1.15147C9.3799 0.682843 8.6201 0.682843 8.15147 1.15147L9.84853 2.84853ZM2 9L1.15147 8.15147C0.682843 8.6201 0.682843 9.3799 1.15147 9.84853L2 9ZM8.15147 16.8485C8.6201 17.3172 9.3799 17.3172 9.84853 16.8485C10.3172 16.3799 10.3172 15.6201 9.84853 15.1515L8.15147 16.8485ZM8.15147 1.15147L1.15147 8.15147L2.84853 9.84853L9.84853 2.84853L8.15147 1.15147ZM1.15147 9.84853L8.15147 16.8485L9.84853 15.1515L2.84853 8.15147L1.15147 9.84853Z" fill="white"/>
                      </svg>
                    </div>
                    Back
                  </button>
                </div>
                <div class="body font-Rubik">
                  <h1 class="text-white text-[20px] font-bold">How Does It Work?</h1>
                  <ul id="instruction-items" class="text-white my-[40px] max-w-[100%]">
                    <li class="w-[300px] mb-[32px] flex justify-between">
                      <div class="one max-w-[270px]">
                        <h3 class="font-medium text-[18px] leading-[21.33px] mb-[12px]">Tell Us About Your Business</h3>
                        <p class="font-normal leading-[24px] text-greyText">Complete our application to tell us about your mission, product, market, and company.</p>
                      </div>
                    </li>
                    <li class="mb-[32px] flex justify-between">
                      <div class="two max-w-[270px]">
                        <h3 class="font-medium text-[18px] leading-[21.33px] mb-[12px]">Upload Your Data</h3>
                        <p class="font-normal leading-[24px] text-greyText">Kinfolk will analyze your business relative to your industry, key metrics, benchmarks.</p>
                      </div>
                    </li>
                    <li class="mb-[32px] flex justify-between">
                      <div class="three max-w-[270px]">
                        <h3 class="font-medium text-[18px] leading-[21.33px] mb-[12px]">Get a Quick Response</h3>
                        <p class="font-normal leading-[24px] text-greyText">Kinfolk will provide an investment decision in a few days, and regardless of our decision we will share back analysis based on the data provided.</p>
                      </div>
                    </li>
                    <li class="mb-[32px] flex justify-between">
                      <div class="four max-w-[270px]">
                        <h3 class="font-medium text-[18px] leading-[21.33px] mb-[12px]">Return to What Matters</h3>
                        <p class="font-normal leading-[24px] text-greyText">Use your funds and our data insights to grow your business and keep solving important problems facing Africa.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </transition>
        </div>
        <div v-if="token" class="lg:w-[50%] w-full flex flex-col justify-center items-center min-w-0 h-full lg:py-0 py-[34px] px-0 z-50">
          <div class="signup-card max-w-[458px] w-full bg-white lg:px-[48px] px-[30px] py-[40px] rounded-[6px]">
            <h1 class="text-center font-Playfair text-[32px] leading-[42.66px]">Reset Password</h1>
            <p class="text-center text-greyText font-Rubik text-[15px] leading-[17.78px] font-light mt-3 mb-6">Welcome to Kinfolk Venture Capital</p>
            <div v-if="error" class="rounded-md bg-red-50 p-4 mb-3">
              <div class="flex">
                <div class="flex-shrink-0">
                  <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                  <p class="text-sm font-medium text-red-800">
                    {{ error }}
                  </p>
                </div>
                <div class="ml-auto pl-3 my-auto">
                  <div class="-mx-1.5 -my-1.5">
                    <button type="button" class="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600">
                      <span class="sr-only">Dismiss</span>
                      <XIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <form>
              <div class="relative w-full mb-3">
                <label
                  class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                  htmlFor="email"
                >
                  Email Address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="username"
                  :class="[
                    v$.email.$error ? 'border-red-400' : 'border-grey-300',
                  ]"
                  class="px-4 py-3 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-gray-200 w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                  placeholder=""
                  style="transition: 'all .15s ease'"
                  v-model="v$.email.$model"
                  @blur="v$.email.$touch()"
                />
                <div v-if="v$.email.$error">
                  <p
                    v-if="v$.email.required.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >Email is required</p>
                  <p
                    v-if="v$.email.email.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >Email is invalid</p>
                </div>
              </div>
              <div class="relative w-full mb-6">
                <label
                  class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                  htmlFor="password"
                >
                  Password
                </label>
                <div class="flex relative w-full my-auto">
                  <span class='absolute inset-y-0 right-0 flex items-center pr-1 mt-2'>
                    <div class=''>
                    <img :src="eye" alt="" height="100%" width="100%" class="w-[20px] h-[20px] mx-[10px] hover:cursor-pointer" @click="toggleShow">
                    </div>
                  </span>
                  <input
                    id="password"
                    name="password"
                    autocomplete="new-password"
                    :type="fieldVisibility"
                    :class="[
                      v$.password.$error ? 'border-red-400' : 'border-grey-300',
                    ]"
                    class="px-4 py-3 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-gray-200 w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                    placeholder=""
                    style="transition: 'all .15s ease'"
                    v-model="v$.password.$model"
                    @blur="v$.password.$touch()"
                  />
                </div>
                <div v-if="v$.password.$error">
                  <p
                    v-if="v$.password.required.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >Password is required</p>
                  <p
                    v-if="v$.password.minLength.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >Password should be alteast 8 characters</p>
                </div>
              </div>
              <button :disabled="v$.$invalid" type="button" class="submit w-full py-4 text-white font-medium text-[16px] text-center leading-[19px] bg-black mb-[40px] disabled:opacity-50" @click="restPassword">Reset Password</button>
              <p class="text-center font-Rubik text-[15px] font-light">Already have an account?&nbsp;
                <router-link to="/sign-in"><b class="text-black font-medium">Sign In</b></router-link>
              </p>
            </form>
          </div>
          <p class="text-white text-[15px] leading-[18px] font-normal lg:mb-0 lg:mt-[32px] mt-[32px] mb-[32px]">By registering you agree with our website's Terms of Use</p>
        </div>
        <div v-else class="lg:w-[50%] w-full flex flex-col justify-center items-center min-w-0 h-full lg:py-0 py-[34px] px-0 z-50">
          <div class="signup-card max-w-[458px] w-full bg-white lg:px-[48px] px-[30px] py-[40px] rounded-[6px]">
            <h1 class="text-center font-Playfair text-[32px] leading-[42.66px]">Forgot Password</h1>
            <p class="text-center text-greyText font-Rubik text-[15px] leading-[17.78px] font-light mt-3 mb-6">Let’s recover your account</p>
            <div v-if="error" class="rounded-md bg-red-50 p-4 mb-3">
              <div class="flex">
                <div class="flex-shrink-0">
                  <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                  <p class="text-sm font-medium text-red-800">
                    {{ error }}
                  </p>
                </div>
                <div class="ml-auto pl-3 my-auto">
                  <div class="-mx-1.5 -my-1.5">
                    <button type="button" class="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600">
                      <span class="sr-only">Dismiss</span>
                      <XIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="message" class="rounded-md bg-green-50 p-4 my-6">
              <div class="flex">
                <div class="flex-shrink-0">
                  <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                  <h3 class="text-sm font-Rubik font-medium text-green-800">
                    Success!
                  </h3>
                  <div class="mt-2 font-Rubik text-sm text-green-700">
                    <p>
                      An Email has been sent to your email address. Please check your email and click on the link to reset your account.
                    </p>
                  </div>
                  <div class="mt-4">
                    <p class="mt-2 font-Rubik text-sm text-green-800">Didn't get the email?</p>
                    <div class="-my-1.5 flex">
                      <p class="mt-2 font-Rubik text-sm text-green-800">Confirm if email is correct </p>
                      <button type="button" class="ml-3 bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100" @click="resendlink">
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form v-if="!message">
              <div class="relative w-full mb-3">
                <label
                  class="font-Rubik text-greyText text-[14px] leading-[16.59px] font-normal"
                  htmlFor="magic-email"
                >
                  Email Address
                </label>
                <input
                  id="magic-email"
                  name="magic-email"
                  type="magic-email"
                  autocomplete="username"
                  :class="[
                    v2$.magic_email.$error ? 'border-red-400' : 'border-grey-300',
                  ]"
                  class="px-4 py-3 mt-2 text-[15px] leading-[17.78px] font-Rubik placeholder-gray-400 text-black text-sm border border-gray-200 w-full focus:outline-none focus:border-black hover:border-black focus:ring-0"
                  placeholder=""
                  style="transition: 'all .15s ease'"
                  v-model="v2$.magic_email.$model"
                  @blur="v2$.magic_email.$touch()"
                />
                <div v-if="v2$.magic_email.$error">
                  <p
                    v-if="v2$.magic_email.required.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >Email is required</p>
                  <p
                    v-if="v2$.magic_email.email.$invalid"
                    class="text-red-400 text-[10px] font-light"
                  >Email is invalid</p>
                </div>
              </div>
              <button :disabled="v2$.$invalid" type="button" class="submit w-full py-4 text-white font-medium text-[16px] text-center leading-[19px] bg-black mb-[40px] disabled:opacity-50" @click="getPasswordLink"> Get Link </button>
              <p class="text-center font-Rubik text-[15px] font-light">Already have an account?&nbsp;
                <router-link to="/sign-in"><b class="text-black font-medium">Sign In</b></router-link>
              </p>
            </form>
          </div>
          <p class="text-white text-[15px] leading-[18px] font-normal lg:mb-0 lg:mt-[32px] mt-[32px] mb-[32px]">By registering you agree with our website's Terms of Use</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/auth.service'
import eyeOpen from '../assets/visible-eye.svg'
import eyeClosed from '../assets/not-visible-eye.svg'
import { ref, watch, reactive, computed } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import { useRoute, useRouter } from 'vue-router'
import { CheckCircleIcon, XCircleIcon, XIcon } from '@heroicons/vue/solid'

export default {
  components: {
    CheckCircleIcon,
    XCircleIcon,
    XIcon
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const token = ref(null)
    const message = ref(null)
    const error = ref(null)
    const toggleHowTo = ref(false)

    const fieldVisibility = ref('password')
    const toggleShow = () => {
      fieldVisibility.value === 'password' ? fieldVisibility.value = 'text' : fieldVisibility.value = 'password'
    }
    const eye = computed(() => {
      return fieldVisibility.value === 'password' ? eyeClosed : eyeOpen
    })

    const handleHowTo = () => {
      toggleHowTo.value = !toggleHowTo.value
    }

    const address = ref('')
    const submitUserSignup = () => {
      // console.log('form submission - data: ')
    }

    function getAddressData (addressData, placeResultData, id) {
      // console.log(addressData)
      address.value = addressData
    }

    function handleError (error) {
      alert(error)
    }

    const data = reactive({
      full_name: '',
      email: '',
      password: ''
    })

    const data2 = reactive({
      magic_email: ''
    })

    const rules = computed(() => ({
      email: { required, email },
      password: { minLength: minLength(8), required }
    }))

    const rules2 = computed(() => ({
      magic_email: { required, email }
    }))

    const resendlink = () => {
      message.value = null
    }
    const getPasswordLink = async () => {
      try {
        const response = await AuthService.getPasswordLink({
          email: data2.magic_email
        })
        if (response.status === 200 && response.data.status === 'success') {
          message.value = response.data.data
        }
      } catch (e) {
        if (e.response) {
          error.value = e.response.data.message
          setTimeout(() => {
            error.value = null
          }, 2000)
        } else if (e.message === 'Network Error') {
          error.value = 'Network Error check your internet connection'
          setTimeout(() => {
            error.value = null
          }, 3000)
        }
      }
    }

    const restPassword = async () => {
      try {
        const signupData = {
          email: data.email,
          password: data.password,
          token: token.value
        }
        // console.log(signupData)
        const response = await AuthService.restPassword(signupData)
        if (response.status === 200) {
          router.push('/sign-in')
        }
      } catch (e) {
        if (e.response) {
          error.value = e.response.data.message
          setTimeout(() => {
            error.value = null
          }, 2000)
        } else if (e.message === 'Network Error') {
          error.value = 'Network Error check your internet connection'
          setTimeout(() => {
            error.value = null
          }, 3000)
        }
      }
    }
    watch(
      () => route,
      async (val) => {
        if (val.query.token) {
          token.value = val.query.token
          data.email = val.query.email
          // console.log(data)
        } else {
          token.value = null
        }
      },
      { immediate: true }
    )
    const v$ = useVuelidate(rules, data)
    const v2$ = useVuelidate(rules2, data2)

    return {
      toggleShow,
      eye,
      submitUserSignup,
      fieldVisibility,
      v$,
      v2$,
      token,
      getAddressData,
      handleError,
      getPasswordLink,
      restPassword,
      message,
      resendlink,
      error,
      toggleHowTo,
      handleHowTo
    }
  }
}
</script>

<style scoped>
.how-to {
  scroll-behavior: smooth;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.how-to::-webkit-scrollbar { /* Chrome */
  display: none;
}
#instruction-items li {
  position: relative;
}
#instruction-items li::before {
  content: '';
  border: solid 1px #828282;
  background-color: black;
  width: 20px;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  border-radius: 50%;
  position: relative;
  left: 0;
}
#instruction-items li:not(:last-child)::after {
  content: '';
  width: 2px;
  height: 100%;
  max-width: 2px;
  position: absolute;
  left: 0;
  top: 25px;
  margin-left: 9px;
  border-right: dotted 2px #828282;
  background-color: transparent;
}
.fade-enter-from {
  opacity: 0;
  position: fixed;
}
.fade-enter-to {
  opacity: 1;
  position: unset;
}
.fade-enter-active {
  transition: all .6s ease;
}
.fade-leave-from {
  opacity: 1;
  position: unset;
}
.fade-leave-to {
  opacity: 0;
  position: fixed;
}
/* .fade-leave-active {
  transition: all .2s ease;
} */

</style>
